/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Admin from "../layout/Admin";
// import MyContext from "../context/permissions";

// Assign Router
import AssigntaskCollege from "../pages/subadmin/taskassign/AssigntaskCollege";
import Report from "../pages/report/Report";
import AssignreportChart from "../pages/assigntask/AssignreportChart";
import AssignModel from "../pages/subadmin/taskassign/assignmodel/AssignModel";
import AddArticle from "../pages/clubs/adddetails/AddArticle";
import AddQa from "../pages/clubs/adddetails/AddQa";
import StaticContent from "../pages/StaticContent/StaticContent";
import SubAdminDashboard from "../pages/dashboard/SubAdminDashboard";
import ProfileDetail from "../pages/subadmin/Profiledetail/ProfileDetail";
import AddProfile from "../pages/subadmin/Profiledetail/AddProfile";
import QuestionForm from "../pages/questions";
import EditPlacement from "../components/PlacementLogo/EditPlacement";

const LoginUpdate = loadable(() => import("../pages/auth/Login2"));
const Dashboard = loadable(() => import("../pages/dashboard/Dashboard"));
const Colleges = loadable(() =>
  import("../pages/academics/colleges/indianCollege/IndianCollege")
);
const EditRequest = loadable(() =>
  import("../pages/academics/colleges/indianCollege/EditRequestList")
);
const Login = loadable(() => import("../pages/auth/Login"));
const UpdatePassword = loadable(() => import("../pages/auth/UpdatePassword"));
const Otp = loadable(() => import("../pages/auth/Otp"));
const ForgotPassword = loadable(() => import("../pages/auth/ForgotPassword"));
const IndianCollegesDetail = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/IndianCollegesDetail")
);

const OverseasCollegesDetail = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/OverseasCollegesDetail"
  )
);
const OverseasColleges = loadable(() =>
  import("../pages/academics/colleges/overseasColleges/OverseasColleges")
);
const IndCollegeCourseList = loadable(() =>
  import("../pages/academics/course/indCollegeCourses/IndCollegeCourseList")
);
const VacationalCoursesList = loadable(() =>
  import("../pages/academics/course/vacationalCourses/VacationalCourseList")
);
const PsychometricTest = loadable(() =>
  import("../pages/academics/test/psychometricTest/PsychometricTest")
);
const AddCollegePlacement = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddCollegePlacement"
  )
);
const AddFaq = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/addDetails/AddFaq")
);

const AddOverseasFaq = loadable(() =>
  import("../pages/academics/colleges/overseasCollegeDetail/addDetails/AddFaq")
);

const AddOverseasGallery = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddGallery"
  )
);
const AddOverseasQa = loadable(() =>
  import("../pages/academics/colleges/overseasCollegeDetail/addDetails/AddQa")
);

const AddOverviewDetails = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddOverviewDetail"
  )
);

const AddOverseasOverviewDetails = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddOverviewDetail"
  )
);

const AddRankingDetail = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddRankingDetail"
  )
);

const AddOverseasRankingDetail = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddRankingDetail"
  )
);

const AddCutoff = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/addDetails/AddCutoff")
);
const IndCollegeCourseDetail = loadable(() =>
  import("../pages/academics/course/indCollegeCourses/IndCollegeCourseDetail")
);
const OverCollegeCourseDetail = loadable(() =>
  import(
    "../pages/academics/course/overseasCollegeCourses/OverCollegeCourseDetail"
  )
);

const MentorListing = loadable(() => import("../pages/userManagement/Main"));

const CourseListing = loadable(() =>
  import("../pages/userManagement/ContentMain")
);

const MentorDetails = loadable(() =>
  import("../pages/userManagement/Mentors/MentorDetails")
);

const CourseDetails = loadable(() =>
  import("../pages/userManagement/Content/CourseDetails")
);

const Overview = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/OverView")
);
const Ranking = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/Ranking")
);
const Placement = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddCollegePlacement"
  )
);

const PlacementOverseas = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddCollegePlacement"
  )
);

const Scholarship = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/Scholarship")
);
const Faq = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/Faq")
);
const Admission = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/Admission")
);

const OverseasAdmission = loadable(() =>
  import("../pages/academics/colleges/overseasCollegeDetail/Admission")
);
const AddTestimonial = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddTestimonial"
  )
);
const AddOverseasTestimonial = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddTestimonial"
  )
);

const AddCta = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/addCostToAttend"
  )
);
const AddReviewpoll = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddReviewpoll"
  )
);
const Testimonial = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/Testimonial")
);

const TestimonialOverseas = loadable(() =>
  import("../pages/academics/colleges/overseasCollegeDetail/Testimonial")
);

const TestPreparation = loadable(() =>
  import("../pages/testpreparation/TestPreparation")
);
const OverseasTest = loadable(() =>
  import("../pages/academics/test/OverseasTest")
);
const EntranceExam = loadable(() =>
  import("../pages/entranceexams/EntranceExam")
);
const Overseasdetails = loadable(() =>
  import("../pages/academics/colleges/overseasColleges/Overseasdetails")
);
const AddCollege = loadable(() => import("../pages/add-college/AddCollege"));
const AddOverSeasCollege = loadable(() =>
  import("../pages/add-college/AddOverseasCollege")
);

const ScholarshipList = loadable(() =>
  import("../pages/academics/scholarship/ScholarshipList")
);
const AddCampusLife = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddCampusLife"
  )
);
const AddAdmission = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddAdmission"
  )
);

const AddOverseasAdmission = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddAdmission"
  )
);

const EditAdmission = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/EditAdmission"
  )
);
const AddEvent = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/addDetails/AddEvent")
);
const AddWebinar = loadable(() =>
  import(
    "../pages/academics/colleges/indianCollegeDetail/addDetails/AddWebinar"
  )
);
const AddCourse = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/addDetails/AddCourse")
);

const AddOverseasCourse = loadable(() =>
  import(
    "../pages/academics/colleges/overseasCollegeDetail/addDetails/AddCourse"
  )
);

const AddDate = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/addDetails/AddDate")
);
const OverseasAddDate = loadable(() =>
  import("../pages/academics/colleges/overseasCollegeDetail/addDetails/AddDate")
);
const DatestoRemember = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/DatestoRemember")
);

const OverSeasDatestoRemember = loadable(() =>
  import("../pages/academics/colleges/overseasCollegeDetail/DatestoRemember")
);

const EntranceexamDetail = loadable(() =>
  import("../pages/entranceexams/EntranceexamDetail")
);
const EntranceemployeInfo = loadable(() =>
  import("../pages/entranceexams/EntranceemployeInfo")
);
const EntranceIntroduction = loadable(() =>
  import("../pages/entranceexams/Entranceoverview")
);
const AddEntranceOverview = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddEntranceOverview")
);
const WebEventList = loadable(() =>
  import("../pages/academics/colleges/indianCollegeDetail/WebEventList")
);
const AddEntranceExamDate = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddEntranceexamDate")
);
const AddEntranceaddApplication = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddEntranceaddApplication")
);
const AddSubAdmin = loadable(() => import("../pages/subadmin/AddSubAdmin"));
const SubAdminDetail = loadable(() =>
  import("../pages/subadmin/SubAdminDetail")
);
const AddScholarships = loadable(() =>
  import("../pages/academics/scholarship/addscholarship/AddScholarships")
);
const AddOverseasScholarships = loadable(() =>
  import("../pages/academics/scholarship/addscholarship/AddOverseasScholarship")
);

const AddtipsExpert = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddtipsExpert")
);
const AddPreviousPaper = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddPreviousPaper")
);
const ScholarShipDetails = loadable(() =>
  import("../pages/academics/scholarship/scholarshipDetails/ScholarShipDetails")
);
const CareerList = loadable(() => import("../pages/career/CareerList"));
const CarrerDetail = loadable(() =>
  import("../pages/career/careerdetail/CarrerDetail")
);
const AddCareer = loadable(() => import("../pages/career/addcareer/AddCareer"));
const AssignCollege = loadable(() =>
  import("../pages/subadmin/subadminmodule/AssignCollege")
);
const SubadminIndianCollege = loadable(() =>
  import("../pages/subadmin/subadminmodule/SubadminIndianCollege")
);
const IndianCollegeOverview = loadable(() =>
  import("../pages/subadmin/suadminPage/IndianCollegeOverview")
);
const TeamLead = loadable(() =>
  import("../pages/subadmin/teamLead/TeamLeadList")
);
const AddExamPattern = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddExamPattern")
);
const ResultCounselling = loadable(() =>
  import("../pages/entranceexams/ResultCounselling")
);
const AddResultCounselling = loadable(() =>
  import("../pages/entranceexams/entranceAdddetails/AddResultCounselling")
);
const EditPaperAnalysis = loadable(() =>
  import("../pages/entranceexams/previousPaperDetails/PreviousPaperAnalysis")
);
const EditExpertData = loadable(() =>
  import("../pages/entranceexams/previousPaperDetails/EditExpertData")
);
const EditRecommendedBooks = loadable(() =>
  import("../pages/entranceexams/previousPaperDetails/EditRecommendedBooks")
);
const ClubDetail = loadable(() => import("../pages/clubs/ClubDetail"));
const ClubList = loadable(() => import("../pages/clubs/ClubList"));
const BulkList = loadable(() => import("../pages/accomodation/BulkList"));

const AddPost = loadable(() => import("../pages/clubs/adddetails/AddPost"));
//  subamin
const AddAssignTask = loadable(() =>
  import("../pages/subadmin/subadminmodule/subadmin-add-pages/AddAssignTask")
);
const TeamLeaderProfile = loadable(() =>
  import("../pages/subadmin/teamLead/LeaderProfile")
);
const InternDetails = loadable(() =>
  import("../pages/subadmin/teamLead/InternDetails")
);
const AssignTaskList = loadable(() =>
  import(
    "../pages/subadmin/subadminmodule/subadmin-add-pages/task/AssignTaskList"
  )
);
const AssignCollegeDetail = loadable(() =>
  import("../pages/subadmin/taskassign/AssignCollegeDetails")
);
const TaskDetails = loadable(() =>
  import("../pages/subadmin/taskassign/TaskDetails")
);
const AddClubDetails = loadable(() =>
  import("../pages/clubs/adddetails/AddClubDetails")
);
const AddPlanDetails = loadable(() =>
  import("../pages/accomodation/adddetails/AddPlanDetails")
);

const AssignToOther = loadable(() =>
  import("../pages/subadmin/indianCollegeContent/AssignToOther")
);
const AssignToMe = loadable(() =>
  import("../pages/subadmin/indianCollegeContent/AssignToMe")
);
const EditPermission = loadable(() =>
  import("../pages/subadmin/suadminPage/EditPermission")
);
const EditStaticPolicy = loadable(() =>
  import("../pages/StaticContent/addStaticContent/EditStaticPolicy")
);
const EditEditStaticTandC = loadable(() =>
  import("../pages/StaticContent/addStaticContent/EditStaticTandC")
);
const AddStaticAboutUs = loadable(() =>
  import("../pages/StaticContent/addStaticContent/AddStaticAboutUs")
);
const EditAboutUs = loadable(() =>
  import("../pages/StaticContent/addStaticContent/EditAboutUs")
);
const AddStaticFaq = loadable(() =>
  import("../pages/StaticContent/addStaticContent/AddStaticFaq")
);

const Blogs = loadable(() => import("../pages/Blogs/index"));

const AddBlogs = loadable(() => import("../components/Blogs/AddBlogs"));

const BlogsDetails = loadable(() => import("../components/Blogs/BlogsDetails"));

const EditBlogs = loadable(() => import("../components/Blogs/EditBlogs"));

const PlacementLogo = loadable(() => import("../pages/PlacementsLogo/index"));

const AddPlacement = loadable(() =>
  import("../components/PlacementLogo/AddPlacement")
);

const AppRouter = () => {
  // const { permissions } = useContext(MyContext);
  return (
    <Routes>
      <Route
        path="/login-update"
        element={
          <PublicRoute>
            {/* <LoginUpdate /> */}
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/"
        element={
          <PublicRoute>
            {/* <Login /> */}
            <LoginUpdate />
          </PublicRoute>
        }
      />
      <Route
        path="/otp"
        element={
          <PublicRoute>
            <Otp />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/update-password"
        element={
          <PublicRoute>
            <UpdatePassword />
          </PublicRoute>
        }
      />
      <Route element={<Admin type="admin" />}>
        <Route
          path="/bulk-counselling"
          element={
            <PrivateRoute page="Bulk">
              <BulkList />
            </PrivateRoute>
          }
        />

        <Route
          path="/placement-logo"
          element={
            <PrivateRoute page="Placement-logo">
              <PlacementLogo />
            </PrivateRoute>
          }
        />

        <Route
          path="/blogs"
          element={
            <PrivateRoute page="Blogs">
              <Blogs />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-placement"
          element={
            <PrivateRoute page="Placement-logo">
              <AddPlacement />
            </PrivateRoute>
          }
        />

				<Route
					path="/blog-detail/:id"
					element={
						<PrivateRoute page="Placement-logo">
							<BlogsDetails />
						</PrivateRoute>
					}
				/>
        <Route
          path="/blog-detail"
          element={
            <PrivateRoute page="Placement-logo">
              <BlogsDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-blog"
          element={
            <PrivateRoute page="Blogs">
              <AddBlogs />
            </PrivateRoute>
          }
        />

				<Route
					path="/edit-blog/:id"
					element={
						<PrivateRoute page="Edit Blog">
							<EditBlogs />
						</PrivateRoute>
					}
				/>

				<Route
					path="/edit-placement/:id"
					element={
						<PrivateRoute page="Placement-logo">
							<EditPlacement />
						</PrivateRoute>
					}
				/>

        <Route
          path="/bulk-counselling/add-plan-details"
          element={
            <PrivateRoute page="Clubs">
              {" "}
              <AddPlanDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-clubdetail"
          element={
            <PrivateRoute page="Clubs">
              {" "}
              <AddClubDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/clubs-list"
          element={
            <PrivateRoute page="Clubs">
              {" "}
              <ClubList />
            </PrivateRoute>
          }
        />
        <Route
          path="/clubs-detail/:id"
          element={
            <PrivateRoute page="Clubs">
              <ClubDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/clubs-detail/add-post"
          element={
            <PrivateRoute page="Clubs">
              {" "}
              <AddPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/clubs-detail/add-article"
          element={
            <PrivateRoute page="Clubs">
              <AddArticle />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute page="Dashboard">
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/colleges-list"
          element={
            <PrivateRoute page="Colleges">
              <Colleges />
            </PrivateRoute>
          }
        />
        <Route
          path="/user_management/mentors/verifications/mentors-list"
          element={
            <PrivateRoute page="Colleges">
              <MentorListing />
            </PrivateRoute>
          }
        />

        <Route
          path="/user_management/mentors/verifications/:mentorId/mentordetails"
          element={
            <PrivateRoute page="Colleges">
              <MentorDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/user_management/content/verifications/courses-list"
          element={
            <PrivateRoute page="Colleges">
              <CourseListing />
            </PrivateRoute>
          }
        />
        <Route
          path="/user_management/content/verifications/:mentorId/coursedetails/:courseId"
          element={
            <PrivateRoute page="Colleges">
              <CourseDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-request"
          element={
            <PrivateRoute page="Colleges">
              <EditRequest />
            </PrivateRoute>
          }
        />

        <Route
          path="/static-content"
          element={
            <PrivateRoute page="static-content">
              <StaticContent />
            </PrivateRoute>
          }
        />
        <Route
          path="/indian-colleges-details/:id"
          element={
            <PrivateRoute page="Colleges">
              <IndianCollegesDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas-colleges-details/:id"
          element={
            <PrivateRoute page="Colleges">
              <OverseasCollegesDetail />
            </PrivateRoute>
          }
        />

        <Route
          path="/indian-colleges-details-subadmin/:id"
          element={
            <PrivateRoute page="Colleges">
              <IndianCollegesDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas-colleges"
          element={
            <PrivateRoute page="Colleges">
              <OverseasColleges />
            </PrivateRoute>
          }
        />

        <Route
          path="/indian-college-course-list"
          element={
            <PrivateRoute page="Colleges">
              <IndCollegeCourseList />
            </PrivateRoute>
          }
        />
        <Route
          path="/indian-college-course-detail/:id"
          element={
            <PrivateRoute page="Colleges">
              <IndCollegeCourseDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/indian-college-course-detail/:college_id/:courseId"
          element={
            <PrivateRoute page="Colleges">
              <IndCollegeCourseDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/Overseas-college-course-detail/:id"
          element={
            <PrivateRoute page="Colleges">
              <OverCollegeCourseDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas-college-course-detail/:college_id/:courseId"
          element={
            <PrivateRoute page="Colleges">
              <OverCollegeCourseDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacational-course-list"
          element={
            <PrivateRoute page="Colleges">
              <VacationalCoursesList />
            </PrivateRoute>
          }
        />
        <Route
          path="/psychometric-test"
          element={
            <PrivateRoute page="Colleges">
              <PsychometricTest />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas-test"
          element={
            <PrivateRoute page="Colleges">
              <OverseasTest />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-college-placement"
          element={
            <PrivateRoute page="Colleges">
              <AddCollegePlacement />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overview-detail/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverviewDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-overview-detail/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasOverviewDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overview-detail/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverviewDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-overview-detail/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasOverviewDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overview-detail/:id/:type"
          element={
            <PrivateRoute page="Colleges">
              <AddOverviewDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-placement/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <Placement />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-placement/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <PlacementOverseas />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-placement/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <Placement />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-placement/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <PlacementOverseas />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-placement/:id/:year/:type"
          element={
            <PrivateRoute page="Colleges">
              {" "}
              <Placement />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-placement/:id/:year/:type"
          element={
            <PrivateRoute page="Colleges">
              {" "}
              <PlacementOverseas />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-rankings-detail/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <AddRankingDetail />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-overseas-rankings-detail/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasRankingDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-cost-to-attend/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddCta />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-cost-to-attend/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddCta />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-rankings-detail/:id/:year"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasRankingDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-rankings-detail/:id/:year/:type"
          element={
            <PrivateRoute page="Colleges">
              <AddRankingDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-cutoff/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddCutoff />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-cutoff/:id/:year/:entrance_Exs/:course/:specialisation"
          element={
            <PrivateRoute page="Colleges">
              <AddCutoff />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-faq/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddFaq />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-faq/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasFaq />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-gallery/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasGallery />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-gallery/:id/:id2"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasGallery />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-faq/:id/:id2"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasFaq />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-overseas-Q&A/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasQa />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-overseas-Q&A/:id/:id2"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasQa />
            </PrivateRoute>
          }
        />

        <Route
          path="/edit-faq/:id/:id2"
          element={
            <PrivateRoute page="Colleges">
              <AddFaq />
            </PrivateRoute>
          }
        />
        <Route
          path="/overview"
          element={
            <PrivateRoute page="Colleges">
              <Overview />
            </PrivateRoute>
          }
        />
        <Route
          path="/ranking"
          element={
            <PrivateRoute page="Colleges">
              <Ranking />
            </PrivateRoute>
          }
        />
        <Route
          path="/scholarship"
          element={
            <PrivateRoute page="Scholarships">
              <Scholarship />
            </PrivateRoute>
          }
        />
        {/* <Route path="/scholarship" element={<PrivateRoute page="Scholarships"><Scholarship /></PrivateRoute>} /> */}
        <Route
          path="/faq"
          element={
            <PrivateRoute page="Colleges">
              <Faq />
            </PrivateRoute>
          }
        />
        <Route
          path="/admission/:id"
          element={
            <PrivateRoute page="Colleges">
              <Admission />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas-admission/:id"
          element={
            <PrivateRoute page="Colleges">
              <OverseasAdmission />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-testimonial/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddTestimonial />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-testimonial/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasTestimonial />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-testimonial/:id/:newId"
          element={
            <PrivateRoute page="Colleges">
              <AddTestimonial />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-testimonial/:id/:newId"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasTestimonial />
            </PrivateRoute>
          }
        />
        <Route
          path="/edittestimonialpoll"
          element={
            <PrivateRoute page="Colleges">
              <AddReviewpoll />
            </PrivateRoute>
          }
        />
        <Route
          path="/testimonial/:id"
          element={
            <PrivateRoute page="Colleges">
              <Testimonial />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas-testimonial/:id"
          element={
            <PrivateRoute page="Colleges">
              <TestimonialOverseas />
            </PrivateRoute>
          }
        />
        <Route
          path="/testpreparation"
          element={
            <PrivateRoute page="Colleges">
              <TestPreparation />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseasdetail"
          element={
            <PrivateRoute page="Colleges">
              <Overseasdetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/addcollege"
          element={
            <PrivateRoute page="Colleges">
              <AddCollege />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-college"
          element={
            <PrivateRoute page="Colleges">
              <AddOverSeasCollege />
            </PrivateRoute>
          }
        />
        <Route
          path="/scholarship-list"
          element={
            <PrivateRoute page="Scholarships">
              <ScholarshipList />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-scholarship/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <AddScholarships />
            </PrivateRoute>
          }
        />

        <Route
          path="/add-overseas-scholarship/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasScholarships />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-scholarship/:college_id/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddScholarships />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-campuslife/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddCampusLife />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-campuslife/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddCampusLife />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-campuslife/:id/:type"
          element={
            <PrivateRoute page="Colleges">
              {" "}
              <AddCampusLife />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/add-event/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddEvent />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-event/:id/:eventId"
          element={
            <PrivateRoute page="Colleges">
              <AddEvent />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-webinar/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddWebinar />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-webinar/:id/:webinarId"
          element={
            <PrivateRoute page="Colleges">
              <AddWebinar />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-course/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <AddCourse />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-course/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasCourse />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-course/:college_id/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddOverseasCourse />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-course/:college_id/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddCourse />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-date/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <AddDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas/add-date/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <OverseasAddDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-date/:college_id/:id"
          element={
            <PrivateRoute page="Colleges">
              <AddDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-date/:college_id/:id/:type"
          element={
            <PrivateRoute page="Colleges">
              <AddDate />
            </PrivateRoute>
          }
        />

        <Route
          path="/overseas/edit-date/:college_id/:id"
          element={
            <PrivateRoute page="Colleges">
              <OverseasAddDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas/edit-date/:college_id/:id/:type"
          element={
            <PrivateRoute page="Colleges">
              <OverseasAddDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/webinar-event-listing/:id"
          element={
            <PrivateRoute page="Colleges">
              <WebEventList />
            </PrivateRoute>
          }
        />
        <Route
          path="/dates-to-remember/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <DatestoRemember />
            </PrivateRoute>
          }
        />
        <Route
          path="/overseas/dates-to-remember/:college_id"
          element={
            <PrivateRoute page="Colleges">
              <OverSeasDatestoRemember />
            </PrivateRoute>
          }
        />
        {/* Report Route */}
        <Route
          path="/report"
          element={
            <PrivateRoute page="Report">
              <Report />
            </PrivateRoute>
          }
        />
        {/* AssignreportChart Route */}
        <Route
          path="/assignreport"
          element={
            <PrivateRoute page="AssignreportChart">
              <AssignreportChart />
            </PrivateRoute>
          }
        />
        {/* Entrance Exam Route */}
        <Route
          path="/entranceexam"
          element={
            <PrivateRoute page="Exams">
              <EntranceExam />
            </PrivateRoute>
          }
        />
        {/* <Route path="/add-entrance-exam" element={<PrivateRoute page="Exams"><AddEntranceExam /></PrivateRoute>} /> */}
        <Route
          path="/entranceexam-detail/:examId"
          element={
            <PrivateRoute page="Exams">
              <EntranceexamDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/entrance-employee-info"
          element={
            <PrivateRoute page="Exams">
              <EntranceemployeInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/entrance-introduction/:examId"
          element={
            <PrivateRoute page="Exams">
              <EntranceIntroduction />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-entrance-overview"
          element={
            <PrivateRoute page="Exams">
              <AddEntranceOverview />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-entrance-overview/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddEntranceOverview />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-exam-date-remember/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddEntranceExamDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-exam-date-remember/:examId/:id"
          element={
            <PrivateRoute page="Exams">
              <AddEntranceExamDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-entrance-application/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddEntranceaddApplication />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-entrance-application/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddEntranceaddApplication />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-tips-expert/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddtipsExpert />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-tips-expert/:examId/:id"
          element={
            <PrivateRoute page="Exams">
              <AddtipsExpert />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-tip-expert-data/:examId/:id"
          element={
            <PrivateRoute page="Exams">
              <EditExpertData />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-recommended-books-data/:examId/:id"
          element={
            <PrivateRoute page="Exams">
              <EditRecommendedBooks />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-previous-paper/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddPreviousPaper />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-previous-paper/:examId"
          element={
            <PrivateRoute page="Exams">
              <AddPreviousPaper />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-paper-analysis/:examId/:paperId"
          element={
            <PrivateRoute page="Exams">
              <EditPaperAnalysis />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-exam-pattern/:examId"
          element={
            <PrivateRoute>
              <AddExamPattern />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/edit-exam-pattern/:examId"
          element={
            <PrivateRoute>
              <AddExamPattern />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/result-counselling/:examId"
          element={
            <PrivateRoute>
              <ResultCounselling />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/add-result-counselling/:examId"
          element={
            <PrivateRoute>
              <AddResultCounselling />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/edit-result-counselling/:examId"
          element={
            <PrivateRoute>
              <AddResultCounselling />
            </PrivateRoute>
          }
        ></Route>
        {/* Career Route */}
        <Route
          path="/career-list"
          element={
            <PrivateRoute page="Career">
              <CareerList />
            </PrivateRoute>
          }
        />
        <Route
          path="/addApptitude"
          element={
            <PrivateRoute>
              <QuestionForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/carrer-detail/:id"
          element={
            <PrivateRoute>
              <CarrerDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-career"
          element={
            <PrivateRoute>
              <AddCareer />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-career/:id"
          element={
            <PrivateRoute>
              <AddCareer />
            </PrivateRoute>
          }
        />

        {/* Static Content Route*/}
        <Route
          path="/edit-static-policy"
          element={
            <PrivateRoute>
              <EditStaticPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-static-tnc"
          element={
            <PrivateRoute>
              <EditEditStaticTandC />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-static-about-us"
          element={
            <PrivateRoute>
              <AddStaticAboutUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-static-about-us/:id/:type"
          element={
            <PrivateRoute>
              <EditAboutUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-static-faq"
          element={
            <PrivateRoute>
              <AddStaticFaq />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-static-faq/:id"
          element={
            <PrivateRoute>
              <AddStaticFaq />
            </PrivateRoute>
          }
        />

        {/* <Route path="/login2" element={<PublicRoute ><Login2/></PublicRoute>} /> */}

        {/* Sub Admin Route */}
        <Route
          path="/add-subadmin"
          element={
            <PrivateRoute page="SubAdmin">
              <AddSubAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-subadmin/:admin_id"
          element={
            <PrivateRoute page="SubAdmin">
              <AddSubAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/subadmin-detail"
          element={
            <PrivateRoute page="SubAdmin">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/quiz"
          element={
            <PrivateRoute page="Quiz">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/team-lead"
          element={
            <PrivateRoute page="TeamLead">
              <TeamLead />
            </PrivateRoute>
          }
        />
        <Route
          path="/team-leader-profile/:adminId"
          element={
            <PrivateRoute page="TeamLeaderProfile">
              <TeamLeaderProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/intern-details/:internId"
          element={
            <PrivateRoute page="InternDetails">
              <InternDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/clubs"
          element={
            <PrivateRoute page="Clubs">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs"
          element={
            <PrivateRoute page="Blogs">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/newsletter"
          element={
            <PrivateRoute page="Newsletters">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/cms"
          element={
            <PrivateRoute page="CMS">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/customer"
          element={
            <PrivateRoute page="Customer">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/master"
          element={
            <PrivateRoute page="Master">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/notification"
          element={
            <PrivateRoute page="Push Notification">
              <SubAdminDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-scholarship"
          element={
            <PrivateRoute>
              <AddScholarships />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-previous-paper"
          element={
            <PrivateRoute>
              <AddPreviousPaper />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-previous-paper/:exam_id/:id"
          element={
            <PrivateRoute>
              <AddPreviousPaper />
            </PrivateRoute>
          }
        />
        <Route
          path="/scholarship-detail/:id"
          element={
            <PrivateRoute>
              <ScholarShipDetails />
            </PrivateRoute>
          }
        />

        {/* task page */}
        <Route
          path="/add-assign-task"
          element={
            <PrivateRoute page="AddAssignTask">
              <AddAssignTask />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-assign-task/:taskId"
          element={
            <PrivateRoute page="AddAssignTask">
              <AddAssignTask />
            </PrivateRoute>
          }
        />
        <Route
          path="/assign-task-list"
          element={
            <PrivateRoute page="TaskDetails">
              <AssignTaskList />
            </PrivateRoute>
          }
        />
        <Route
          path="/assigntask-college"
          element={
            <PrivateRoute page="AssigntaskCollege">
              <AssigntaskCollege />
            </PrivateRoute>
          }
        />
        <Route
          path="/assign-college-list"
          element={
            <PrivateRoute page="AssignCollegeDetail">
              <AssignCollegeDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-college-list/:taskId"
          element={
            <PrivateRoute page="AssignCollegeDetail">
              <AssignCollegeDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/sub-admin-dashboard"
          element={
            <PrivateRoute page="AssignCollegeDetail">
              <SubAdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/assign"
          element={
            <PrivateRoute page="AssignCollegeDetail">
              <AssignModel />
            </PrivateRoute>
          }
        />
        <Route
          path="/task-detail/:taskId"
          element={
            <PrivateRoute>
              <TaskDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/assign-to-other-list"
          element={
            <PrivateRoute page="AssingOtherList">
              <AssignToOther />
            </PrivateRoute>
          }
        />
        <Route
          path="/assign-to-me-list"
          element={
            <PrivateRoute page="AssignToMeList">
              <AssignToMe />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-overseas-admission/:college_id"
          element={
            <PrivateRoute page="AddAdmission">
              <AddOverseasAdmission />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-overseas-admission/:college_id"
          element={
            <PrivateRoute page="AddAdmission">
              <AddOverseasAdmission />
            </PrivateRoute>
          }
        />
        {/* sub addmission router */}

        <Route
          path="/add-admission/:college_id"
          element={
            <PrivateRoute page="AddAdmission">
              <AddAdmission />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-admission/:college_id/:course_id"
          element={
            <PrivateRoute page="AddAdmission">
              <AddAdmission />
              {/* <EditAdmission /> */}
            </PrivateRoute>
          }
        />
        {/* <Route path="/edit-admission-details/:college_id/:admission_id/:course_id" element={<PrivateRoute page="AddAdmission"><AddAdmission /></PrivateRoute>}/> */}
      </Route>
      <Route element={<Admin type="subadmin" />}>
        <Route
          path="/subadmin/assign-college"
          element={
            <PrivateRoute>
              <AssignCollege />
            </PrivateRoute>
          }
        />
        <Route
          path="/subadmin/indian-college"
          element={
            <PrivateRoute>
              <SubadminIndianCollege />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-permission"
          element={
            <PrivateRoute>
              <EditPermission />
            </PrivateRoute>
          }
        />
        <Route
          path="/subadmin/indiancollege-addoverview"
          element={
            <PrivateRoute>
              <IndianCollegeOverview />
            </PrivateRoute>
          }
        />
        <Route
          path="/club-detail/qa/add-qa"
          element={
            <PrivateRoute>
              <AddQa />
            </PrivateRoute>
          }
        />

        <Route
          path="/static-content"
          element={
            <PrivateRoute>
              <StaticContent />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile-detail"
          element={
            <PrivateRoute>
              <ProfileDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-profile"
          element={
            <PrivateRoute>
              <AddProfile />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="*" element={<>404 Page not found</>} />
    </Routes>
  );
};

export default AppRouter;
