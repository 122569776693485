import React, { useEffect } from 'react';
import $ from "jquery";
import { getMediaURL } from '../../helpers';
import { defaultPdf, defaultVideo, docsImage, uploadImage } from '../../constants/images';
// import Loader from '../../HOC/Loader';

const ImageUpload = ({ title = "", inputId = "", src = "", alt = "", imgId = "", type = "upload", name = "", setValue = () => { }, register, isLoading, setLoading, accept = "image/*", name2 = "" }) => {
    // const ImageUpload = ({ title = "", inputId = "", src = "", alt = "", imgId = "", type = "upload", name = "", setValue = () => { }, register, isLoading, setLoading, accept = "image/*", name2 = "", callback = () => { } }) => {
    // accept="image/* video/* pdf/* .doc, .docx, .ppt, .pptx,"
    useEffect(() => {
        $(`#${inputId}`).change(function () {
            var curElement = $(`#${imgId}`);
            var reader = new FileReader();
            reader.onload = function (e) {
                if (e?.target?.result) {
                    if (e.target.result.indexOf("/pdf") > -1 || e.target.result.indexOf("application/pdf") > -1) {
                        curElement.attr("src", defaultPdf);
                        if (name2) {
                            setValue(name2, 'pdf');
                        }
                    }
                    else if (e.target.result.indexOf("video/") > -1) {
                        curElement.attr("src", defaultVideo);
                        if (name2) {
                            setValue(name2, 'video');
                        }
                    }
                    else if (e.target.result.indexOf("application/msword") > -1 || e.target.result.indexOf("application/vnd.openxmlformats-officedocument.wordprocessingml.document") > -1) {
                        curElement.attr("src", docsImage);
                        if (name2) {
                            setValue(name2, 'doc');
                        }
                    } else {
                        curElement.attr("src", e.target.result);
                        if (name2) {
                            setValue(name2, 'image');
                        }
                    }
                }
            };

            reader.readAsDataURL(this.files[0]);
        });
    }, [])
    return (
        <>
            <label htmlFor={inputId}>
                <img className='image-upload pointer-footer' id={imgId} src={src || uploadImage} alt={alt} />
                {title}
            </label>
            <input
                type="file"
                className="form-control image-upload-input pointer-footer"
                id={inputId}
                name={name}
                onChange={async (e) => {
                    // setLoading(true)
                    // debugger
                    if (type === "upload") {
                        setValue(name, e.target.files)
                    }
                    else {
                        let response = await getMediaURL(e.target.files[0])
                        setValue(name, response)
                    }
                    // if (!src) {
                    //     callback();
                    // }
                    // setLoading(false)
                }}
                accept={accept} />
        </>
    )
}

export default ImageUpload;