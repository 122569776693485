import { combineReducers } from "redux";
import {
  dataReducer,
  filterReducer,
  prefrencesReducer,
  filterReducerAssignToOther,
  prefrencesReducerAssignToOther,
  filterReducerAssignedCollege,
  mentorPrefrencesReducer,
} from "./reducers";

const rootReducer = combineReducers({
  counsellingId: dataReducer,
  permissionFilter: filterReducer,
  permissionPrefrences: prefrencesReducer,
  assignToOtherFilter: filterReducerAssignToOther,
  assignToOtherPrefrences: prefrencesReducerAssignToOther,
  assignedCollegeFilter: filterReducerAssignedCollege,
  mentorPrefrencesFilter: mentorPrefrencesReducer,
});

export default rootReducer;
