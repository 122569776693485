import { faAngleRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomEditor from "../../../components/common/CustomEditor";
import ImageUpload from "../../../components/common/ImageUpload";
import CustomEditorHosted from "../../../components/common/CustomEditorHosted";

const AddQa = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="wrap_header p-0">
        <div className="breadcrumps">
          <a onClick={() => navigate("/colleges-list")}>Club</a>
          <FontAwesomeIcon icon={faAngleRight} />
          <a onClick={() => navigate(-1)}>Club Details</a>
          <FontAwesomeIcon icon={faAngleRight} />
          <a>Add/Edit Club QA</a>
        </div>
        <div className="subheader-right-btm subheader-right-btn3">
          <button
            className="btn btn-purple"
            type="button"
            onClick={() => navigate(-1)}
          >
            Discard
          </button>
          <button type="submit" className="btn btn-purple discard">
            Save
          </button>
        </div>
      </div>
      <div className="mb-3">
        <h1>
          <FontAwesomeIcon className="play-icon" icon={faPlay} />
          Title
        </h1>
        <CustomEditorHosted />
      </div>
      <div className="mb-3">
        <h1>
          <FontAwesomeIcon className="play-icon" icon={faPlay} />
          Upload Image
        </h1>
        <div>
          <ImageUpload
            inputId="img1"
            imgId="img"
            // src={uploadImage}
            alt="clubs image"
            // name=
            // setValue={setValue}
            // register={register}
            type="url"

            // defaultValues={item?.images?.club_image}
          />
        </div>
      </div>
    </div>
  );
};

export default AddQa;
