import api from "../helpers/interceptor";

// ####################  get details apis for Indians colleges  #######

export const collegeList = async (payload) =>
  await api.get(
    `/getIndianColleges?search_key=${payload.search_key}&limit=${payload.limit}&page=${payload.page}&modules=${payload.modules}`
  );
// export const collegeListing = async (payload) => await api.get(`/getIndianColleges?search_key=${payload.search}&limit=${payload.limit}&page=${payload.page}&modules=${payload.modules}&city=${payload.city}&state=${payload.state}`);
export const collegeListing = async (payload) =>
  await api.post(`/getIndianColleges`, payload);

export const getClgList = async () => await api.get(`/getActiveIndianColleges`);

export const editRequestList = async (payload) =>
  await api.get(
    `/getEditRequests?search_key=${payload.search_key}&limit=${payload.limit}&page=${payload.page}&cm_id=${payload.cm_id}&status=${payload.status}`
  );
export const approveRejectRequest = async (payload) =>
  await api.put(`/approveRejectEditPermissions`, payload);

export const indCollegeDetail = async (id) =>
  await api.get(`/getIndianCollegeDetails?college_id=${id}`);

export const deleteIndCollege = async (payload) =>
  await api.put(`/deleteIndianCollege`, payload);
export const indCollegeOverview = async (payload) =>
  await api.get(
    `/getIndianCollegeOverview?college_id=${payload.college_id}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const indCollegePlacement = async (payload) =>
  await api.get(
    `/getIndianCollegePlacement?college_id=${payload.college_id}&year=${payload.year}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const cutOffData = async (payload) =>
  await api.get(
    `/getIndianCollegeCutOff?college_id=${payload.college_id}&year=${payload.year}&entrance_exam=${payload.entrance_exam}&course=${payload.course}&specialisation=${payload.specialisation}`
  );
export const getRanking = async (payload) =>
  await api.get(
    `/getIndianCollegeRanking?college_id=${payload.college_id}&year=${payload.year}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const campusLifeData = async (payload) =>
  await api.get(
    `/getIndianCollegeCampusLife?college_id=${payload.college_id}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const scholarshipList = async (payload) =>
  await api.get(
    `/getScholarships?page=${payload.page}&limit=${payload.limit}&college_id=${payload.college_id}&search_key=${payload.search_key}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const scholarshipCommon = async (payload) =>
  await api.get(
    `/getScholarships?page=${payload.page}&limit=${payload.limit}&college_id=${payload.college_id}&search_key=${payload.search_key}`
  );
export const deleteScholarship = async (payload) =>
  await api.put(`/deleteScholarship`, payload);
export const testimoData = async (payload) =>
  await api.get(
    `/getIndianCollegeTestimonialDetails?college_id=${payload.college_id}&id=${payload.id}`
  );
export const webinarLists = async (id) =>
  await api.get(`/getIndianCollegeWebinars?college_id=${id}`);
export const webinarDetails = async (id) =>
  await api.get(`/getIndianCollegeWebinarDetails?id=${id}`);
export const eventLists = async (id) =>
  await api.get(`/getIndianCollegeEvents?college_id=${id}`);
export const eventDetails = async (id) =>
  await api.get(`/getIndianCollegeEventDetails?id=${id}`);

export const getDatesToRemember = async (payload) =>
  await api.get(
    `/getIndianCollegeDates?college_id=${payload.college_id}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const addDatesToRemember = async (payload) =>
  await api.post(`/addIndianCollegeDates`, payload);
export const editDatesToRemember = async (payload) =>
  await api.put(`/editIndianCollegeDates`, payload);
export const getDateDetails = async (payload) =>
  await api.get(
    `/getIndianCollegeDateDetails?college_id=${payload.college_id}&id=${payload.id}`
  );

export const getEntrance_Exam = async (college_id) =>
  await api.get(`/getCutOffEntranceExam?college_id=${college_id}`);

// #################### add details apis for Indian colleges ###############

export const uploadImage = async (formData) =>
  await api.post(`/uploadFile`, formData);
export const collegeType = async (payload) =>
  await api.get("/getIndianCollegeType", payload);
export const addCollegeOverview = async (formData) =>
  await api.post(`/addIndianCollegeOverview`, formData);
export const editCollegeOverview = async (formData) =>
  await api.put(`/editIndianCollegeOverview`, formData);
export const addCollegePlacement = async (formData) =>
  await api.post(`/addIndianCollegePlacement`, formData);
export const editCollegePlacement = async (formData) =>
  await api.put(`/editIndianCollegePlacement`, formData);
export const addRanking = async (payload) =>
  await api.post(`/addIndianCollegeRanking`, payload);
export const editRanking = async (payload) =>
  await api.put(`/editIndianCollegeRanking`, payload);
export const getFAQ = async (payload) =>
  await api.get(
    `/getIndianCollegeFAQ?college_id=${payload.college_id}&form_type=${payload.form_type}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const addFAQ = async (payload) =>
  await api.post(`/addIndianCollegeFAQ`, payload);
export const editFAQ = async (payload) =>
  await api.put(`/editIndianCollegeFAQ`, payload);
export const getFAQDetails = async (payload) =>
  await api.get(
    `/getIndianCollegeFAQDetails?college_id=${payload.college_id}&id=${payload.id}&type=${payload.type}`
  );
export const getTestimonials = async (payload) =>
  await api.get(
    `/getIndianCollegeTestimonials?college_id=${payload.college_id}`
  );
export const addTestimonials = async (payload) =>
  await api.post(`/addIndianCollegeTestimonial`, payload);
export const editTestimonials = async (payload) =>
  await api.put(`/editIndianCollegeTestimonial`, payload);

export const addCutoff = async (payload) =>
  await api.post(`/addIndianCollegeCutOff`, payload);

export const editCutoff = async (payload) =>
  await api.put(`/editIndianCollegeCutOff`, payload);

export const addIndCollege = async (formData) =>
  await api.post(`/addNewIndianCollege`, formData);
export const addIndCollegeWebinar = async (formData) =>
  await api.post(`/addIndianCollegeWebinar`, formData);
export const editIndCollegeWebinar = async (formData) =>
  await api.put(`/editIndianCollegeWebinar`, formData);
export const addIndCollegeEvent = async (formData) =>
  await api.post(`/addIndianCollegeEvent`, formData);
export const editIndCollegeEvent = async (formData) =>
  await api.put(`/editIndianCollegeEvent`, formData);
export const addCampusLife = async (payload) =>
  await api.post(`/addIndianCollegeCampusLife`, payload);
export const editCampusLifeData = async (payload) =>
  await api.put(`/editIndianCollegeCampusLife`, payload);
export const getFacilityList = async () => await api.get(`/getOtherFacilities`);

export const addScholarship = async (payload) =>
  await api.post(`/addScholarship`, payload);
export const getScholarshipDetails = async (payload) =>
  await api.get(
    `/getScholarshipDetails?id=${payload.id}&action=${payload.action}&type=${payload.type}`
  ); //edit screen data prefillation
export const editScholarShip = async (payload) =>
  await api.put(`/editScholarshipDetails`, payload);
export const getScholarshipDetail = async (payload) =>
  await api.get(
    `/getScholarshipDetails?id=${payload.id}&action=${payload.action}&type=${payload.type}`
  ); // details page
export const getScholarshipDetailCopy = async (payload) =>
  await api.get(
    `/getScholarshipDetails?id=${payload.id}&action=${payload.action}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  ); // details page

export const courseList = async (payload) =>
  await api.get(
    `/getIndianCollegeCourses?college_id=${payload.college_id}&page=${payload.page}&limit=${payload.limit}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const courseListAdmission = async (payload) =>
  await api.get(`/getICCourses?college_id=${payload.college_id}`);
export const courseDetail = async (payload) =>
  await api.get(
    `/getIndianCollegeCourseDetails?college_id=${payload.college_id}&id=${payload.course_id}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const addCourseIndCollege = async (payload) =>
  await api.post(`/addIndianCollegeCourse`, payload);
export const editCourseIndCollege = async (payload) =>
  await api.put(`/editIndianCollegeCourseDetails`, payload);
export const updateCourseData = async (payload) =>
  await api.put(`/editIndianCollegeCourseStatus`, payload);

export const collegeStatus = async (payload) =>
  await api.put(`/editIndianCollegeStatus`, payload);

// ############ All Dropdowns api ########
export const getAllDropdowns = async (payload) =>
  await api.get(`/getAllDropdowns?type=${payload}`);
// export const getAllCityDropdowns = async(payload) => await api.get(`/getAllDropdowns?type=${payload.type}&state_id=${payload.state_id}`)

// ########## Add Admission api #############
export const getCounsellingList = async (payload) =>
  await api.get(`/getEECounsellingAgencies?exam_id=${payload}`);

export const getCounsellingDetails = async (payload) => {
  console.log("payload top", payload);

  const res = await api.get(
    `/getEECounsellingAgencyDetails?agency_id=${payload.agency_id}`
  );

  console.log("direct res", res);
  return res;
};

export const getCounsellingDetailsByAgency = async (payload) => {
  await api.get(`/getEECounsellingAgencyDetails?agency_id=${payload}`);
};

export const getCounsellingDetailsByAgencyNew = async (payload) => {
  return await api.get(`/getEECounsellingAgencyDetails?agency_id=${payload}`);
};

export const addAdmissionDetails = async (payload) =>
  await api.post(`/addIndianCollegeAdmission`, payload);
export const addAdmissionIntroduction = async (payload) =>
  await api.post(`/addEditICAdmissionIntroduction`, payload);
export const getAdmissionListing = async (payload) =>
  await api.get(
    `/getIndianCollegeAdmission?college_id=${payload.college_id}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const getAdmissionDetails = async (payload) =>
  await api.get(
    `/getIndianCollegeAdmissionDetails?college_id=${payload.college_id}&id=${payload.id}&type=${payload.type}&sub_admin_id=${payload.sub_admin_id}`
  );
export const editAdmissionDetails = async (payload) =>
  await api.put(`/editIndianCollegeAdmission`, payload);
