import { faAngleRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../HOC/Loader";
import ImageUpload from "../common/ImageUpload";
import {
	editPlacement,
	getPlacementDeatailsByid,
} from "../../services/subadmin";

const EditPlacement = () => {
	const navigate = useNavigate();
	const { id } = useParams(); // Get ID from URL parameters
	const { register, handleSubmit, setValue } = useForm(); // Initialize useForm
	const [placementDetails, setPlacementDetails] = useState({}); // State to store placement details

	useEffect(() => {
		const fetchPlacementDetails = async () => {
			try {
				const response = await getPlacementDeatailsByid({ id });
				setPlacementDetails(response?.data?.result); // Update state with fetched data
				setValue("name", response?.data?.result?.name || "");
				setValue("logo", response?.data?.result?.logo || "");
			} catch (error) {
				console.error("Failed to fetch placement details", error);
			}
		};
		fetchPlacementDetails();
	}, [id, setValue]);

	const handleEditPlacement = async (data) => {
		try {
			const payload = {
				id,
				name: data.name,
				logo: data.logo,
			};
			await editPlacement(payload);
			navigate("/placement-logo");
		} catch (error) {
			console.error("Failed to edit placement", error);
		}
	};

	const handleImageUpload = (file, url) => {
		console.log(url, "AllLogo");
		setValue("logo", url);
	};

	return (
		<div className="subadmin">
			<form onSubmit={handleSubmit(handleEditPlacement)}>
				<div className="subheader-title mb-3">
					<div className="breadcrumps">
						<a onClick={() => navigate("/placement-logo")}>Placement logo</a>
						<FontAwesomeIcon icon={faAngleRight} />
						<a>Edit Placement</a>
					</div>
					<div className="subheader-right-btm">
						<button type="submit" className="btn-purple discard">
							Save
						</button>
						<button
							type="button"
							className="btn-purple"
							onClick={() => navigate(`/subadmin-detail`)}
						>
							Discard
						</button>
					</div>
				</div>
				<div className="mb-3">
					<h6>
						<FontAwesomeIcon className="play-icon" icon={faPlay} />
						Basic Info
					</h6>
				</div>
				<div className="col-md-9 student-textarea mb-3 p-3">
					<div className="row mb-3">
						<div className="row mb-3"></div>
						<div className="col-md-3">
							<h6>
								<FontAwesomeIcon className="play-icon" icon={faPlay} />
								Company name
							</h6>
							<input
								className="form-control"
								placeholder="Johnathan Doe"
								{...register("name")}
								defaultValue={placementDetails.name || ""} // Display fetched name
								onChange={(e) =>
									setPlacementDetails({
										...placementDetails,
										name: e.target.value,
									})
								}
							/>
						</div>
						<div className="col-md-4">
							<h6>
								<FontAwesomeIcon className="play-icon" icon={faPlay} />
								Upload Image
							</h6>
							<ImageUpload
								inputId={`personalitiesImage`}
								imgId={`personImage`}
								src={placementDetails.logo || ""}
								alt="Personality Image"
								name={`personalities.image`}
								setValue={handleImageUpload}
								register={register}
								type="url"
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Loader(EditPlacement);
