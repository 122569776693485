import React, { useEffect } from "react";

import { Button, Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSearch,
  faBars,
  faBell,
  faFolder,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { generateConfirm } from "../../helpers";

const Header = (props) => {
  const user_img = process.env.PUBLIC_URL + "/assets/images/user-img.png";
  const toggleMenuHnadler = () => {
    props.onClickMenuBtn();
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector(".main-header");
    const scrollTop = window.scrollY;
    scrollTop > 50
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
  }

  const name = localStorage.getItem("name") || "";
  return (
    <header className="main-header">
      <Navbar expand="lg" className="header-nav">
        <Container fluid className="justify-content-end">
          <Button onClick={toggleMenuHnadler} className="menu-btn">
            <FontAwesomeIcon icon={faBars} />
          </Button>
          {/* <Form.Group controlId="formFile" className="subheader-search">
            <Form.Control type="search" placeholder="Search here" />
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
          </Form.Group> */}
          <div className="header-nav-right">
            <div className="header-nav-items">
              {/* <Button className="btn-icon">
                <FontAwesomeIcon icon={faBell} />
              </Button>
              <Button className="btn-icon">
                <FontAwesomeIcon icon={faSquareCheck} />
              </Button>
              <Button className="btn-icon">
                <FontAwesomeIcon icon={faFolder} />
              </Button> */}
            </div>
            <Dropdown className="user-profile">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={user_img} />
                <div className="user-deitail">
                  <div className="user-name">{name}</div>
                  <p className="user-position">Admin</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/profile-detail">My Account</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Settings</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                  generateConfirm(
                    "Are you sure you want to Log out ?",
                    " ",
                    "log-out",
                    () => {
                      logout()
                      navigate("/")
                    }
                  )
                }}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
