const initialState = {
  data: null,
};

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEND_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FILTER_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const prefrencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PREFRENCES_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const mentorPrefrencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MENTOR_PREFRENCES_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const filterReducerAssignToOther = (state = initialState, action) => {
  switch (action.type) {
    case "FILTER_ASSIGN_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const prefrencesReducerAssignToOther = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "PREFRENCES_ASSIGN_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const filterReducerAssignedCollege = (state = initialState, action) => {
  switch (action.type) {
    case "FILTER_ASSIGNED_COLLEGE":
      return {
        ...state,
        data: action.payload,
      };
    case "RESET_ASSIGNED_COLLEGE_FILTER":
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};
