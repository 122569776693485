import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <h3>Copyright {new Date().getFullYear()} - Sygnific Careers</h3>
          </div>
          <div className="col-md-4">
            <h3>
              Connect{" "}
              <span>
                <FontAwesomeIcon icon={faFacebook} />{" "}
              </span>
              <span>
                <FontAwesomeIcon icon={faYoutube} />{" "}
              </span>
              <span>
                <FontAwesomeIcon icon={faLinkedin} />{" "}
              </span>{" "}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
