/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbars from "react-custom-scrollbars";
import { Link, useLocation } from "react-router-dom";
import MyContext from "../../context/permissions";
import { logo, Logo } from "../../constants/images";
import usePermissions from "../../hooks/usePermissions";

const Sidebar = () => {
  const permission = usePermissions();
  const location = useLocation();
  const [collegeVisible, setCollegeVisible] = useState(false);

  const [userVisible, setUserVisible] = useState(false);
  const [mentorVisible, setMentorVisible] = useState(false);

  const [testVisible, setTestVisible] = useState(false);

  return (
    <aside className="sidebar">
      <div className="sidebar-header">
        <a href="#">
          <img className="logo" src={logo} />
          <img className="fevicon" src={Logo} />
        </a>
      </div>
      <div className="sidebar-menu">
        <Scrollbars className="sidebar-menu-scroll">
          <ul className="sidebar-menu-list">
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
                to="/dashboard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30.799"
                  height="30.799"
                  viewBox="0 0 30.799 30.799"
                >
                  <g
                    id="vuesax_bulk_category"
                    data-name="vuesax/bulk/category"
                    transform="translate(-108 -188)"
                  >
                    <g id="category" transform="translate(108 188)">
                      <path
                        id="Vector"
                        d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C11,1.476,9.522,0,6.725,0Z"
                        transform="translate(2.567 2.567)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                        transform="translate(17.248 2.567)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                        transform="translate(17.248 17.235)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712C0,9.522,1.476,11,4.273,11H6.712c2.8,0,4.273-1.476,4.273-4.273V4.286C11,1.476,9.522,0,6.725,0Z"
                        transform="translate(2.567 17.235)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M0,0H30.8V30.8H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Dashboard</span>
              </Link>
            </li>
            <li></li>

            <li>
              <a
                onClick={() => {
                  setCollegeVisible(!collegeVisible);
                  if (testVisible) {
                    setTestVisible(!testVisible);
                  }
                }}
                className={`tab-links show ${
                  location.pathname === "/academics" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                >
                  <g
                    id="vuesax_bulk_teacher"
                    data-name="vuesax/bulk/teacher"
                    transform="translate(-172 -252)"
                  >
                    <g id="teacher" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                        transform="translate(6.791 15.515)"
                        fill="#fff"
                        opacity="0.62"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                        transform="translate(3.121 2.414)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H29V29H0Z"
                        fill="white"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span className="sidebar-menu-list-submenu">Colleges</span>
              </a>
              <ul className={`sub-menu ${collegeVisible ? "is-open" : ""}`}>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/colleges-list" ||
                      location.pathname.indexOf("/indian-colleges-details") >
                        -1 ||
                      location.pathname.indexOf("/edit-placement") > -1 ||
                      location.pathname.indexOf("/edit-overview-detail") > -1 ||
                      location.pathname.indexOf("/edit-rankings-detail") > -1 ||
                      location.pathname.indexOf("/edit-cutoff") > -1 ||
                      location.pathname.indexOf("/add-overview-detail") > -1 ||
                      location.pathname.indexOf("/edit-overview-detail") > -1 ||
                      location.pathname.indexOf("/add-faq") > -1 ||
                      location.pathname.indexOf("/admission") > -1 ||
                      location.pathname.indexOf("/testimonial") > -1 ||
                      location.pathname.indexOf("/add-testimonial") > -1 ||
                      location.pathname.indexOf("/add-webinar") > -1 ||
                      location.pathname.indexOf("/webinar-details") > -1
                        ? "active"
                        : ""
                    }`}
                    to="/colleges-list"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Indian Colleges</span>
                  </Link>
                </li>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/overseas-colleges" ? "active" : ""
                    }`}
                    to="/overseas-colleges"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Overseas Colleges</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                onClick={() => {
                  setTestVisible(!testVisible);
                  if (collegeVisible) {
                    setCollegeVisible(!collegeVisible);
                  }
                }}
                className={`tab-links show ${
                  location.pathname === "/academics" ? "active" : ""
                }`}
                to="/academics"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30.901"
                  height="30.901"
                  viewBox="0 0 30.901 30.901"
                >
                  <g
                    id="vuesax_bulk_edit"
                    data-name="vuesax/bulk/edit"
                    transform="translate(-620 -252)"
                  >
                    <g id="edit" transform="translate(620 252)">
                      <path
                        id="Vector"
                        d="M17.356,0H7.107C2.665,0,0,2.652,0,7.107V17.343c0,4.468,2.665,7.12,7.107,7.12H17.343c4.455,0,7.107-2.652,7.107-7.107V7.107C24.463,2.652,21.8,0,17.356,0Z"
                        transform="translate(2.575 3.863)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M8.52,1.758c-2.3-2.318-4.558-2.369-6.927,0L.138,3.2a.469.469,0,0,0-.116.476A9.654,9.654,0,0,0,6.6,10.255a.528.528,0,0,0,.142.013.5.5,0,0,0,.348-.142L8.52,8.684a4.948,4.948,0,0,0,1.751-3.438A5.049,5.049,0,0,0,8.52,1.758Z"
                        transform="translate(18.544 2.079)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M14.386,6.541a10.291,10.291,0,0,1-.991-.528c-.258-.155-.515-.322-.76-.5A6.72,6.72,0,0,1,11.966,5a1.324,1.324,0,0,1-.206-.18,9.275,9.275,0,0,1-1.223-1.236.986.986,0,0,1-.167-.219A4.808,4.808,0,0,1,9.88,2.7,6.162,6.162,0,0,1,9.416,2,10.152,10.152,0,0,1,8.9,1.017C8.734.657,8.605.322,8.489,0L1.563,6.927A3.952,3.952,0,0,0,.584,8.858L.03,12.7a2.41,2.41,0,0,0,.605,2.073,2.283,2.283,0,0,0,1.648.644c.142,0,.283-.013.425-.026l3.824-.541a3.847,3.847,0,0,0,1.931-.979L15.391,6.94C15.069,6.837,14.747,6.7,14.386,6.541Z"
                        transform="translate(8.609 6.875)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H30.9V30.9H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span className="sidebar-menu-list-submenu">Tests</span>
              </a>
              <ul className={`sub-menu ${testVisible ? "is-open" : ""}`}>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/psychometric-test" ? "active" : ""
                    }`}
                    to="/psychometric-test"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Psychometric Test</span>
                  </Link>
                </li>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/overseas-test" ? "active" : ""
                    }`}
                    to="/overseas-test"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Overseas Test</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/vacational-course-list"
                    ? "active"
                    : ""
                }`}
                to="/vacational-course-list"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g
                    id="vuesax_bulk_book"
                    data-name="vuesax/bulk/book"
                    transform="translate(-492 -188)"
                  >
                    <g id="book" transform="translate(492 188)">
                      <path
                        id="Vector"
                        d="M21.25,6.25v10H3.563A3.569,3.569,0,0,0,0,19.813V6.25C0,1.25,1.25,0,6.25,0H15C20,0,21.25,1.25,21.25,6.25Z"
                        transform="translate(4.375 2.5)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.25,0V4.375A4.38,4.38,0,0,1,16.875,8.75H4.375A4.38,4.38,0,0,1,0,4.375V3.563A3.569,3.569,0,0,1,3.563,0Z"
                        transform="translate(4.375 18.75)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M10.938,1.875h-10A.944.944,0,0,1,0,.938.944.944,0,0,1,.938,0h10a.944.944,0,0,1,.938.938A.944.944,0,0,1,10.938,1.875Z"
                        transform="translate(9.063 7.813)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M7.188,1.875H.938A.944.944,0,0,1,0,.938.944.944,0,0,1,.938,0h6.25a.944.944,0,0,1,.938.938A.944.944,0,0,1,7.188,1.875Z"
                        transform="translate(9.063 12.188)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M0,0H30V30H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Vocational Courses</span>
              </Link>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/career-list" ? "active" : ""
                }`}
                to="/career-list"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.199"
                  height="31.199"
                  viewBox="0 0 31.199 31.199"
                >
                  <g
                    id="vuesax_bulk_brifecase-tick"
                    data-name="vuesax/bulk/brifecase-tick"
                    transform="translate(-236 -188)"
                    opacity="0.8"
                  >
                    <g id="brifecase-tick" transform="translate(236 188)">
                      <path
                        id="Vector"
                        d="M25.609,0,24.8,8.814c-.273,2.6-1.339,5.252-7.059,5.252H7.839c-5.72,0-6.786-2.652-7.046-5.239L0,.364A.179.179,0,0,0,.052.4c.442.286.871.572,1.339.832.182.117.377.221.572.325A21.4,21.4,0,0,0,6.63,3.445,16.972,16.972,0,0,0,8.619,3.9,4.221,4.221,0,0,0,17,3.822V3.809c.663-.13,1.326-.3,1.976-.494a22.589,22.589,0,0,0,4.667-2.041,1.284,1.284,0,0,0,.195-.117A16.679,16.679,0,0,0,25.557.065.13.13,0,0,0,25.609,0Z"
                        transform="translate(2.795 14.833)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M24.813,6.773c-1.1-1.222-2.938-1.833-5.629-1.833h-.312V4.888c0-2.184,0-4.888-4.888-4.888H12.008C7.12,0,7.12,2.7,7.12,4.888V4.94H6.808c-2.691,0-4.537.611-5.629,1.833A5.922,5.922,0,0,0,.061,11.44l.013.091L.191,12.9a.284.284,0,0,0,.065.039c.442.286.871.572,1.339.832.182.117.377.221.572.325a21.564,21.564,0,0,0,4.654,1.885,6.175,6.175,0,0,0,12.35-.078v-.052a21.733,21.733,0,0,0,4.68-2.041c.078-.039.13-.078.195-.117A16.679,16.679,0,0,0,25.762,12.6a.284.284,0,0,0,.052-.065l.052-.468.065-.611c.013-.078.013-.143.026-.234A5.745,5.745,0,0,0,24.813,6.773ZM8.979,4.888c0-2.21,0-3.042,3.029-3.042h1.976c3.029,0,3.029.832,3.029,3.042V4.94H8.979ZM13,20.123A4.226,4.226,0,0,1,8.81,16.432a3.546,3.546,0,0,1-.039-.533,4.225,4.225,0,0,1,8.45,0c0,.156-.013.3-.026.442v.013A4.236,4.236,0,0,1,13,20.123Z"
                        transform="translate(2.604 2.301)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M2.259,4.5a.964.964,0,0,1-.689-.286L.283,2.935A.974.974,0,0,1,1.661,1.557l.624.624L4.378.257A.975.975,0,0,1,5.7,1.687L2.935,4.248A1.139,1.139,0,0,1,2.259,4.5Z"
                        transform="translate(12.6 16.343)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H31.2V31.2H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Careers</span>
              </Link>
            </li>
            <li>
              <Link className="tab-links" to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.301"
                  height="29.301"
                  viewBox="0 0 29.301 29.301"
                >
                  <g
                    id="vuesax_bulk_task-square"
                    data-name="vuesax/bulk/task-square"
                    transform="translate(-494 -317.97)"
                  >
                    <g id="task-square" transform="translate(494 317.97)">
                      <path
                        id="Vector"
                        d="M20.789,0H8.512C3.179,0,0,3.179,0,8.512V20.789C0,26.122,3.179,29.3,8.512,29.3H20.789c5.333,0,8.512-3.179,8.512-8.512V8.512C29.3,3.179,26.122,0,20.789,0Z"
                        transform="translate(0)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M9.889,1.1a1.1,1.1,0,0,1-1.1,1.1H1.1A1.107,1.107,0,0,1,0,1.1,1.107,1.107,0,0,1,1.1,0H8.79A1.1,1.1,0,0,1,9.889,1.1Z"
                        transform="translate(14.006 8.966)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M6.278,1.872l-3.3,3.3A1.087,1.087,0,0,1,2.2,5.49a1.063,1.063,0,0,1-.776-.322l-1.1-1.1a1.079,1.079,0,0,1,0-1.553,1.105,1.105,0,0,1,1.553,0l.322.322L4.725.319A1.1,1.1,0,0,1,6.278,1.872Z"
                        transform="translate(5.399 6.772)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M9.889,1.1a1.1,1.1,0,0,1-1.1,1.1H1.1A1.107,1.107,0,0,1,0,1.1,1.107,1.107,0,0,1,1.1,0H8.79A1.1,1.1,0,0,1,9.889,1.1Z"
                        transform="translate(14.006 19.221)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M6.278,1.872l-3.3,3.3A1.087,1.087,0,0,1,2.2,5.49a1.063,1.063,0,0,1-.776-.322l-1.1-1.1a1.079,1.079,0,0,1,0-1.553,1.105,1.105,0,0,1,1.553,0l.322.322L4.725.319A1.1,1.1,0,0,1,6.278,1.872Z"
                        transform="translate(5.399 17.027)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>

                <span>Quiz</span>
              </Link>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/entranceexam" ||
                  location.pathname.indexOf("/entranceexam-detail") > -1
                    ? "active"
                    : ""
                }`}
                to="/entranceexam"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.6"
                  height="31.6"
                  viewBox="0 0 31.6 31.6"
                >
                  <g
                    id="vuesax_bulk_note"
                    data-name="vuesax/bulk/note"
                    transform="translate(-428 -252)"
                  >
                    <g id="note" transform="translate(428 252)">
                      <path
                        id="Vector"
                        d="M0,0H31.6V31.6H0Z"
                        fill="none"
                        opacity="0"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.066,5.267V18.1c0,3.95-2.357,5.267-5.267,5.267H5.267C2.357,23.371,0,22.054,0,18.1V5.267C0,.987,2.357,0,5.267,0a2.945,2.945,0,0,0,.869,2.093,2.945,2.945,0,0,0,2.094.869h4.608A2.967,2.967,0,0,0,15.8,0C18.71,0,21.066.987,21.066,5.267Z"
                        transform="translate(5.267 5.596)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M10.533,2.962A2.967,2.967,0,0,1,7.571,5.925H2.962A2.962,2.962,0,0,1,2.962,0H7.571a2.967,2.967,0,0,1,2.962,2.962Z"
                        transform="translate(10.533 2.633)"
                        fill="#fff"
                      />
                      <g id="Group" transform="translate(9.546 16.129)">
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M6.254,1.975H.987A1,1,0,0,1,0,.987,1,1,0,0,1,.987,0H6.254a1,1,0,0,1,.987.987A1,1,0,0,1,6.254,1.975Z"
                          fill="#fff"
                        />
                      </g>
                      <g
                        id="Group-2"
                        data-name="Group"
                        transform="translate(9.546 21.396)"
                      >
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M11.521,1.975H.987A1,1,0,0,1,0,.987,1,1,0,0,1,.987,0H11.521a1,1,0,0,1,.987.987A1,1,0,0,1,11.521,1.975Z"
                          fill="#fff"
                        />
                      </g>
                      <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,0H31.6V31.6H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Entrance Exams</span>
              </Link>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/scholarship-list" ? "active" : ""
                }`}
                to="/scholarship-list"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                >
                  <g
                    id="vuesax_bulk_teacher"
                    data-name="vuesax/bulk/teacher"
                    transform="translate(-172 -252)"
                  >
                    <g id="teacher" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                        transform="translate(6.791 15.515)"
                        fill="grey"
                        opacity="0.62"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                        transform="translate(3.121 2.414)"
                        fill="grey"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H29V29H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Scholarships</span>
              </Link>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/clubs-list" ? "active" : ""
                }`}
                to="/clubs-list"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.533"
                  height="32.533"
                  viewBox="0 0 32.533 32.533"
                >
                  <g
                    id="vuesax_bulk_clipboard-tick"
                    data-name="vuesax/bulk/clipboard-tick"
                    transform="translate(-172 -252)"
                  >
                    <g id="clipboard-tick" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M17.554,0H6.059A6.066,6.066,0,0,0,0,6.059V18.815a6.074,6.074,0,0,0,6.059,6.059H17.541A6.066,6.066,0,0,0,23.6,18.815V6.059A6.046,6.046,0,0,0,17.554,0Z"
                        transform="translate(4.46 4.948)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M8.933,0H2.562A2.557,2.557,0,0,0,0,2.548V3.823A2.545,2.545,0,0,0,2.548,6.371H8.933a2.545,2.545,0,0,0,2.548-2.548V2.548A2.537,2.537,0,0,0,8.933,0Z"
                        transform="translate(10.519 2.711)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M3.047,7.452a1,1,0,0,1-.718-.3L.295,5.121A1.016,1.016,0,0,1,1.732,3.684L3.047,5l4.7-4.7A1.016,1.016,0,0,1,9.187,1.732L3.765,7.154A.983.983,0,0,1,3.047,7.452Z"
                        transform="translate(11.607 15.524)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H32.533V32.533H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Clubs</span>
              </Link>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/static-content" ? "active" : ""
                }`}
                to="/static-content"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.533"
                  height="32.533"
                  viewBox="0 0 32.533 32.533"
                >
                  <g
                    id="vuesax_bulk_clipboard-tick"
                    data-name="vuesax/bulk/clipboard-tick"
                    transform="translate(-172 -252)"
                  >
                    <g id="clipboard-tick" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M17.554,0H6.059A6.066,6.066,0,0,0,0,6.059V18.815a6.074,6.074,0,0,0,6.059,6.059H17.541A6.066,6.066,0,0,0,23.6,18.815V6.059A6.046,6.046,0,0,0,17.554,0Z"
                        transform="translate(4.46 4.948)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M8.933,0H2.562A2.557,2.557,0,0,0,0,2.548V3.823A2.545,2.545,0,0,0,2.548,6.371H8.933a2.545,2.545,0,0,0,2.548-2.548V2.548A2.537,2.537,0,0,0,8.933,0Z"
                        transform="translate(10.519 2.711)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M3.047,7.452a1,1,0,0,1-.718-.3L.295,5.121A1.016,1.016,0,0,1,1.732,3.684L3.047,5l4.7-4.7A1.016,1.016,0,0,1,9.187,1.732L3.765,7.154A.983.983,0,0,1,3.047,7.452Z"
                        transform="translate(11.607 15.524)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H32.533V32.533H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Static Content</span>
              </Link>
            </li>
            <li>
              <Link className="tab-links" to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.533"
                  height="32.533"
                  viewBox="0 0 32.533 32.533"
                >
                  <g
                    id="vuesax_bulk_clipboard-tick"
                    data-name="vuesax/bulk/clipboard-tick"
                    transform="translate(-172 -252)"
                  >
                    <g id="clipboard-tick" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M17.554,0H6.059A6.066,6.066,0,0,0,0,6.059V18.815a6.074,6.074,0,0,0,6.059,6.059H17.541A6.066,6.066,0,0,0,23.6,18.815V6.059A6.046,6.046,0,0,0,17.554,0Z"
                        transform="translate(4.46 4.948)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M8.933,0H2.562A2.557,2.557,0,0,0,0,2.548V3.823A2.545,2.545,0,0,0,2.548,6.371H8.933a2.545,2.545,0,0,0,2.548-2.548V2.548A2.537,2.537,0,0,0,8.933,0Z"
                        transform="translate(10.519 2.711)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M3.047,7.452a1,1,0,0,1-.718-.3L.295,5.121A1.016,1.016,0,0,1,1.732,3.684L3.047,5l4.7-4.7A1.016,1.016,0,0,1,9.187,1.732L3.765,7.154A.983.983,0,0,1,3.047,7.452Z"
                        transform="translate(11.607 15.524)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H32.533V32.533H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>News</span>
              </Link>
            </li>
            <li>
              <Link
                className={`tab-links ${
                  location.pathname === "/subadmin-detail" ? "active" : ""
                }`}
                to="/subadmin-detail"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30.799"
                  height="30.799"
                  viewBox="0 0 30.799 30.799"
                >
                  <g
                    id="vuesax_bulk_category"
                    data-name="vuesax/bulk/category"
                    transform="translate(-108 -188)"
                  >
                    <g id="category" transform="translate(108 188)">
                      <path
                        id="Vector"
                        d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C11,1.476,9.522,0,6.725,0Z"
                        transform="translate(2.567 2.567)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                        transform="translate(17.248 2.567)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                        transform="translate(17.248 17.235)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712C0,9.522,1.476,11,4.273,11H6.712c2.8,0,4.273-1.476,4.273-4.273V4.286C11,1.476,9.522,0,6.725,0Z"
                        transform="translate(2.567 17.235)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M0,0H30.8V30.8H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>Subadmin</span>
              </Link>
            </li>
            <li>
              <a
                onClick={() => {
                  setCollegeVisible(!collegeVisible);
                  if (testVisible) {
                    setTestVisible(!testVisible);
                  }
                }}
                className={`tab-links show ${
                  location.pathname === "/academics" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                >
                  <g
                    id="vuesax_bulk_teacher"
                    data-name="vuesax/bulk/teacher"
                    transform="translate(-172 -252)"
                  >
                    <g id="teacher" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                        transform="translate(6.791 15.515)"
                        fill="#fff"
                        opacity="0.62"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                        transform="translate(3.121 2.414)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H29V29H0Z"
                        fill="white"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span className="sidebar-menu-list-submenu">Master</span>
              </a>
              <ul className={`sub-menu ${collegeVisible ? "is-open" : ""}`}>
                <li className="">
                  {/* <Link
                   className={`tab-links  ${location.pathname === "/master" ? "active" : ""
}`}
                    to="/master"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Sub Admin</span>
                  </Link> */}
                </li>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/placement-logo" ? "active" : ""
                    }`}
                    to="/placement-logo"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Placement Logo</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link
                className={`tab-links  ${location.pathname === "/master" ? "active" : ""
                  }`}
                to="/master"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.6"
                  height="31.6"
                  viewBox="0 0 31.6 31.6"
                >
                  <g
                    id="vuesax_bulk_note"
                    data-name="vuesax/bulk/note"
                    transform="translate(-428 -252)"
                  >
                    <g id="note" transform="translate(428 252)">
                      <path
                        id="Vector"
                        d="M0,0H31.6V31.6H0Z"
                        fill="none"
                        opacity="0"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.066,5.267V18.1c0,3.95-2.357,5.267-5.267,5.267H5.267C2.357,23.371,0,22.054,0,18.1V5.267C0,.987,2.357,0,5.267,0a2.945,2.945,0,0,0,.869,2.093,2.945,2.945,0,0,0,2.094.869h4.608A2.967,2.967,0,0,0,15.8,0C18.71,0,21.066.987,21.066,5.267Z"
                        transform="translate(5.267 5.596)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M10.533,2.962A2.967,2.967,0,0,1,7.571,5.925H2.962A2.962,2.962,0,0,1,2.962,0H7.571a2.967,2.967,0,0,1,2.962,2.962Z"
                        transform="translate(10.533 2.633)"
                        fill="#fff"
                      />
                      <g id="Group" transform="translate(9.546 16.129)">
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M6.254,1.975H.987A1,1,0,0,1,0,.987,1,1,0,0,1,.987,0H6.254a1,1,0,0,1,.987.987A1,1,0,0,1,6.254,1.975Z"
                          fill="#fff"
                        />
                      </g>
                      <g
                        id="Group-2"
                        data-name="Group"
                        transform="translate(9.546 21.396)"
                      >
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M11.521,1.975H.987A1,1,0,0,1,0,.987,1,1,0,0,1,.987,0H11.521a1,1,0,0,1,.987.987A1,1,0,0,1,11.521,1.975Z"
                          fill="#fff"
                        />
                      </g>
                      <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,0H31.6V31.6H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>
                <span>Master</span>
              </Link>
            </li> */}
            <li>
              <a
                onClick={() => {
                  setCollegeVisible(!collegeVisible);
                  if (testVisible) {
                    setTestVisible(!testVisible);
                  }
                }}
                className={`tab-links show ${
                  location.pathname === "/acadeFmics" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                >
                  <g
                    id="vuesax_bulk_teacher"
                    data-name="vuesax/bulk/teacher"
                    transform="translate(-172 -252)"
                  >
                    <g id="teacher" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                        transform="translate(6.791 15.515)"
                        fill="#fff"
                        opacity="0.62"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                        transform="translate(3.121 2.414)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H29V29H0Z"
                        fill="white"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span className="sidebar-menu-list-submenu">
                  News Letters & Blogs
                </span>
              </a>
              <ul className={`sub-menu ${collegeVisible ? "is-open" : ""}`}>
                <li className="">
                  {/* <Link
                   className={`tab-links  ${location.pathname === "/master" ? "active" : ""
}`}
                    to="/master"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Sub Admin</span>
                  </Link> */}
                </li>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/blogs" ? "active" : ""
                    }`}
                    to="/blogs"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Blogs</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="tab-links" to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.533"
                  height="32.533"
                  viewBox="0 0 32.533 32.533"
                >
                  <g
                    id="vuesax_bulk_clipboard-tick"
                    data-name="vuesax/bulk/clipboard-tick"
                    transform="translate(-172 -252)"
                  >
                    <g id="clipboard-tick" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M17.554,0H6.059A6.066,6.066,0,0,0,0,6.059V18.815a6.074,6.074,0,0,0,6.059,6.059H17.541A6.066,6.066,0,0,0,23.6,18.815V6.059A6.046,6.046,0,0,0,17.554,0Z"
                        transform="translate(4.46 4.948)"
                        fill="#fff"
                        opacity="0.4"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M8.933,0H2.562A2.557,2.557,0,0,0,0,2.548V3.823A2.545,2.545,0,0,0,2.548,6.371H8.933a2.545,2.545,0,0,0,2.548-2.548V2.548A2.537,2.537,0,0,0,8.933,0Z"
                        transform="translate(10.519 2.711)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M3.047,7.452a1,1,0,0,1-.718-.3L.295,5.121A1.016,1.016,0,0,1,1.732,3.684L3.047,5l4.7-4.7A1.016,1.016,0,0,1,9.187,1.732L3.765,7.154A.983.983,0,0,1,3.047,7.452Z"
                        transform="translate(11.607 15.524)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H32.533V32.533H0Z"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span>CAP</span>
              </Link>
            </li>
            <li>
              <a
                onClick={() => {
                  setUserVisible(!userVisible);
                  if (mentorVisible) {
                    setMentorVisible(!mentorVisible);
                  }
                }}
                className={`tab-links show ${
                  location?.pathname?.includes("user_management")
                    ? "active"
                    : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                >
                  <g
                    id="vuesax_bulk_teacher"
                    data-name="vuesax/bulk/teacher"
                    transform="translate(-172 -252)"
                  >
                    <g id="teacher" transform="translate(172 252)">
                      <path
                        id="Vector"
                        d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                        transform="translate(6.791 15.515)"
                        fill="#fff"
                        opacity="0.62"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                        transform="translate(3.121 2.414)"
                        fill="#fff"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H29V29H0Z"
                        fill="white"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <span className="sidebar-menu-list-submenu">
                  User Management
                </span>
              </a>
              <ul className={`sub-menu ${userVisible ? "is-open" : ""}`}>
                <li className=""></li>
                <li className="">
                  <div
                    className={`tab-links ${
                      location?.pathname?.includes("user_management")
                        ? "active"
                        : ""
                    }`}
                    // to="/user_management-mentors-list"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Mentors</span>
                  </div>
                  <ul className={`sub-menu ${userVisible ? "is-open" : ""}`}>
                    <li className=""></li>
                    <li className="">
                      <Link
                        className={`tab-links ${
                          location.pathname.includes(
                            "/user_management/mentors/verifications/mentors-list"
                          )
                            ? "active"
                            : ""
                        }`}
                        to="/user_management/mentors/verifications/mentors-list"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Verification Model</span>
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className={`tab-links ${
                          location.pathname ===
                          "/user_management/content/verifications/courses-list"
                            ? "active"
                            : ""
                        }`}
                        to="/user_management/content/verifications/courses-list"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Approval</span>
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className={`tab-links ${
                          location.pathname === "/user_management-mentors-list"
                            ? "active"
                            : ""
                        }`}
                        to="/user_management-mentors-list"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Order & Purchase</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className={`sub-menu ${userVisible ? "is-open" : ""}`}>
                <li className=""></li>
                <li className="">
                  <Link
                    className={`tab-links ${
                      location.pathname === "/user_management-mentors-list"
                        ? "active"
                        : ""
                    }`}
                    to="/user_management-mentors-list"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g
                        id="vuesax_bulk_award"
                        data-name="vuesax/bulk/award"
                        transform="translate(-428 -188)"
                      >
                        <g id="award">
                          <path
                            id="Vector"
                            d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                            transform="translate(432.1 192.788)"
                            fill="#fff"
                            opacity="0.4"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                            transform="translate(434.19 190)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                            transform="translate(436.949 198.467)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(428 188)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </svg>
                    <span>Students</span>
                  </Link>
                  <ul className={`sub-menu ${userVisible ? "is-open" : ""}`}>
                    <li className=""></li>
                    <li className="">
                      <Link
                        className={`tab-links ${
                          location.pathname === "/user_management-mentors-list"
                            ? "active"
                            : ""
                        }`}
                        to="/user_management-mentors-list"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Verification</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </Scrollbars>
      </div>
    </aside>
  );
};

export default Sidebar;
