export const faqDropdown = [
  //     {
  //     label:"Employee Info",
  //     value:"employee_info"
  // },
  {
    label: "Overview",
    value: "overview",
  },
  {
    value: "campus_life",
    label: "Campus Life",
  },
  {
    value: "rankings",
    label: "Rankings",
  },
  {
    value: "courses_fees",
    label: "Courses&fees",
  },
  {
    value: "placements",
    label: "Placements",
  },
  {
    value: "cutoff",
    label: "Cutoff",
  },
  {
    value: "scholarship",
    label: "Scholarship",
  },
  {
    value: "admissions",
    label: "Admissions",
  },
  {
    value: "dates_to_remember",
    label: "Dates to Remember",
  },
  {
    value: "testimonials",
    label: "Testimonials",
  },
  // ,{
  //     value:"add_new_section",
  //     label:"Add new section"
  // }
  {
    value: "Event_Webinars",
    label: "Event & Webinars",
  },
];

export const titleDropdown = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    value: "Mr.",
    label: "Mr.",
  },
  {
    value: "Mrs.",
    label: "Mrs.",
  },
  {
    value: "Miss",
    label: "Miss",
  },
];
