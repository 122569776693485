import {
  faAdd,
  faAngleRight,
  faPlay,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CustomEditor from "../../../components/common/CustomEditor";
import ImageUpload from "../../../components/common/ImageUpload";
import { uploadImage } from "../../../services/college";
import CustomEditorHosted from "../../../components/common/CustomEditorHosted";

const AddProfile = () => {
  return (
    <div className="wrapper-assignpage">
      <div class="subheader wrapassign-subheader subheader-title-subadmin">
        <div class="">
          <h2 class="heading-36">
            Profile <FontAwesomeIcon icon={faAngleRight} /> Edit Profile
          </h2>
        </div>
        <div class="subheader-right-top ">
          <button class="add-btn btn-cancel cancel-btn">Cancel</button>
          <button type="submit" class=" add-btn btn-save save-btn">
            Save
          </button>
        </div>
      </div>
      <div>
        <ImageUpload
          inputId="shruti"
          imgId="shruti"
          src={uploadImage}
          alt="accomodation image"
          //   name={`accomodation[${nestedIndex}].images[${kindex}].accomodation_image`}
          //   setValue={setValue}
          //   register={register}
          type="url"
        />
      </div>

      <div>
        <h6 class="add-page-heading">
          <FontAwesomeIcon className="adminplayicon" icon={faPlay} />
          Basic Details
        </h6>
        <div className="row mb-3">
          <div className="col-md-3">
            <input class="form-control" placeholder="Name" name="website" />
          </div>
          <div className="col-md-3">
            <input class="form-control" placeholder="Email" name="website" />
          </div>
          <div className="col-md-3">
            <input
              class="form-control"
              placeholder="Date Of Birth"
              name="website"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <input class="form-control" placeholder="Name" name="Gender" />
          </div>
          <div className="col-md-3">
            <input
              class="form-control"
              placeholder="Email"
              name="Employee ID"
            />
          </div>
          <div className="col-md-3">
            <input
              class="form-control"
              placeholder="Designation"
              name="website"
            />
          </div>
        </div>
      </div>
      <div>
        <h6 class="add-page-heading">
          <FontAwesomeIcon className="adminplayicon" icon={faPlay} />
          Professional Details
        </h6>
        <div className="col-md-9 student-textarea p-3">
          <div class="row">
            <div class="col-md-5">
              <div class="">
                <label for="student">Title</label>
                <input
                  class="form-control"
                  type="number"
                  placeholder="Company Name"
                  name="totalStudent"
                />
              </div>
            </div>
            <div class="col-md-5 mb-3">
              <div class="">
                <label for="graduate">Value</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Designation"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-10 mb-3">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Location"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Start Date"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="End Date"
                  name="ug"
                />
              </div>
            </div>

            <div class="subheader-right-btm overview-btn">
              <button class="add-btn save-btn me-2">
                <FontAwesomeIcon icon={faAdd} />
                Add More
              </button>
              <button class="add-btn cancel-btn">
                <FontAwesomeIcon icon={faTrash} />
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 class="add-page-heading">
          <FontAwesomeIcon className="adminplayicon" icon={faPlay} />
          Education Details
        </h6>
        <div className="col-md-9 student-textarea p-3">
          <div class="row">
            <div class="col-md-5">
              <div class="">
                <label for="student">Title</label>
                <input
                  class="form-control"
                  type="number"
                  placeholder="School / University"
                  name="totalStudent"
                />
              </div>
            </div>
            <div class="col-md-5 mb-3">
              <div class="">
                <label for="graduate">Value</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Degree"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-5 mb-3">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Field of study"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-5 mb-3">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Location"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Start Date"
                  name="ug"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="">
                <input
                  type="number"
                  class="form-control"
                  placeholder="End Date"
                  name="ug"
                />
              </div>
            </div>

            <div class="subheader-right-btm overview-btn">
              <button class="add-btn save-btn me-2">
                <FontAwesomeIcon icon={faAdd} />
                Add More
              </button>
              <button class="add-btn cancel-btn">
                <FontAwesomeIcon icon={faTrash} />
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 class="add-page-heading">
          <FontAwesomeIcon className="adminplayicon" icon={faPlay} />
          Technical Skills
        </h6>
        <div class="col-md-5">
          <div class="">
            <input
              class="form-control"
              type="number"
              placeholder="Enter skills here"
              name="totalStudent"
            />
          </div>
        </div>
      </div>
      <div>
        <h6 class="add-page-heading">
          <FontAwesomeIcon className="adminplayicon" icon={faPlay} />
          Contact Details
        </h6>
        <div>
          <div class="col-md-5">
            <input
              class="form-control"
              type="number"
              placeholder="Contact Details"
              name="totalStudent"
            />
          </div>
          <div class="">
            <CustomEditorHosted />
          </div>
        </div>
      </div>
      <div>
        <h6 class="add-page-heading">
          <FontAwesomeIcon className="adminplayicon" icon={faPlay} />
          Social Details
        </h6>
        <div className="row col-md-9">
          <div className="col-md-4 mb-3">
            <div className="row">
              <div className="col-md-2">
                <img className="img-fluid" src="../assets/images/fb.svg" />
              </div>
              <div className="col-md-10 p-0">
                <input
                  class="form-control"
                  type="number"
                  placeholder="Enter link here"
                  name="totalStudent"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="row">
              <div className="col-md-2">
                <img className="img-fluid" src="../assets/images/wt.svg" />
              </div>
              <div className="col-md-10 p-0">
                <input
                  class="form-control"
                  type="number"
                  placeholder="Enter link here"
                  name="totalStudent"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="row">
              <div className="col-md-2">
                <img className="img-fluid" src="../assets/images/tw.png" />
              </div>
              <div className="col-md-10 p-0">
                <input
                  class="form-control"
                  type="number"
                  placeholder="Enter link here"
                  name="totalStudent"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="row">
              <div className="col-md-2">
                <img
                  className="img-fluid"
                  src="../assets/images/instagram.svg"
                />
              </div>
              <div className="col-md-10 p-0">
                <input
                  class="form-control"
                  type="number"
                  placeholder="Enter link here"
                  name="totalStudent"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="row">
              <div className="col-md-2">
                <img className="img-fluid" src="../assets/images/link.svg" />
              </div>
              <div className="col-md-10 p-0">
                <input
                  class="form-control"
                  type="number"
                  placeholder="Enter link here"
                  name="totalStudent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProfile;
