import React from "react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Budges = () => {
    return(
        <div className="wrap-badgespg">
        <div className="badges-content">
            <div className="custom-content">
                <div className="images-box">
                    <FontAwesomeIcon icon={faStar} />
                    <label>Level</label>
                </div>
                <h1>Fortune</h1>
            </div>
            <div className="badges-content">
            <div className="custom-content">
                <div className="images-box">
                    <FontAwesomeIcon icon={faStar} />
                    <label>Current Badges</label>
                </div>
                <h1>Fortune</h1>
                <button>View details</button>
            </div>
            </div>
        </div>
        <div className="badges-content">
            <div className="custom-content">
                <div className="images-box">
                    <FontAwesomeIcon icon={faStar} />
                    <label>Recent Points</label>
                </div>
                <h1>15000 Points</h1>
            </div>
        </div>
    </div>
    )
}
export default Budges