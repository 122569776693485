import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbars from "react-custom-scrollbars";
import { Link, useLocation } from "react-router-dom";
import MyContext from "../../context/permissions";
import { logo, Logo } from "../../constants/images";
import { menu } from "../../constants/images";
import usePermissions from "../../hooks/usePermissions";

const Menu = process.env.PUBLIC_URL + "/assets/images/menu.svg";
const SubadminSidebar = () => {

  const { permissions } = useContext(MyContext);
  const permission = usePermissions();
  const location = useLocation();
  const [collegeVisible, setCollegeVisible] = useState(false);
  const [testVisible, setTestVisible] = useState(false);
  const [indCollegeVisible, setIndCollegeVisible] = useState(false);
  const [overCollegeVisible, setOverCollegeVisible] = useState(false);
  let checkPermissions = permissions?.filter(item => item.checked)?.flatMap(item => item.permission_name)
  const admin_type = localStorage.getItem("admin_type") || "";
  const role = localStorage.getItem("role") || "";
  return (
    <div>
      <aside className="subadmin-sidebar sidebar">
        <div className="sidebar-header subadmin-sidebar-header menu row m-0">
          <div className="col-md-2 p-0"><img className="menu1" src={Menu} /></div>
          <div className="col-md-9 p-0"><img className="logo1" src={logo} /></div>
        </div>
        <div className="sidebar-menu">
          <Scrollbars className="sidebar-menu-scroll ">
            <ul className="sidebar-menu-list subadmin-menu">
              <li>
                <Link
                  className={`tab-links ${location.pathname === "/sub-admin-dashboard" ? "active" : ""
                    }`}
                  to="/sub-admin-dashboard"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.799"
                    height="30.799"
                    viewBox="0 0 30.799 30.799"
                  >
                    <g
                      id="vuesax_bulk_category"
                      data-name="vuesax/bulk/category"
                      transform="translate(-108 -188)"
                    >
                      <g id="category" transform="translate(108 188)">
                        <path
                          id="Vector"
                          d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C11,1.476,9.522,0,6.725,0Z"
                          transform="translate(2.567 2.567)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                          transform="translate(17.248 2.567)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                          transform="translate(17.248 17.235)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712C0,9.522,1.476,11,4.273,11H6.712c2.8,0,4.273-1.476,4.273-4.273V4.286C11,1.476,9.522,0,6.725,0Z"
                          transform="translate(2.567 17.235)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M0,0H30.8V30.8H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>
              {admin_type === "sub_admin" && <li>
                <Link
                  className={`tab-links ${location.pathname === "/colleges-list"
                    ? "active"
                    : ""
                    }`}
                  to="/colleges-list">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                  >
                    <g
                      id="vuesax_bulk_teacher"
                      data-name="vuesax/bulk/teacher"
                      transform="translate(-172 -252)"
                    >
                      <g id="teacher" transform="translate(172 252)">
                        <path
                          id="Vector"
                          d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                          transform="translate(6.791 15.515)"
                          fill="grey"
                          opacity="0.62"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                          transform="translate(3.121 2.414)"
                          fill="grey"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,0H29V29H0Z"
                          fill="grey"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Assigned Colleges</span>
                </Link>
              </li>}
              {role === "content_manager" ? <li>
                <Link
                  className={`tab-links ${location.pathname === "/edit-permission"
                    ? "active"
                    : ""
                    }`}
                  to="/edit-permission">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                  >
                    <g
                      id="vuesax_bulk_teacher"
                      data-name="vuesax/bulk/teacher"
                      transform="translate(-172 -252)"
                    >
                      <g id="teacher" transform="translate(172 252)">
                        <path
                          id="Vector"
                          d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                          transform="translate(6.791 15.515)"
                          fill="grey"
                          opacity="0.62"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                          transform="translate(3.121 2.414)"
                          fill="grey"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,0H29V29H0Z"
                          fill="grey"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Edit Permission</span>
                </Link>
              </li> : ""}
              {admin_type === "sub_admin" && (role === "content_manager") ?
                <li>
                  <Link className={`tab-links ${location.pathname === "/team-lead" ||
                    location.pathname.indexOf("/team-leader-profile") > -1 ||
                    location.pathname.indexOf("/intern-details") > -1
                    ? "active"
                    : ""
                    }`} to="/team-lead">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="vuesax_bulk_profile-2user" data-name="vuesax/bulk/profile-2user" transform="translate(-172 -252)" opacity="0.5">
                        <g id="profile-2user">
                          <path id="Vector" d="M4.75,0a4.746,4.746,0,0,0-.12,9.49.807.807,0,0,1,.22,0h.07A4.746,4.746,0,0,0,4.75,0Z" transform="translate(176.25 254)" fill="#fff" opacity="0.4" />
                          <path id="Vector-2" data-name="Vector" d="M12.12,1.395a9.929,9.929,0,0,0-10.15,0A3.947,3.947,0,0,0,0,4.625a3.914,3.914,0,0,0,1.96,3.21,9.239,9.239,0,0,0,5.08,1.41,9.239,9.239,0,0,0,5.08-1.41,3.945,3.945,0,0,0,1.96-3.23A3.937,3.937,0,0,0,12.12,1.395Z" transform="translate(173.96 264.755)" fill="#fff" />
                          <path id="Vector-3" data-name="Vector" d="M5.88,3.283a3.579,3.579,0,0,1-3.13,3.87H2.7a.468.468,0,0,0-.17.02A3.588,3.588,0,0,1,0,6.343a4.576,4.576,0,0,0,1.5-3.8A4.641,4.641,0,0,0,.73.363a3.59,3.59,0,0,1,5.15,2.92Z" transform="translate(186.11 256.057)" fill="#fff" opacity="0.4" />
                          <path id="Vector-4" data-name="Vector" d="M8,4.09A3.046,3.046,0,0,1,6.26,6.47a7.058,7.058,0,0,1-3.51.78A3.569,3.569,0,0,0,3.97,4.93,3.94,3.94,0,0,0,2.3,1.55,8.255,8.255,0,0,0,0,.29,7.815,7.815,0,0,1,6.7,1.46,3.038,3.038,0,0,1,8,4.09Z" transform="translate(185.99 264.5)" fill="#fff" />
                          <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 252)" fill="none" opacity="0" />
                        </g>
                      </g>
                    </svg>


                    <span>Team Leaders</span>
                  </Link>
                </li> : ""}

              {admin_type === "sub_admin" && (role === "content_manager" || role === "team_lead" && role === "intern") && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="vuesax_bulk_chart"
                      data-name="vuesax/bulk/chart"
                      transform="translate(-556 -252)"
                      opacity="0.5"
                    >
                      <g id="chart">
                        <path
                          id="Vector"
                          d="M20.75,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h20a.755.755,0,0,1,.75.75A.755.755,0,0,1,20.75,1.5Z"
                          transform="translate(557.25 272.5)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M0,2V20H4.5V2c0-1.1-.45-2-1.8-2H1.8C.45,0,0,.9,0,2Z"
                          transform="translate(565.75 254)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,2V14H4V2C4,.9,3.6,0,2.4,0H1.6C.4,0,0,.9,0,2Z"
                          transform="translate(559 260)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,2V9H4V2C4,.9,3.6,0,2.4,0H1.6C.4,0,0,.9,0,2Z"
                          transform="translate(573 265)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M0,0H24V24H0Z"
                          transform="translate(556 252)"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Reports</span>
                </Link>
              </li>}
              {admin_type === "sub_admin" && (role === "content_manager" || role === "team_lead") && <li>
                <Link className={`tab-links ${location.pathname === "/assign-task-list" ||
                  location.pathname.indexOf("/add-assign-task") > -1 ||
                  location.pathname.indexOf("/assign-college-list") > -1
                  ? "active"
                  : ""
                  }`} to="/assign-task-list">
                  <svg xmlns="http://www.w3.org/2000/svg" width="27.129" height="27.129" viewBox="0 0 27.129 27.129">
                    <g id="vuesax_bulk_note" data-name="vuesax/bulk/note" transform="translate(-428 -252)" opacity="0.5">
                      <g id="note" transform="translate(428 252)">
                        <path id="Vector" d="M0,0H27.129V27.129H0Z" fill="none" opacity="0" />
                        <path id="Vector-2" data-name="Vector" d="M18.086,4.521V15.542c0,3.391-2.023,4.521-4.521,4.521H4.521c-2.5,0-4.521-1.13-4.521-4.521V4.521C0,.848,2.023,0,4.521,0a2.529,2.529,0,0,0,.746,1.8,2.529,2.529,0,0,0,1.8.746h3.956A2.547,2.547,0,0,0,13.564,0C16.062,0,18.086.848,18.086,4.521Z" transform="translate(4.521 4.804)" fill="#fff" opacity="0.4" />
                        <path id="Vector-3" data-name="Vector" d="M9.043,2.543A2.547,2.547,0,0,1,6.5,5.087H2.543A2.543,2.543,0,0,1,2.543,0H6.5A2.547,2.547,0,0,1,9.043,2.543Z" transform="translate(9.043 2.261)" fill="#fff" />
                        <g id="Group" transform="translate(8.195 13.847)">
                          <path id="Vector-4" data-name="Vector" d="M5.369,1.7H.848A.854.854,0,0,1,0,.848.854.854,0,0,1,.848,0H5.369a.854.854,0,0,1,.848.848A.854.854,0,0,1,5.369,1.7Z" fill="#fff" />
                        </g>
                        <g id="Group-2" data-name="Group" transform="translate(8.195 18.368)">
                          <path id="Vector-5" data-name="Vector" d="M9.891,1.7H.848A.854.854,0,0,1,0,.848.854.854,0,0,1,.848,0H9.891a.854.854,0,0,1,.848.848A.854.854,0,0,1,9.891,1.7Z" fill="#fff" />
                        </g>
                        <path id="Vector-6" data-name="Vector" d="M0,0H27.129V27.129H0Z" fill="none" opacity="0" />
                      </g>
                    </g>
                  </svg>
                  <span>Assign Task</span>
                </Link>
              </li>}
              {role === "intern" ?
                <li>
                  <Link
                    className={`tab-links ${location.pathname === "/assign-to-me-list" ||
                      location.pathname.indexOf("/indian-colleges-details") > -1 ||
                      location.pathname.indexOf("/edit-placement") > -1 ||
                      location.pathname.indexOf("/edit-overview-detail") > -1 ||
                      location.pathname.indexOf("/edit-rankings-detail") > -1 ||
                      location.pathname.indexOf("/edit-cutoff") > -1 ||
                      location.pathname.indexOf("/add-overview-detail") > -1 ||
                      location.pathname.indexOf("/edit-overview-detail") > -1 ||
                      location.pathname.indexOf("/add-faq") > -1 ||
                      location.pathname.indexOf("/admission") > -1 ||
                      location.pathname.indexOf("/testimonial") > -1 ||
                      location.pathname.indexOf("/add-testimonial") > -1 ||
                      location.pathname.indexOf("/add-webinar") > -1 ||
                      location.pathname.indexOf("/webinar-details") > -1
                      ? "active"
                      : ""
                      }`}
                    to="/assign-to-me-list">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="vuesax_bulk_book" data-name="vuesax/bulk/book" transform="translate(-556 -188)" opacity="0.5">
                        <g id="book">
                          <path id="Vector" d="M10,2.638v16.03a1.009,1.009,0,0,1-.49-.11l-.04-.02A25.693,25.693,0,0,0,2.03,16.1l-.29-.04A2.055,2.055,0,0,1,0,14.078V2A1.967,1.967,0,0,1,2.16.008a18.757,18.757,0,0,1,7.06,2.34l.25.15A1.048,1.048,0,0,0,10,2.638Z" transform="translate(558 190.662)" fill="#fff" opacity="0.4" />
                          <path id="Vector-2" data-name="Vector" d="M10,2v12.07a2.055,2.055,0,0,1-1.74,1.98l-.33.04a25.461,25.461,0,0,0-7.46,2.46.885.885,0,0,1-.47.11V2.628a1.048,1.048,0,0,0,.53-.14l.17-.11A18.851,18.851,0,0,1,7.77.008h.06A1.97,1.97,0,0,1,10,2Z" transform="translate(568 190.672)" fill="#fff" />
                          <path id="Vector-3" data-name="Vector" d="M3,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0H3a.755.755,0,0,1,.75.75A.755.755,0,0,1,3,1.5Z" transform="translate(560.75 195.74)" fill="#fff" />
                          <path id="Vector-4" data-name="Vector" d="M3.75,1.5h-3A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h3A.755.755,0,0,1,4.5.75.755.755,0,0,1,3.75,1.5Z" transform="translate(560.75 198.74)" fill="#fff" />
                          <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(556 188)" fill="none" opacity="0" />
                        </g>
                      </g>
                    </svg>
                    <span>Indian College</span>
                  </Link>
                </li> : <li>
                  <a
                    onClick={() => {
                      setIndCollegeVisible(!indCollegeVisible);
                      if (overCollegeVisible) {
                        setOverCollegeVisible(!overCollegeVisible);
                      }
                    }}
                    className={`tab-links show ${location.pathname === "/academics" ? "active" : ""
                      }`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="vuesax_bulk_book" data-name="vuesax/bulk/book" transform="translate(-556 -188)" opacity="0.5">
                        <g id="book">
                          <path id="Vector" d="M10,2.638v16.03a1.009,1.009,0,0,1-.49-.11l-.04-.02A25.693,25.693,0,0,0,2.03,16.1l-.29-.04A2.055,2.055,0,0,1,0,14.078V2A1.967,1.967,0,0,1,2.16.008a18.757,18.757,0,0,1,7.06,2.34l.25.15A1.048,1.048,0,0,0,10,2.638Z" transform="translate(558 190.662)" fill="#fff" opacity="0.4" />
                          <path id="Vector-2" data-name="Vector" d="M10,2v12.07a2.055,2.055,0,0,1-1.74,1.98l-.33.04a25.461,25.461,0,0,0-7.46,2.46.885.885,0,0,1-.47.11V2.628a1.048,1.048,0,0,0,.53-.14l.17-.11A18.851,18.851,0,0,1,7.77.008h.06A1.97,1.97,0,0,1,10,2Z" transform="translate(568 190.672)" fill="#fff" />
                          <path id="Vector-3" data-name="Vector" d="M3,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0H3a.755.755,0,0,1,.75.75A.755.755,0,0,1,3,1.5Z" transform="translate(560.75 195.74)" fill="#fff" />
                          <path id="Vector-4" data-name="Vector" d="M3.75,1.5h-3A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h3A.755.755,0,0,1,4.5.75.755.755,0,0,1,3.75,1.5Z" transform="translate(560.75 198.74)" fill="#fff" />
                          <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(556 188)" fill="none" opacity="0" />
                        </g>
                      </g>
                    </svg>
                    <span className="sidebar-menu-list-submenu">Indian College </span>
                  </a>
                  <ul className={`sub-menu ${indCollegeVisible ? "is-open" : ""}`}>
                    {/* {(admin_type === "sub_admin" && permission["Indian Colleges"]) && */}
                    <li className="">
                      <Link
                        // className="tab-links" to="/assign-to-other-list"
                        className={`tab-links ${location.pathname === "/assign-to-other-list"
                          // location.pathname.indexOf("/indian-colleges-details") > -1 ||
                          // location.pathname.indexOf("/edit-placement") > -1 ||
                          // location.pathname.indexOf("/edit-overview-detail") > -1 ||
                          // location.pathname.indexOf("/edit-rankings-detail") > -1 ||
                          // location.pathname.indexOf("/edit-cutoff") > -1 ||
                          // location.pathname.indexOf("/add-overview-detail") > -1 ||
                          // location.pathname.indexOf("/edit-overview-detail") > -1 ||
                          // location.pathname.indexOf("/add-faq") > -1 ||
                          // location.pathname.indexOf("/admission") > -1 ||
                          // location.pathname.indexOf("/testimonial") > -1 ||
                          // location.pathname.indexOf("/add-testimonial") > -1 ||
                          // location.pathname.indexOf("/add-webinar") > -1 ||
                          // location.pathname.indexOf("/webinar-details") > -1
                          ?
                          "active"
                          : ""
                          }`}
                        to="/assign-to-other-list">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        {role === "team_lead"? <span>Assigned to Interns</span>:<span>Assigned to Others</span>}
                      </Link>
                    </li>
                    {role === "content_manager" ? "" : <li className="">
                      <Link
                        className={`tab-links ${location.pathname === "/assign-to-me-list" ||
                          location.pathname.indexOf("/indian-colleges-details") > -1 ||
                          location.pathname.indexOf("/edit-placement") > -1 ||
                          location.pathname.indexOf("/edit-overview-detail") > -1 ||
                          location.pathname.indexOf("/edit-rankings-detail") > -1 ||
                          location.pathname.indexOf("/edit-cutoff") > -1 ||
                          location.pathname.indexOf("/add-overview-detail") > -1 ||
                          location.pathname.indexOf("/edit-overview-detail") > -1 ||
                          location.pathname.indexOf("/add-faq") > -1 ||
                          location.pathname.indexOf("/admission") > -1 ||
                          location.pathname.indexOf("/testimonial") > -1 ||
                          location.pathname.indexOf("/add-testimonial") > -1 ||
                          location.pathname.indexOf("/add-webinar") > -1 ||
                          location.pathname.indexOf("/webinar-details") > -1
                          ? "active"
                          : ""
                          }`}
                        to="/assign-to-me-list"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Assigned to Me</span>
                      </Link>
                    </li>}
                  </ul>
                </li>}
              <li>
                <a
                  onClick={() => {
                    setOverCollegeVisible(!overCollegeVisible);
                    if (indCollegeVisible) {
                      setIndCollegeVisible(!indCollegeVisible);
                    }
                  }}
                  className={`tab-links show ${location.pathname === "/academics" ? "active" : ""
                    }`}
                >

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.934"
                    height="27.934"
                    viewBox="0 0 27.934 27.934"
                  >
                    <g
                      id="vuesax_bulk_award"
                      data-name="vuesax/bulk/award"
                      transform="translate(-428 -188)"
                      opacity="0.5"
                    >
                      <g id="award" transform="translate(428 188)">
                        <path
                          id="Vector"
                          d="M16.12,3.4l-.163-.093-1.618-.931L11,.445a3.878,3.878,0,0,0-3.608,0L4.05,2.377,2.433,3.32l-.21.116C.14,4.833,0,5.089,0,7.336V12.7c0,2.246.14,2.5,2.27,3.934l5.121,2.956a3.574,3.574,0,0,0,1.8.442,3.6,3.6,0,0,0,1.8-.442L16.167,16.6c2.083-1.4,2.223-1.653,2.223-3.9V7.336C18.39,5.089,18.25,4.833,16.12,3.4Z"
                          transform="translate(4.772 5.572)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M0,6.565l1.618-.943L4.807,3.783l.151-.093a3.878,3.878,0,0,1,3.608,0l.151.093,3.189,1.839,1.618.931V4.062C13.525,1.443,12.081,0,9.463,0H4.05C1.432,0,0,1.443,0,4.062Z"
                          transform="translate(7.205 2.328)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M6.856,3.343l-.722.885a.874.874,0,0,0-.186.559l.07,1.141a.748.748,0,0,1-1.106.8L3.853,6.311a1.071,1.071,0,0,0-.605,0L2.189,6.73a.748.748,0,0,1-1.106-.8l.07-1.141a.874.874,0,0,0-.186-.559L.245,3.343a.75.75,0,0,1,.419-1.3L1.77,1.76A.924.924,0,0,0,2.247,1.4L2.864.445a.752.752,0,0,1,1.373,0l.617.954a.924.924,0,0,0,.477.361l1.106.279A.75.75,0,0,1,6.856,3.343Z"
                          transform="translate(10.416 12.183)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H27.934V27.934H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span className="sidebar-menu-list-submenu">Overseas College</span>
                </a>
                <ul className={`sub-menu ${overCollegeVisible ? "is-open" : ""}`}>
                  <li className="">
                    <Link className="tab-links" to="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="vuesax_bulk_award"
                          data-name="vuesax/bulk/award"
                          transform="translate(-428 -188)"
                        >
                          <g id="award">
                            <path
                              id="Vector"
                              d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                              transform="translate(432.1 192.788)"
                              fill="#fff"
                              opacity="0.4"
                            />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                              transform="translate(434.19 190)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-3"
                              data-name="Vector"
                              d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                              transform="translate(436.949 198.467)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-4"
                              data-name="Vector"
                              d="M0,0H24V24H0Z"
                              transform="translate(428 188)"
                              fill="none"
                              opacity="0"
                            />
                          </g>
                        </g>
                      </svg>
                      <span>Assigned to Others</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link className="tab-links" to="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="vuesax_bulk_award"
                          data-name="vuesax/bulk/award"
                          transform="translate(-428 -188)"
                        >
                          <g id="award">
                            <path
                              id="Vector"
                              d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                              transform="translate(432.1 192.788)"
                              fill="#fff"
                              opacity="0.4"
                            />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                              transform="translate(434.19 190)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-3"
                              data-name="Vector"
                              d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                              transform="translate(436.949 198.467)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-4"
                              data-name="Vector"
                              d="M0,0H24V24H0Z"
                              transform="translate(428 188)"
                              fill="none"
                              opacity="0"
                            />
                          </g>
                        </g>
                      </svg>
                      <span>Assigned to Me</span>
                    </Link>
                  </li>
                </ul>
              </li>
              {(admin_type === "sub_admin" && permission["Dashboard"]) && <li>
                <Link
                  className={`tab-links ${location.pathname === "/" ? "active" : ""
                    }`}
                  to="/dashboard"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.799"
                    height="30.799"
                    viewBox="0 0 30.799 30.799"
                  >
                    <g
                      id="vuesax_bulk_category"
                      data-name="vuesax/bulk/category"
                      transform="translate(-108 -188)"
                    >
                      <g id="category" transform="translate(108 188)">
                        <path
                          id="Vector"
                          d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C11,1.476,9.522,0,6.725,0Z"
                          transform="translate(2.567 2.567)"
                          fill="grey"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                          transform="translate(17.248 2.567)"
                          fill="grey"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M6.712,0H4.273C1.476,0,0,1.476,0,4.273V6.712c0,2.8,1.476,4.273,4.273,4.273H6.712c2.8,0,4.273-1.476,4.273-4.273V4.273C10.985,1.476,9.509,0,6.712,0Z"
                          transform="translate(17.248 17.235)"
                          fill="grey"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M6.725,0H4.286C1.476,0,0,1.476,0,4.273V6.712C0,9.522,1.476,11,4.273,11H6.712c2.8,0,4.273-1.476,4.273-4.273V4.286C11,1.476,9.522,0,6.725,0Z"
                          transform="translate(2.567 17.235)"
                          fill="grey"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M0,0H30.8V30.8H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>}
              {/* {(admin_type === "sub_admin" && permission["Colleges"]) && <li>
                <a
                  onClick={() => {
                    setCollegeVisible(!collegeVisible);
                    if (testVisible) {
                      setTestVisible(!testVisible);
                    }
                  }}
                  className={`tab-links show ${location.pathname === "/academics" ? "active" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="29"
                    viewBox="0 0 29 29"
                  >
                    <g
                      id="vuesax_bulk_teacher"
                      data-name="vuesax/bulk/teacher"
                      transform="translate(-172 -252)"
                    >
                      <g id="teacher" transform="translate(172 252)">
                        <path
                          id="Vector"
                          d="M15.418,0V5.957a4.141,4.141,0,0,1-2.634,3.661L8.93,10.9a4.411,4.411,0,0,1-2.441,0L2.634,9.618A4.122,4.122,0,0,1,0,5.957L.012,0,5.353,3.48a4.725,4.725,0,0,0,4.761,0Z"
                          transform="translate(6.791 15.515)"
                          fill="#fff"
                          opacity="0.62"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M21.022,5.392,13.784.643a4.725,4.725,0,0,0-4.761,0L1.749,5.392a3.85,3.85,0,0,0,0,6.452L3.682,13.1l5.341,3.48a4.725,4.725,0,0,0,4.761,0l5.3-3.48,1.655-1.088v3.7a.906.906,0,0,0,1.813,0V9.766A3.837,3.837,0,0,0,21.022,5.392Z"
                          transform="translate(3.121 2.414)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,0H29V29H0Z"
                          fill="white"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span className="sidebar-menu-list-submenu">Colleges</span>
                </a>
                <ul className={`sub-menu ${collegeVisible ? "is-open" : ""}`}>
                  <li className="">
                    <Link
                      className={`tab-links ${location.pathname === "/colleges-list" ||
                        location.pathname.indexOf("/indian-colleges-details") > -1 ||
                        location.pathname.indexOf("/edit-placement") > -1 ||
                        location.pathname.indexOf("/edit-overview-detail") > -1 ||
                        location.pathname.indexOf("/edit-rankings-detail") > -1 ||
                        location.pathname.indexOf("/edit-cutoff") > -1 ||
                        location.pathname.indexOf("/add-overview-detail") > -1 ||
                        location.pathname.indexOf("/edit-overview-detail") > -1 ||
                        location.pathname.indexOf("/add-faq") > -1 ||
                        location.pathname.indexOf("/admission") > -1 ||
                        location.pathname.indexOf("/testimonial") > -1 ||
                        location.pathname.indexOf("/add-testimonial") > -1 ||
                        location.pathname.indexOf("/add-webinar") > -1 ||
                        location.pathname.indexOf("/webinar-details") > -1
                        ? "active"
                        : ""
                        }`}
                      to="/colleges-list"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="vuesax_bulk_award"
                          data-name="vuesax/bulk/award"
                          transform="translate(-428 -188)"
                        >
                          <g id="award">
                            <path
                              id="Vector"
                              d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                              transform="translate(432.1 192.788)"
                              fill="#fff"
                              opacity="0.4"
                            />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                              transform="translate(434.19 190)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-3"
                              data-name="Vector"
                              d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                              transform="translate(436.949 198.467)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-4"
                              data-name="Vector"
                              d="M0,0H24V24H0Z"
                              transform="translate(428 188)"
                              fill="none"
                              opacity="0"
                            />
                          </g>
                        </g>
                      </svg>
                      <span>Indian Colleges</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      className={`tab-links ${location.pathname === "/overseas-colleges" ? "active" : ""
                        }`}
                      to="/overseas-colleges"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="vuesax_bulk_award"
                          data-name="vuesax/bulk/award"
                          transform="translate(-428 -188)"
                        >
                          <g id="award">
                            <path
                              id="Vector"
                              d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                              transform="translate(432.1 192.788)"
                              fill="#fff"
                              opacity="0.4"
                            />
                            <path
                              id="Vector-2"
                              data-name="Vector"
                              d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                              transform="translate(434.19 190)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-3"
                              data-name="Vector"
                              d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                              transform="translate(436.949 198.467)"
                              fill="#fff"
                            />
                            <path
                              id="Vector-4"
                              data-name="Vector"
                              d="M0,0H24V24H0Z"
                              transform="translate(428 188)"
                              fill="none"
                              opacity="0"
                            />
                          </g>
                        </g>
                      </svg>
                      <span>Overseas Colleges</span>
                    </Link>
                  </li>
                </ul>
              </li>} */}
              {(admin_type === "sub_admin" && permission["Tests"]) && <li>
                <a
                  onClick={() => {
                    setTestVisible(!testVisible);
                    if (collegeVisible) {
                      setCollegeVisible(!collegeVisible);
                    }
                  }}
                  className={`tab-links show ${location.pathname === "/academics" ? "active" : ""
                    }`}
                  to="/academics"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="vuesax_bulk_chart"
                      data-name="vuesax/bulk/chart"
                      transform="translate(-556 -252)"
                      opacity="0.5"
                    >
                      <g id="chart">
                        <path
                          id="Vector"
                          d="M20.75,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h20a.755.755,0,0,1,.75.75A.755.755,0,0,1,20.75,1.5Z"
                          transform="translate(557.25 272.5)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M0,2V20H4.5V2c0-1.1-.45-2-1.8-2H1.8C.45,0,0,.9,0,2Z"
                          transform="translate(565.75 254)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M0,2V14H4V2C4,.9,3.6,0,2.4,0H1.6C.4,0,0,.9,0,2Z"
                          transform="translate(559 260)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,2V9H4V2C4,.9,3.6,0,2.4,0H1.6C.4,0,0,.9,0,2Z"
                          transform="translate(573 265)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M0,0H24V24H0Z"
                          transform="translate(556 252)"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span className="sidebar-menu-list-submenu"> Test
                    {/* Reports */}
                  </span>
                </a>
                <ul className={`sub-menu ${testVisible ? "is-open" : ""}`}>
                  {(admin_type === "sub_admin" && permission["Psychometric Tests"]) &&
                    <li className="">
                      <Link
                        className={`tab-links ${location.pathname === "/psychometric-test"
                          ? "active"
                          : ""
                          }`}
                        to="/psychometric-test"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Psychometric Test</span>
                      </Link>
                    </li>}
                  {(admin_type === "sub_admin" && permission["Overseas Tests"]) &&
                    <li className="">
                      <Link
                        className={`tab-links ${location.pathname === "/overseas-test" ? "active" : ""
                          }`}
                        to="/overseas-test"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <g
                            id="vuesax_bulk_award"
                            data-name="vuesax/bulk/award"
                            transform="translate(-428 -188)"
                          >
                            <g id="award">
                              <path
                                id="Vector"
                                d="M13.85,2.922l-.14-.08-1.39-.8L9.45.382a3.332,3.332,0,0,0-3.1,0L3.48,2.042l-1.39.81-.18.1C.12,4.152,0,4.373,0,6.3v4.61c0,1.93.12,2.15,1.95,3.38l4.4,2.54a3.071,3.071,0,0,0,1.55.38,3.1,3.1,0,0,0,1.55-.38l4.44-2.57c1.79-1.2,1.91-1.42,1.91-3.35V6.3C15.8,4.373,15.68,4.153,13.85,2.922Z"
                                transform="translate(432.1 192.788)"
                                fill="#fff"
                                opacity="0.4"
                              />
                              <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M0,5.64l1.39-.81L4.13,3.25l.13-.08a3.332,3.332,0,0,1,3.1,0l.13.08,2.74,1.58,1.39.8V3.49A3.149,3.149,0,0,0,8.13,0H3.48A3.141,3.141,0,0,0,0,3.49Z"
                                transform="translate(434.19 190)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M5.891,2.872l-.62.76a.751.751,0,0,0-.16.48l.06.98a.643.643,0,0,1-.95.69l-.91-.36a.92.92,0,0,0-.52,0l-.91.36a.643.643,0,0,1-.95-.69l.06-.98a.751.751,0,0,0-.16-.48l-.62-.76a.644.644,0,0,1,.36-1.12l.95-.24a.794.794,0,0,0,.41-.31l.53-.82a.646.646,0,0,1,1.18,0l.53.82a.794.794,0,0,0,.41.31l.95.24A.644.644,0,0,1,5.891,2.872Z"
                                transform="translate(436.949 198.467)"
                                fill="#fff"
                              />
                              <path
                                id="Vector-4"
                                data-name="Vector"
                                d="M0,0H24V24H0Z"
                                transform="translate(428 188)"
                                fill="none"
                                opacity="0"
                              />
                            </g>
                          </g>
                        </svg>
                        <span>Overseas Test</span>
                      </Link>
                    </li>}
                </ul>
              </li>}

              {(admin_type === "sub_admin" && permission["Careers"]) && <li>
                <Link
                  className={`tab-links ${location.pathname === "/vacational-course-list"
                    ? "active"
                    : ""
                    }`}
                  to="/career-list"
                >

                  <svg xmlns="http://www.w3.org/2000/svg" width="22.461" height="22.461" viewBox="0 0 22.461 22.461">
                    <g id="vuesax_bulk_task-square" data-name="vuesax/bulk/task-square" transform="translate(-494 -317.97)" opacity="0.5">
                      <g id="task-square" transform="translate(494 317.97)">
                        <path id="Vector" d="M15.936,0H6.525C2.437,0,0,2.437,0,6.525v9.411c0,4.088,2.437,6.525,6.525,6.525h9.411c4.088,0,6.525-2.437,6.525-6.525V6.525C22.461,2.437,20.024,0,15.936,0Z" transform="translate(0)" fill="#fff" opacity="0.4" />
                        <path id="Vector-2" data-name="Vector" d="M7.581.842a.841.841,0,0,1-.842.842H.842A.848.848,0,0,1,0,.842.848.848,0,0,1,.842,0h5.9A.841.841,0,0,1,7.581.842Z" transform="translate(10.737 6.873)" fill="#fff" />
                        <path id="Vector-3" data-name="Vector" d="M4.812,1.435,2.285,3.962a.833.833,0,0,1-.6.247.815.815,0,0,1-.6-.247L.253,3.119a.827.827,0,0,1,0-1.19.847.847,0,0,1,1.19,0l.247.247L3.622.244a.842.842,0,0,1,1.19,1.19Z" transform="translate(4.139 5.191)" fill="#fff" />
                        <path id="Vector-4" data-name="Vector" d="M7.581.842a.841.841,0,0,1-.842.842H.842A.848.848,0,0,1,0,.842.848.848,0,0,1,.842,0h5.9A.841.841,0,0,1,7.581.842Z" transform="translate(10.737 14.735)" fill="#fff" />
                        <path id="Vector-5" data-name="Vector" d="M4.812,1.435,2.285,3.962a.833.833,0,0,1-.6.247.815.815,0,0,1-.6-.247L.253,3.119a.827.827,0,0,1,0-1.19.847.847,0,0,1,1.19,0l.247.247L3.622.244a.842.842,0,0,1,1.19,1.19Z" transform="translate(4.139 13.053)" fill="#fff" />
                      </g>
                    </g>
                  </svg>


                  <span>Careers
                    {/* Team Leader */}
                  </span>
                </Link>
              </li>}

              {(admin_type === "sub_admin" && permission["Vocational Courses"]) && <li>
                <Link
                  className={`tab-links ${location.pathname === "/entranceexam" ||
                    location.pathname.indexOf("/entranceexam-detail") > -1
                    ? "active"
                    : ""
                    }`}
                  to="/vacational-course-list"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.129"
                    height="27.129"
                    viewBox="0 0 27.129 27.129"
                  >
                    <g
                      id="vuesax_bulk_note"
                      data-name="vuesax/bulk/note"
                      transform="translate(-428 -252)"
                      opacity="0.5"
                    >
                      <g id="note" transform="translate(428 252)">
                        <path
                          id="Vector"
                          d="M0,0H27.129V27.129H0Z"
                          fill="none"
                          opacity="0"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M18.086,4.521V15.542c0,3.391-2.023,4.521-4.521,4.521H4.521c-2.5,0-4.521-1.13-4.521-4.521V4.521C0,.848,2.023,0,4.521,0a2.529,2.529,0,0,0,.746,1.8,2.529,2.529,0,0,0,1.8.746h3.956A2.547,2.547,0,0,0,13.564,0C16.062,0,18.086.848,18.086,4.521Z"
                          transform="translate(4.521 4.804)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M9.043,2.543A2.547,2.547,0,0,1,6.5,5.087H2.543A2.543,2.543,0,0,1,2.543,0H6.5A2.547,2.547,0,0,1,9.043,2.543Z"
                          transform="translate(9.043 2.261)"
                          fill="#fff"
                        />
                        <g id="Group" transform="translate(8.195 13.847)">
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M5.369,1.7H.848A.854.854,0,0,1,0,.848.854.854,0,0,1,.848,0H5.369a.854.854,0,0,1,.848.848A.854.854,0,0,1,5.369,1.7Z"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Group-2"
                          data-name="Group"
                          transform="translate(8.195 18.368)"
                        >
                          <path
                            id="Vector-5"
                            data-name="Vector"
                            d="M9.891,1.7H.848A.854.854,0,0,1,0,.848.854.854,0,0,1,.848,0H9.891a.854.854,0,0,1,.848.848A.854.854,0,0,1,9.891,1.7Z"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Vector-6"
                          data-name="Vector"
                          d="M0,0H27.129V27.129H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Vocational Courses
                    {/* Assign Task */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Quiz"]) && <li>
                <Link
                  className={`tab-links ${location.pathname === "" ? "active" : ""
                    }`}
                  to=""
                >

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="vuesax_bulk_book" data-name="vuesax/bulk/book" transform="translate(-556 -188)" opacity="0.5">
                      <g id="book">
                        <path id="Vector" d="M10,2.638v16.03a1.009,1.009,0,0,1-.49-.11l-.04-.02A25.693,25.693,0,0,0,2.03,16.1l-.29-.04A2.055,2.055,0,0,1,0,14.078V2A1.967,1.967,0,0,1,2.16.008a18.757,18.757,0,0,1,7.06,2.34l.25.15A1.048,1.048,0,0,0,10,2.638Z" transform="translate(558 190.662)" fill="#fff" opacity="0.4" />
                        <path id="Vector-2" data-name="Vector" d="M10,2v12.07a2.055,2.055,0,0,1-1.74,1.98l-.33.04a25.461,25.461,0,0,0-7.46,2.46.885.885,0,0,1-.47.11V2.628a1.048,1.048,0,0,0,.53-.14l.17-.11A18.851,18.851,0,0,1,7.77.008h.06A1.97,1.97,0,0,1,10,2Z" transform="translate(568 190.672)" fill="#fff" />
                        <path id="Vector-3" data-name="Vector" d="M3,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0H3a.755.755,0,0,1,.75.75A.755.755,0,0,1,3,1.5Z" transform="translate(560.75 195.74)" fill="#fff" />
                        <path id="Vector-4" data-name="Vector" d="M3.75,1.5h-3A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0h3A.755.755,0,0,1,4.5.75.755.755,0,0,1,3.75,1.5Z" transform="translate(560.75 198.74)" fill="#fff" />
                        <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(556 188)" fill="none" opacity="0" />
                      </g>
                    </g>
                  </svg>


                  <span>Quiz
                    {/* Indian Colleges Content */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Scholarships"]) && <li>
                <Link
                  className={`tab-links ${location.pathname === "/subadmin" ? "active" : ""
                    }`}
                  to="/subadmin-detail"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.934"
                    height="27.934"
                    viewBox="0 0 27.934 27.934"
                  >
                    <g
                      id="vuesax_bulk_award"
                      data-name="vuesax/bulk/award"
                      transform="translate(-428 -188)"
                      opacity="0.5"
                    >
                      <g id="award" transform="translate(428 188)">
                        <path
                          id="Vector"
                          d="M16.12,3.4l-.163-.093-1.618-.931L11,.445a3.878,3.878,0,0,0-3.608,0L4.05,2.377,2.433,3.32l-.21.116C.14,4.833,0,5.089,0,7.336V12.7c0,2.246.14,2.5,2.27,3.934l5.121,2.956a3.574,3.574,0,0,0,1.8.442,3.6,3.6,0,0,0,1.8-.442L16.167,16.6c2.083-1.4,2.223-1.653,2.223-3.9V7.336C18.39,5.089,18.25,4.833,16.12,3.4Z"
                          transform="translate(4.772 5.572)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M0,6.565l1.618-.943L4.807,3.783l.151-.093a3.878,3.878,0,0,1,3.608,0l.151.093,3.189,1.839,1.618.931V4.062C13.525,1.443,12.081,0,9.463,0H4.05C1.432,0,0,1.443,0,4.062Z"
                          transform="translate(7.205 2.328)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M6.856,3.343l-.722.885a.874.874,0,0,0-.186.559l.07,1.141a.748.748,0,0,1-1.106.8L3.853,6.311a1.071,1.071,0,0,0-.605,0L2.189,6.73a.748.748,0,0,1-1.106-.8l.07-1.141a.874.874,0,0,0-.186-.559L.245,3.343a.75.75,0,0,1,.419-1.3L1.77,1.76A.924.924,0,0,0,2.247,1.4L2.864.445a.752.752,0,0,1,1.373,0l.617.954a.924.924,0,0,0,.477.361l1.106.279A.75.75,0,0,1,6.856,3.343Z"
                          transform="translate(10.416 12.183)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H27.934V27.934H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Scholarships
                    {/* Overseas College Content */}

                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Entrance Exams"]) && <li>
                <Link
                  className={`tab-links ${location.pathname === "/entranceexam" ? "active" : ""
                    }`}
                  to="/entranceexam"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.042,0H5.75C2.156,0,0,2.146,0,5.75v8.281c0,3.615,2.156,5.76,5.75,5.76h8.281c3.6,0,5.75-2.146,5.75-5.75V5.75C19.792,2.146,17.635,0,14.042,0Z"
                          transform="translate(2.083 3.125)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M6.893,1.422c-1.865-1.875-3.687-1.917-5.6,0L.112,2.589a.38.38,0,0,0-.094.385A7.81,7.81,0,0,0,5.341,8.3a.427.427,0,0,0,.115.01.4.4,0,0,0,.281-.115L6.893,7.026A4,4,0,0,0,8.31,4.245,4.085,4.085,0,0,0,6.893,1.422Z"
                          transform="translate(15.003 1.682)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.639,5.292a8.326,8.326,0,0,1-.8-.427c-.208-.125-.417-.26-.615-.406a5.437,5.437,0,0,1-.542-.417A1.071,1.071,0,0,1,9.514,3.9a7.5,7.5,0,0,1-.99-1,.8.8,0,0,1-.135-.177,3.89,3.89,0,0,1-.4-.531,4.985,4.985,0,0,1-.375-.573A8.214,8.214,0,0,1,7.2.823C7.066.531,6.962.26,6.868,0l-5.6,5.6A3.2,3.2,0,0,0,.472,7.167l-.448,3.1a1.95,1.95,0,0,0,.49,1.677,1.847,1.847,0,0,0,1.333.521c.115,0,.229-.01.344-.021l3.094-.438a3.112,3.112,0,0,0,1.563-.792l5.6-5.6C12.191,5.531,11.931,5.417,11.639,5.292Z"
                          transform="translate(6.965 5.562)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25V25H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Entrance Exams
                    {/* Careers */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Clubs"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.6"
                    height="31.6"
                    viewBox="0 0 31.6 31.6"
                  >
                    <g
                      id="vuesax_bulk_note"
                      data-name="vuesax/bulk/note"
                      transform="translate(-428 -252)"
                    >
                      <g id="note" transform="translate(428 252)">
                        <path
                          id="Vector"
                          d="M0,0H31.6V31.6H0Z"
                          fill="none"
                          opacity="0"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M21.066,5.267V18.1c0,3.95-2.357,5.267-5.267,5.267H5.267C2.357,23.371,0,22.054,0,18.1V5.267C0,.987,2.357,0,5.267,0a2.945,2.945,0,0,0,.869,2.093,2.945,2.945,0,0,0,2.094.869h4.608A2.967,2.967,0,0,0,15.8,0C18.71,0,21.066.987,21.066,5.267Z"
                          transform="translate(5.267 5.596)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M10.533,2.962A2.967,2.967,0,0,1,7.571,5.925H2.962A2.962,2.962,0,0,1,2.962,0H7.571a2.967,2.967,0,0,1,2.962,2.962Z"
                          transform="translate(10.533 2.633)"
                          fill="#fff"
                        />
                        <g id="Group" transform="translate(9.546 16.129)">
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M6.254,1.975H.987A1,1,0,0,1,0,.987,1,1,0,0,1,.987,0H6.254a1,1,0,0,1,.987.987A1,1,0,0,1,6.254,1.975Z"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Group-2"
                          data-name="Group"
                          transform="translate(9.546 21.396)"
                        >
                          <path
                            id="Vector-5"
                            data-name="Vector"
                            d="M11.521,1.975H.987A1,1,0,0,1,0,.987,1,1,0,0,1,.987,0H11.521a1,1,0,0,1,.987.987A1,1,0,0,1,11.521,1.975Z"
                            fill="#fff"
                          />
                        </g>
                        <path
                          id="Vector-6"
                          data-name="Vector"
                          d="M0,0H31.6V31.6H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Clubs
                    {/* Scholarship */}

                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Static Content"]) && <li>
                <Link className="tab-links" to="/">

                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="vuesax_bulk_chart-success" data-name="vuesax/bulk/chart-success" transform="translate(-108 -188)" opacity="0.5">
                      <g id="chart-success">
                        <g id="chart-success-2" data-name="chart-success">
                          <path id="Vector" d="M4.97,0H2.03A1.866,1.866,0,0,0,0,2.03V4.97A1.866,1.866,0,0,0,2.03,7H4.97A1.866,1.866,0,0,0,7,4.97V2.03A1.866,1.866,0,0,0,4.97,0Zm.66,2.45L3.26,5.22a.49.49,0,0,1-.37.18H2.87a.514.514,0,0,1-.37-.15L1.41,4.17a.519.519,0,0,1,0-.73.51.51,0,0,1,.73,0l.7.69,2-2.34a.521.521,0,0,1,.73-.06A.509.509,0,0,1,5.63,2.45Z" transform="translate(124 204)" fill="#fff" />
                          <path id="Vector-2" data-name="Vector" d="M14,18.97V16.03A1.866,1.866,0,0,1,16.03,14h2.94a2.336,2.336,0,0,1,1.03.22V5.81C20,2.17,17.83,0,14.19,0H5.81C2.17,0,0,2.17,0,5.81v8.37C0,17.83,2.17,20,5.81,20h8.41A2.336,2.336,0,0,1,14,18.97Z" transform="translate(110 190)" fill="#fff" opacity="0.4" />
                          <path id="Vector-3" data-name="Vector" d="M.75,4.5A.755.755,0,0,1,0,3.75v-3A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75v3A.755.755,0,0,1,.75,4.5Z" transform="translate(114.25 197.75)" fill="#fff" />
                          <path id="Vector-4" data-name="Vector" d="M.75,4.5A.755.755,0,0,1,0,3.75v-3A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75v3A.755.755,0,0,1,.75,4.5Z" transform="translate(119.25 197.75)" fill="#fff" />
                          <path id="Vector-5" data-name="Vector" d="M.75,4.5A.755.755,0,0,1,0,3.75v-3A.755.755,0,0,1,.75,0,.755.755,0,0,1,1.5.75v3A.755.755,0,0,1,.75,4.5Z" transform="translate(124.25 197.75)" fill="#fff" />
                        </g>
                        <path id="Vector-6" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(108 188)" fill="none" opacity="0" />
                      </g>
                    </g>
                  </svg>


                  <span>Static Content
                    {/* Entrance Exams */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["News"]) && <li>
                <Link className="tab-links" to="/scholarship-list">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.461"
                    height="22.461"
                    viewBox="0 0 22.461 22.461"
                  >
                    <g
                      id="vuesax_bulk_task-square"
                      data-name="vuesax/bulk/task-square"
                      transform="translate(-494 -317.97)"
                      opacity="0.5"
                    >
                      <g id="task-square" transform="translate(494 317.97)">
                        <path
                          id="Vector"
                          d="M15.936,0H6.525C2.437,0,0,2.437,0,6.525v9.411c0,4.088,2.437,6.525,6.525,6.525h9.411c4.088,0,6.525-2.437,6.525-6.525V6.525C22.461,2.437,20.024,0,15.936,0Z"
                          transform="translate(0)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.581.842a.841.841,0,0,1-.842.842H.842A.848.848,0,0,1,0,.842.848.848,0,0,1,.842,0h5.9A.841.841,0,0,1,7.581.842Z"
                          transform="translate(10.737 6.873)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M4.812,1.435,2.285,3.962a.833.833,0,0,1-.6.247.815.815,0,0,1-.6-.247L.253,3.119a.827.827,0,0,1,0-1.19.847.847,0,0,1,1.19,0l.247.247L3.622.244a.842.842,0,0,1,1.19,1.19Z"
                          transform="translate(4.139 5.191)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M7.581.842a.841.841,0,0,1-.842.842H.842A.848.848,0,0,1,0,.842.848.848,0,0,1,.842,0h5.9A.841.841,0,0,1,7.581.842Z"
                          transform="translate(10.737 14.735)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M4.812,1.435,2.285,3.962a.833.833,0,0,1-.6.247.815.815,0,0,1-.6-.247L.253,3.119a.827.827,0,0,1,0-1.19.847.847,0,0,1,1.19,0l.247.247L3.622.244a.842.842,0,0,1,1.19,1.19Z"
                          transform="translate(4.139 13.053)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>News
                    {/* Courses */}

                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Sub Admin"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.199"
                    height="31.199"
                    viewBox="0 0 31.199 31.199"
                  >
                    <g
                      id="vuesax_bulk_brifecase-tick"
                      data-name="vuesax/bulk/brifecase-tick"
                      transform="translate(-236 -188)"
                      opacity="0.8"
                    >
                      <g id="brifecase-tick" transform="translate(236 188)">
                        <path
                          id="Vector"
                          d="M25.609,0,24.8,8.814c-.273,2.6-1.339,5.252-7.059,5.252H7.839c-5.72,0-6.786-2.652-7.046-5.239L0,.364A.179.179,0,0,0,.052.4c.442.286.871.572,1.339.832.182.117.377.221.572.325A21.4,21.4,0,0,0,6.63,3.445,16.972,16.972,0,0,0,8.619,3.9,4.221,4.221,0,0,0,17,3.822V3.809c.663-.13,1.326-.3,1.976-.494a22.589,22.589,0,0,0,4.667-2.041,1.284,1.284,0,0,0,.195-.117A16.679,16.679,0,0,0,25.557.065.13.13,0,0,0,25.609,0Z"
                          transform="translate(2.795 14.833)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M24.813,6.773c-1.1-1.222-2.938-1.833-5.629-1.833h-.312V4.888c0-2.184,0-4.888-4.888-4.888H12.008C7.12,0,7.12,2.7,7.12,4.888V4.94H6.808c-2.691,0-4.537.611-5.629,1.833A5.922,5.922,0,0,0,.061,11.44l.013.091L.191,12.9a.284.284,0,0,0,.065.039c.442.286.871.572,1.339.832.182.117.377.221.572.325a21.564,21.564,0,0,0,4.654,1.885,6.175,6.175,0,0,0,12.35-.078v-.052a21.733,21.733,0,0,0,4.68-2.041c.078-.039.13-.078.195-.117A16.679,16.679,0,0,0,25.762,12.6a.284.284,0,0,0,.052-.065l.052-.468.065-.611c.013-.078.013-.143.026-.234A5.745,5.745,0,0,0,24.813,6.773ZM8.979,4.888c0-2.21,0-3.042,3.029-3.042h1.976c3.029,0,3.029.832,3.029,3.042V4.94H8.979ZM13,20.123A4.226,4.226,0,0,1,8.81,16.432a3.546,3.546,0,0,1-.039-.533,4.225,4.225,0,0,1,8.45,0c0,.156-.013.3-.026.442v.013A4.236,4.236,0,0,1,13,20.123Z"
                          transform="translate(2.604 2.301)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M2.259,4.5a.964.964,0,0,1-.689-.286L.283,2.935A.974.974,0,0,1,1.661,1.557l.624.624L4.378.257A.975.975,0,0,1,5.7,1.687L2.935,4.248A1.139,1.139,0,0,1,2.259,4.5Z"
                          transform="translate(12.6 16.343)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H31.2V31.2H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Sub Admin
                    {/* Q&A */}
                  </span>
                </Link>
              </li>}

              {(admin_type === "sub_admin" && permission["Master Data"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.627"
                    height="27.627"
                    viewBox="0 0 27.627 27.627"
                  >
                    <g
                      id="vuesax_bulk_messages"
                      data-name="vuesax/bulk/messages"
                      transform="translate(-364 -252)"
                      opacity="0.5"
                    >
                      <g id="messages" transform="translate(364 252)">
                        <path
                          id="Vector"
                          d="M0,0H27.627V27.627H0Z"
                          fill="none"
                          opacity="0"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M17.267,8.058c0,4.455-3.868,8.058-8.633,8.058L7.563,17.405l-.633.76A1.157,1.157,0,0,1,5,17.9L3.453,14.5A7.89,7.89,0,0,1,0,8.058C0,3.6,3.868,0,8.633,0a8.65,8.65,0,0,1,7.828,4.685A7.434,7.434,0,0,1,17.267,8.058Z"
                          transform="translate(2.302 2.302)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M15.46,7.816a7.89,7.89,0,0,1-3.453,6.446l-1.543,3.4a1.152,1.152,0,0,1-1.934.265l-1.7-2.049A8.75,8.75,0,0,1,0,12.72l1.071-1.289c4.766,0,8.633-3.6,8.633-8.058A7.434,7.434,0,0,0,8.9,0,8.19,8.19,0,0,1,15.46,7.816Z"
                          transform="translate(9.865 6.987)"
                          fill="#fff"
                        />
                        <g id="Group" transform="translate(7.195 9.497)">
                          <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M6.619,1.727H.863A.87.87,0,0,1,0,.863.87.87,0,0,1,.863,0H6.619a.87.87,0,0,1,.863.863A.87.87,0,0,1,6.619,1.727Z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>

                  <span>Master Data
                    {/* News & Updates */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["News Letter"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g
                      id="vuesax_bulk_clipboard-tick"
                      data-name="vuesax/bulk/clipboard-tick"
                      transform="translate(-172 -252)"
                      opacity="0.5"
                    >
                      <g id="clipboard-tick" transform="translate(172 252)">
                        <path
                          id="Vector"
                          d="M16.188,0H5.588A5.593,5.593,0,0,0,0,5.587V17.35a5.6,5.6,0,0,0,5.588,5.587H16.175a5.593,5.593,0,0,0,5.588-5.587V5.587A5.575,5.575,0,0,0,16.188,0Z"
                          transform="translate(4.112 4.562)"
                          fill="#f8f8f8"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M8.238,0H2.362A2.358,2.358,0,0,0,0,2.35V3.525a2.347,2.347,0,0,0,2.35,2.35H8.238a2.347,2.347,0,0,0,2.35-2.35V2.35A2.339,2.339,0,0,0,8.238,0Z"
                          transform="translate(9.7 2.5)"
                          fill="#f8f8f8"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M2.809,6.872A.927.927,0,0,1,2.147,6.6L.272,4.722A.937.937,0,0,1,1.6,3.4L2.809,4.609,7.147.272A.937.937,0,0,1,8.472,1.6l-5,5A.907.907,0,0,1,2.809,6.872Z"
                          transform="translate(10.703 14.316)"
                          fill="#f8f8f8"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H30V30H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>News Letter
                    {/* Articles */}
                  </span>
                </Link>
              </li>}

              {(admin_type === "sub_admin" && permission["Blogs"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                  >
                    <g
                      id="vuesax_bulk_task"
                      data-name="vuesax/bulk/task"
                      transform="translate(-428 -316)"
                      opacity="0.5"
                    >
                      <g id="task" transform="translate(428 316)">
                        <path
                          id="Vector"
                          d="M10.714,1.777H.888A.888.888,0,1,1,.888,0h9.826a.888.888,0,1,1,0,1.777Z"
                          transform="translate(10.952 19.088)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M10.714,1.777H.888A.888.888,0,1,1,.888,0h9.826a.888.888,0,0,1,0,1.777Z"
                          transform="translate(10.952 12.274)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M10.714,1.777H.888A.888.888,0,1,1,.888,0h9.826a.888.888,0,1,1,0,1.777Z"
                          transform="translate(10.952 5.449)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M1.874,4.723a.894.894,0,0,1-.628-.26L.26,3.478A.889.889,0,1,1,1.517,2.221l.357.358L4.193.26A.889.889,0,1,1,5.449,1.517L2.5,4.463A.914.914,0,0,1,1.874,4.723Z"
                          transform="translate(3.445 3.976)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-5"
                          data-name="Vector"
                          d="M1.874,4.723a.886.886,0,0,1-.628-.26L.26,3.478A.889.889,0,0,1,1.517,2.221l.357.358L4.193.26A.889.889,0,0,1,5.449,1.517L2.5,4.463A.886.886,0,0,1,1.874,4.723Z"
                          transform="translate(3.445 10.801)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-6"
                          data-name="Vector"
                          d="M1.874,4.723a.886.886,0,0,1-.628-.26L.26,3.478A.889.889,0,0,1,1.517,2.221l.357.357L4.193.26A.889.889,0,0,1,5.449,1.517L2.5,4.463A.886.886,0,0,1,1.874,4.723Z"
                          transform="translate(3.445 17.301)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-7"
                          data-name="Vector"
                          d="M0,0H26V26H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Blogs
                    {/* News Letter */}

                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Merchandise"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Merchandise
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["CAP"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>
                    CAP
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Accomodation"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Accomodation
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Help & Support"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Help & Support
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Test Preperation"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Test Preperation
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Resources"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Resources
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
              {(admin_type === "sub_admin" && permission["Study Abroad"]) && <li>
                <Link className="tab-links" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.719"
                    height="25.719"
                    viewBox="0 0 25.719 25.719"
                  >
                    <g
                      id="vuesax_bulk_edit"
                      data-name="vuesax/bulk/edit"
                      transform="translate(-620 -252)"
                      opacity="0.5"
                    >
                      <g id="edit" transform="translate(620 252)">
                        <path
                          id="Vector"
                          d="M14.446,0H5.915C2.218,0,0,2.208,0,5.915v8.519c0,3.719,2.218,5.926,5.915,5.926h8.519c3.708,0,5.915-2.208,5.915-5.915V5.915C20.361,2.208,18.143,0,14.446,0Z"
                          transform="translate(2.143 3.215)"
                          fill="#fff"
                          opacity="0.4"
                        />
                        <path
                          id="Vector-2"
                          data-name="Vector"
                          d="M7.091,1.463C5.173-.466,3.3-.509,1.326,1.463L.115,2.663a.391.391,0,0,0-.1.4A8.035,8.035,0,0,0,5.494,8.536a.44.44,0,0,0,.118.011A.414.414,0,0,0,5.9,8.428l1.19-1.2A4.118,4.118,0,0,0,8.549,4.367,4.2,4.2,0,0,0,7.091,1.463Z"
                          transform="translate(15.434 1.731)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-3"
                          data-name="Vector"
                          d="M11.974,5.444A8.565,8.565,0,0,1,11.149,5c-.214-.129-.429-.268-.632-.418a5.593,5.593,0,0,1-.557-.429,1.1,1.1,0,0,1-.171-.15A7.719,7.719,0,0,1,8.77,2.979.821.821,0,0,1,8.63,2.8a4,4,0,0,1-.407-.547,5.129,5.129,0,0,1-.386-.589A8.449,8.449,0,0,1,7.409.847C7.269.547,7.162.268,7.066,0L1.3,5.765A3.289,3.289,0,0,0,.486,7.373L.025,10.566a2.006,2.006,0,0,0,.5,1.725,1.9,1.9,0,0,0,1.372.536c.118,0,.236-.011.354-.021l3.183-.45a3.2,3.2,0,0,0,1.607-.814L12.81,5.776C12.542,5.69,12.274,5.572,11.974,5.444Z"
                          transform="translate(7.165 5.722)"
                          fill="#fff"
                        />
                        <path
                          id="Vector-4"
                          data-name="Vector"
                          d="M0,0H25.719V25.719H0Z"
                          fill="none"
                          opacity="0"
                        />
                      </g>
                    </g>
                  </svg>

                  <span>Study Abroad
                    {/* Blogs */}
                  </span>
                </Link>
              </li>}
            </ul>
          </Scrollbars>
        </div>
      </aside>
    </div>
  );
};

export default SubadminSidebar;
