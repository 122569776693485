import toast from "react-hot-toast";
import { uploadImage } from "../services/college";
import { confirmAlert } from "react-confirm-alert";


export const getMediaURL = async(payload)=>{
    let formData = new FormData()
    formData.append("media",payload)
    if(payload?.type?.indexOf("image")>-1){
        formData.append("media_type","image")
    }
    else if(payload?.type?.indexOf("video")>-1){
        formData.append("media_type","video")
    }
    else if(payload?.type?.indexOf("pdf")>-1){
        formData.append("media_type","pdf")
    }
    else if(payload?.type?.indexOf("doc")>-1){
      formData.append("media_type","doc")
    }
    else if(payload?.type?.indexOf("json")>-1){
      formData.append("media_type","json")
    }
    else{
        return;
    }
    let response = await uploadImage(formData);
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.APICODERESULT || "Success");
  }
    return response?.data?.result.media_url || "";
}

export const generateConfirm = (title="",message="",id="",yesFunc=()=>{})=>{
  confirmAlert({
      title,
      message,
      id,
      buttons: [
        {
          label: "Yes",
          onClick: yesFunc
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
}
