import React, { useState } from "react";
import { useOutlet } from "react-router-dom";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";
import Footer from "../components/common/Footer";
import SubadminSidebar from "../components/common/SubadminSidebar";
import SubadminHeader from "../components/common/SubadminHeader";
import SubadminFooter from "../components/common/SubadminFooter";

const HeaderSidebar = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const outlet = useOutlet()
  const ToggleClass = () => {
    setMenuOpen(!isMenuOpen);
  };
  const type = localStorage.getItem("admin_type") || "";
  return (
    <main
      className={`main-wrapper ${isMenuOpen ? "full-screen-layout" : null}`}
    >
      {type === "admin" ?
        <Sidebar /> : <SubadminSidebar />
      }

      {type === "admin" ?
       (<div className="right-panel default-bg">
       <Header onClickMenuBtn={ToggleClass} />
       <div className="right-panel-wraper">{outlet}
       </div>
     </div>) : (<div className="right-panel right-panel-subadmin default-bg">
          <SubadminHeader onClickMenuBtn={ToggleClass} />
          <div className="right-panel-wraper">{outlet}
          </div>
        </div>)
      }
      {/* {type === "admin" ?
        <Sidebar /> : <SubadminSidebar />}
      <div className="right-panel default-bg">
      {type === "admin" ?
      <Header onClickMenuBtn={ToggleClass} /> : <SubadminHeader />
      }
      
        <div className="right-panel-wraper">{outlet}</div>
       
      
      </div> : <div>gfff</div>} */}
      {/* {type === "admin" ?
      {/* <Footer /> : <SubadminFooter/> */}

      {/* {type === "admin" &&
        <div className="right-panel default-bg">
          <Header onClickMenuBtn={ToggleClass} />
          <div className="right-panel-wraper">{outlet}
          </div>
        </div>
      }
      {type === "subadmin" &&
        <div className="right-panel right-panel-subadmin default-bg">
          <SubadminHeader onClickMenuBtn={ToggleClass} />
          <div className="right-panel-wraper">{outlet}
          </div>
        </div>
      } */}
      {type === "admin" ?
        <Footer /> : <SubadminFooter />
      }
    </main>
  );
};

export default HeaderSidebar;
