import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEllipsis, } from "@fortawesome/free-solid-svg-icons";
import LottieAnimation from "../../HOC/LottieAnimation";
import { useNavigate } from "react-router-dom";

const formatDate = (date) => {
  const today = moment();
  const providedDate = moment.utc(date);
  const daysAgo = today.diff(providedDate, "days");
  if (daysAgo === 0) {
    return "Today";
  } else if (daysAgo === 1) {
    return "Yesterday";
  } else {
    return `${Math.abs(daysAgo)} Days ago`;
  }
};


const ActiveTaskCard = ({ activeData, cmTab, role }) => {
  let navigate = useNavigate();
  console.log("cmTab for value", cmTab === "edit_requests" ? true : false)
  return (
    <div className="active-tasks">
      {role === "content_manager" && cmTab === "edit_requests" ? <div className="row">
        {activeData.length === 0 && <h1 className="no-data"><LottieAnimation /></h1>}
        {activeData?.map((item, index) => (
          <div className="col-md-3 mb-3" key={index}>
            <div className="card card-stamp-main p-2">
              <div className="d-flex justify-content-between">
                <div className="card-title card-style">
                  <span className="text-white">{item?.request_id}</span>
                </div>
              </div>
              <h3 className="card-subtitle mb-1 text-muted">
                {item?.task_title ? item?.task_title : ""}
              </h3>
              <div className="deadline mt-1">
                <p className="card-text table_moreText_hide html_string" dangerouslySetInnerHTML={{ __html: item?.request_title?.replaceAll("%22", `"`)?.replaceAll("%27", `'`) || "NA" }}></p>
              </div>
              <div className="mt-1">
                {/* <p className="bold mb-1">status</p> */}
                <div className="d-flex justify-content-between status">
                  <h6>{item?.request_status === "rejected" ? <sapn><FontAwesomeIcon className="circle-dot me-1 text-danger" icon={faCircle} /> Rejectd</sapn> : item?.request_status === "approved" ? 
                  <sapn><FontAwesomeIcon className="circle-dot me-1" icon={faCircle} /> Approved</sapn> : item?.request_status === "pending" ? <sapn><FontAwesomeIcon className="circle-dot me-1 text-warning" icon={faCircle} /> Pending</sapn> : "" || "NA"}</h6>
                </div>
              </div>
              <div className="assign">
                <div>
                  <h6>College Name</h6>
                  <h6 className="m-0 mb-2 table_moreText_hide" title={item?.clg_name || "NA"}>{item?.clg_name || "NA"}</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> : <div className="row">
        {activeData.length === 0 && <h1 className="no-data"><LottieAnimation /></h1>}
        {activeData?.map((item, index) => (
          <div className="col-md-3 mb-3" key={index}>
            <div className="card card-stamp-main p-2" onClick={()=> {role === "team_lead" || role === "intern" ? navigate('/assign-to-me-list') : navigate('')}}>
              <div className="d-flex justify-content-between">
                <div className="card-title card-style">
                  <span className="text-white">{formatDate(item?.deadline_date) || "N/A"}</span>
                </div>
                <div>
                  <FontAwesomeIcon icon={faEllipsis} />
                </div>
              </div>
              <h3 className="card-subtitle mb-1 text-muted">
                {item?.task_title || "NA"}
              </h3>
              <p className="card-text table_moreText_hide" dangerouslySetInnerHTML={{ __html: item?.description?.replaceAll("%22", `"`)?.replaceAll("%27", `'`) || "NA" }}></p>
              <div className="d-flex deadline mt-1">
                <p className="bold">Deadline :</p>
                <p className="dead-green">{moment(item?.deadline_date).format("DD/MM/YYYY") || "N/A"}</p>
              </div>
              <div className="d-flex deadline mt-1">
                <p className="bold">Module :</p>
                <p className="dead-green">{item?.module || "N/A"}</p>
              </div>
              <div className="mt-1">
                {/* <p className="bold mb-1">status</p> */}
                <p className="dead-green table_moreText_hide">{item?.clg_name || "N/A"}</p>
                <div className="d-flex justify-content-between status">
                  <h6>{item?.task_status === "in_progress" ?<span><FontAwesomeIcon className="circle-dot me-1" icon={faCircle} />  In Progress</span>: item?.task_status === "pending" ? <span><FontAwesomeIcon className="circle-dot me-1 text-warning" icon={faCircle} /> Pending</span> : item?.task_status === "rejected" ? <span><FontAwesomeIcon className="circle-dot me-1 text-danger" icon={faCircle} /> Rejected</span> : "" || "NA"}</h6>
                  {/* <h6><FontAwesomeIcon className="circle-dot me-1" icon={faCircle } />On Going</h6>
                    <h6><FontAwesomeIcon className="circle-dot me-1" icon={faCircle } />Completed</h6> */}
                </div>
              </div>
              <div className="assign">
                <div className="mt-3">
                  <img src="assets/images/image2.png" />
                </div>
                <div>
                  {role === "team_lead" || role === "intern" ? <h6>Assigned By :</h6> : <h6>Assigned To :</h6>}
                  <h6 className="m-0 mb-2">{item?.assigned_by || item?.assigned_to || "NA"}</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>}
    </div>
  );
};

export default ActiveTaskCard;
