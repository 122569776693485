import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../constants/no-data.json';

const LottieAnimation = () => {
  const lottieOptions = {
    animationData: animationData,
    autoplay: true,
    loop: true,
    width: '10px',
    height: '10px',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className='dash-loader-wrap'>
      <div className='d-flex justify-content-center align-items-center'>
        <Lottie className='dashboard-lottie' {...lottieOptions} />
      </div>
    </div>
  );
};

export default LottieAnimation;
