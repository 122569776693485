import { useContext } from "react";
import MyContext from "../context/permissions";

function usePermissions(){
    
    const {permissions} = useContext(MyContext);
    const admin_type=localStorage.getItem("admin_type");
    let availablePermission=[];
    if(admin_type==="sub_admin"){
        availablePermission = permissions?.filter(item=>item.checked)?.flatMap(item=>item.permission_name);
    }
    else{
        availablePermission = []
        // ########useful line for subadmin #####
        // availablePermission = permissions?.flatMap(item=>item.permission_name)  
    }
    let perms={};
    availablePermission.forEach((item)=>{
        perms[item]=true;
    })
    return perms;
}
export default usePermissions;