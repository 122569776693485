export const permissionRoutes={
    Dashboard:"/dashboard",
    Colleges:"/subadmin/indian-college",
    Master:"/master",
    Exams:"/entranceexam",
    Scholarships:"/scholarship-list",
    CMS:"/cms",
    Push_Notifications:"/notification",
    Clubs:"/clubs",
    Customer:"/customer",
    Blogs:"/blogs",
    Quiz:"/quiz",
    NewsLetters:"/newsletter"
}