import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Budges from "./Badges";
import RatingTask from "./RatingTask";
import Suggestions from "./Suggestions";
import Summary from "./Summary";
import chart1 from "../../assets/images/flow-chart1.PNG";
import chart2 from "../../assets/images/flow-chart2.PNG";

const AssignreportChart = () => {
    let location = useLocation();
    const [tab, setTab] = useState(location?.state?.tab ? location?.state?.tab : 0)

    return (
        <div className="wrapper-assignpage">
            <div className="dashboard">
                <div className="subheader wrapassign-subheader">
                    <div className="subheader-title">
                        <div className="userprofiledata">
                            <img src="/assets/images/user-profile.svg" />
                            <div className="userprofile-datatext">
                                <button className="btn-tl-id">TL ID: 9783</button>
                                <h6>Shivam Kaushik</h6>
                                <p>Joining Date: 22 Aug 2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="subheader-right-top ">
                        <button className="add-btn btn-cancel">Give Coins and Ratings</button>
                    </div>
                </div>
                <div className="lightheading">
                    <h1><span>Task Listing</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        Shivam Kaushik
                    </h1>
                </div>
                <div className="assignrepport-subheader">
                    <div className="selected-data">
                        <div className="select-content">
                            <input type="radio" name="radio" checked />
                            <label>Total Allocated Task Vs Achieved Task</label>
                        </div>
                        <div className="select-content">
                            <input type="radio" name="radio" />
                            <label>Productivity and Efficiency</label>
                        </div>
                    </div>
                    <div className="assigntime-bx">
                        <input type="date" name="date" />
                        <div className="assigntime">
                            <button>Day</button>
                            <button>Week</button>
                            <button>Month</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div>
                            flow chart 1
                            <img src={[chart1]} />
                        </div>
                    </div>
                    <div className="col-md-5">
                    <div>
                        flow chart 2
                            <img src={[chart2]} />
                        </div>
                    </div>
                </div>
                <div className="nav-tab assignrepport-subheader">
                    <div className="d-flex">
                    <div className={`tabbing-button ${tab === 0 ? "active" : ""}`}
                        onClick={() => setTab(0)} >
                        <button className="tabinprogess">Rating by CM</button>
                    </div>
                    <div className={`tabbing-button ${tab === 1 ? "active" : ""}`}
                        onClick={() => setTab(1)} >
                        <button className="tabcompleted">Suggestions</button>
                    </div>
                    <div className={`tabbing-button ${tab === 2 ? "active" : ""}`}
                        onClick={() => setTab(2)} >
                        <button>Summary</button>
                    </div>
                    <div>
                    <div
                        className={`tabbing-button ${tab === 3 ? "active" : ""}`}
                        onClick={() => setTab(3)}
                    >
                        <button>Badges</button>
                    </div>
                    </div>
                    </div>
                    
                    <div className="assigntime-bx">
                        <input type="date" />
                        <select className="select-day">
                            <option>Week</option>
                            <option>Month</option>
                            <option>This Month</option>
                            <option>12 Month</option>
                            <option>This Year</option>
                        </select>
                    </div>
                </div>
                {tab === 0 && <RatingTask />}
                {tab === 1 && <Suggestions />}
                {tab === 2 && <Summary />}
                {tab === 3 && <Budges />}
            </div>
        </div>
    )
}
export default AssignreportChart