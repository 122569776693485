import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState } from "react";
import { useLocation } from "react-router-dom";
import AssignindianCollege from "./AssignindianCollege";
import AssignoverseasCollege from "./AssignoverseasCollege";


const AssignTaskCollege = () => {
    let location = useLocation();
    const [tab, setTab] = useState(location?.state?.tab ? location?.state?.tab : 0)
    const dropdownImage = () => {

    }
    return (
        <div className="wrapper-assignpage">
            <div className="dashboard">
                <div className="subheader wrapassign-subheader">
                    <div className="subheader-title">
                        <h2 className="heading-36">
                         Assigned Colleges
                        </h2>
                    </div>
                    <div className="subheader-right-top ">
                        <div className="subheader-search">
                            <input placeholder="search here" type="search" id="formFile" className="form-control" />
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        </div>
                        <button className="add-btn btn-prefrence">Preferences</button>
                        <button className="add-btn btn-export">Filter</button>
                        <button className="add-btn btn-export">Export as csv</button>
                    </div>
                </div>
                

                <div className="nav-tab assigntask-nav-tab">
                <div
                        className={`tabbing-button ${tab === 0 ? "active" : ""}`}
                        onClick={() => setTab(0)}
                    >
                        <button>Indian College</button>
                    </div>
                    <div
                        className={`tabbing-button ${tab === 1 ? "active" : ""}`}
                        onClick={() => setTab(1)}
                    >
                        <button>Overseas College</button>
                    </div>
                </div>
                {tab === 0 && <AssignindianCollege />}
                {tab === 1 && <AssignoverseasCollege />}
                </div>
                
            </div>
    )
}
export default AssignTaskCollege;