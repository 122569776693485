import api from "../helpers/interceptor";

export const staticContentDetails = async (payload) => await api.get(`/getStaticContent?type=${payload.type}`);
export const editStaticContentDetails = async (payload) => await api.put(`/editStaticContent`, payload);
export const staticContentFaqList = async (payload) => await api.get(`/getStaticContentFAQ?faq_type=${payload.faq_type}`);
export const editStaticContentfaqs = async (payload) => await api.put(`/editStaticContentFAQ`, payload);
export const addStaticContentfaqs = async (payload) => await api.post(`/addStaticContentFAQ`, payload);
export const staticContentFaqDetails = async (payload) => await api.get(`/getStaticContentFAQDetails?faq_id=${payload.faq_id}`);
export const addStaticContentAboutUs = async (payload) => await api.post(`/addStaticContentAboutUs`, payload);
export const editStaticContentAbout = async (payload) => await api.put(`/editStaticContentAboutUs`, payload);
export const staticContentAboutUsList = async (payload) => await api.get(`/getStaticContentAboutUs`, payload);
export const staticContentAboutUsDetails = async (payload) => await api.get(`/getStaticContentAboutUsDetails?type=${payload.type}&id=${payload.id}`);

