import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { permissionRoutes } from "../constants/permissionRoutes";
import MyContext from "../context/permissions";

const PrivateRoute = (props) => {
  const { permissions } = useContext(MyContext);

  const user = localStorage.getItem("token");
  let admin_type = localStorage.getItem("admin_type");
  if (!user) {

    //  ##### USER is not authenticated #######
    return <Navigate to="/" />;
  }
  
  let checkPermissions = permissions?.filter(item => item.checked)?.flatMap(item => item.permission_name)
  let permitted = checkPermissions?.includes(props.page);
  if (!permitted && admin_type === "sub_admin") {
    let availablePermission = "/error"
    if (!checkPermissions.length) {

      availablePermission = permissionRoutes[checkPermissions[0]]
    }

    // ###### FOR AVAILABE PERMISSIONS #####

    // return <Navigate to={availablePermission} />;
  }

  return props.children;
};

export default PrivateRoute;