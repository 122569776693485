import React from "react";
import $ from "jquery";

$(".btn-pagination").on("click", function () {
    $(".btn-pagination").removeClass("active");
    $(this).addClass("active");
    });
    

const AssignindianCollege = () => {
    return(
        <div className="wrapper-assignpage">
<div className="subheader wrapassign-subheader assigncolllege-subheader">
                    <div className="subheader-title">
                        <h2 className="heading-30">
                            Bulk Indian College Action
                        </h2>
                        <button>Submit for Publish</button>
                        <button>Edit Permission</button>
                    </div>
                    <div className="subheader-right-top ">
                        <p>Quick Filter</p>
                        <div className="wrap-option">
                            <input type="checkbox" name="name" />
                            <label>Show verify colleges</label>
                        </div>
                        <div className="wrap-option">
                            <input type="checkbox" name="name" />
                            <label>Non verify colleges</label>
                        </div>
                    </div>
                </div>
                <div>
                <div className="table-main assigndata-table fixTableHead">
                                <table className="table nowrap table-bordered table-default-dark ">
                                    <thead><tr className="table-heading">
                                        <th className="td_ellipse">S.no.</th>
                                        <th className="td_ellipse">College Name</th>
                                        <th className="td_ellipse">College Name</th>
                                        <th className="td_ellipse">Overview</th>
                                        <th className="td_ellipse">Courses & fees</th>
                                        <th className="td_ellipse">Placement</th>
                                        <th className="td_ellipse">Status</th>
                                        <th className="td_ellipse">Modified Date</th>
                                        <th className="td_ellipse">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="td_ellipse">1</td>
                                            <td className="td_ellipse">SYGCL25161</td>
                                            <td className="td_ellipse">Demo - IPS Academy, institute of Business Management and</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse">Working Mode </td>
                                            <td className="td_ellipse">24 June, 2021</td>
                                            <td className="td_ellipse"><button className="btn btn-primary">Submit for Publish</button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">1</td>
                                            <td className="td_ellipse">SYGCL25161</td>
                                            <td className="td_ellipse">Demo - IPS Academy, institute of Business Management and</td>
                                            <td className="td_ellipse"><span className="yellow-dot"></span>TL</td>
                                            <td className="td_ellipse"><span className="pink-dot"></span>Intern</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse">Working Mode </td>
                                            <td className="td_ellipse">24 June, 2021</td>
                                            <td className="td_ellipse"><button className="btn btn-primary">Submit for Publish</button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">1</td>
                                            <td className="td_ellipse">SYGCL25161</td>
                                            <td className="td_ellipse">Demo - IPS Academy, institute of Business Management and</td>
                                            <td className="td_ellipse"><span className="yellow-dot"></span>TL</td>
                                            <td className="td_ellipse"><span className="pink-dot"></span>Intern</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse">Working Mode </td>
                                            <td className="td_ellipse">24 June, 2021</td>
                                            <td className="td_ellipse"><button className="btn btn-primary">Submit for Publish</button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">1</td>
                                            <td className="td_ellipse">SYGCL25161</td>
                                            <td className="td_ellipse">Demo - IPS Academy, institute of Business Management and</td>
                                            <td className="td_ellipse"><span className="yellow-dot"></span>TL</td>
                                            <td className="td_ellipse"><span className="pink-dot"></span>Intern</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse">Working Mode </td>
                                            <td className="td_ellipse">24 June, 2021</td>
                                            <td className="td_ellipse"><button className="btn btn-primary">Submit for Publish</button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">1</td>
                                            <td className="td_ellipse">SYGCL25161</td>
                                            <td className="td_ellipse">Demo - IPS Academy, institute of Business Management and</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>TL</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Intern</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse">Working Mode </td>
                                            <td className="td_ellipse">24 June, 2021</td>
                                            <td className="td_ellipse"><button className="btn btn-primary btn-permission4edit">Permission for Edit</button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">1</td>
                                            <td className="td_ellipse">SYGCL25161</td>
                                            <td className="td_ellipse">Demo - IPS Academy, institute of Business Management and</td>
                                            <td className="td_ellipse"><span className="yellow-dot"></span>TL</td>
                                            <td className="td_ellipse"><span className="pink-dot"></span>Intern</td>
                                            <td className="td_ellipse"><span className="green-dot"></span>Content Manager</td>
                                            <td className="td_ellipse">Working Mode </td>
                                            <td className="td_ellipse">24 June, 2021</td>
                                            <td className="td_ellipse"><button className="btn btn-primary">Submit for Publish</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <button className=" btn-pagination btn-prev">Previous</button>
                                <button className="btn-pagination btn-next active">Next</button>
                            </div>
                        </div>
        </div>
    )
}
export default AssignindianCollege