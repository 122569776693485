import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import MyContext from "../../../../context/permissions";
import {
  multipleClgModule,
  singleClgModule,
  subAdminModuleData,
} from "../../../../services/subadmin";

const AssignModel = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isCheck,
    collegeId,
    taskId,
    moduleData,
    singleModule,
    teamCollegeName,
    teamCollegeId,
    teamModule,
    collegeMoudleInitial,
    assignClgName,
    assignClgId
  } = props;
  // console.log("taskId", taskId)
  // console.log("collegeMoudleInitial", collegeMoudleInitial)
  // const handleChange = () =>{
  //   props.onHide
  //   navigate(`/add-assign-task`)
  // }

  // const collegeName = localStorage.getItem("clg_name")
  const editable = location.pathname.indexOf("edit") > -1 ? true : false;

  const [checkedBoxes, setCheckedBoxes] = useState("");
  const [taskChecked, setTaskChecked] = useState([]);

  const { permissions } = useContext(MyContext);
  const [availablePermissions, setAvailablePermissions] = useState({});
  const [clgNameKeys, setClgNameKeys] = useState("");
  const [clgNameValues, setClgNameValues] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [moduleClgid, setModuleClgid] = useState({});
  const [filterArray, setFilterArray] = useState([]);
  useEffect(() => {
    findCollegePermissions();
  }, []);

  const handleCheckboxChange = (event, id) => {
    const checkboxValue = event.target.checked;
    if (role === "content_manager") {
      let tempArr = [];
      let moduleName = [];
      taskChecked?.forEach((item) => {
        if (item.permission_id === id) {
          item.checked = checkboxValue;
        }
        if (item.checked) {
          moduleName.push(item.permission_name);
        }
        tempArr.push(item);
      });
      setCheckedBoxes(moduleName?.join(","));
      setTaskChecked(tempArr);
      setIsButtonVisible(true);
    } else {
      let tempArr = [];
      let moduleName = [];
      taskChecked?.forEach((item) => {
        // debugger;
        if (item.item === id) {
          item.checked = checkboxValue;
        }
        if (item.checked) {
          moduleName.push(item.item);
        }
        tempArr.push(item);
      });
      setCheckedBoxes(moduleName?.join(","));
      setTaskChecked(tempArr);
    }
  };

  const findCollegePermissions = () => {
    let result = [];
    permissions.forEach((item) => {
      if (item.permission_name === "Colleges") {
        let obj = item?.permissions?.find(
          (ii) => ii.permission_name === "Indian Colleges"
        );
        result = obj.sub_permissions?.filter((i) => i.checked);
      }
    });
    let obj = {};
    result?.forEach((item) => {
      obj[item.permission_name] = true;
    });
    setAvailablePermissions(obj);
  };
  useEffect(() => {
    getPermissionStatus();
  }, []);
  const [isChecking, setIsChecking] = useState(false);

  const role = localStorage.getItem("role") || "";

  const getPermissionStatus = () => {
    if (role === "content_manager") {
      let subPermission =
        permissions
          ?.find((ii) => ii.permission_name === "Colleges")
          .permissions?.find((ii) => ii.permission_name === "Indian Colleges")
          .sub_permissions?.filter((item) => item.checked)
          ?.map((item) => ({ ...item, checked: false, value: "write" })) || [];
      setTaskChecked(subPermission);
    } else {
    }
  };

  useEffect(() => {
    teamLeadPermissions();
  }, [moduleData, singleModule, role]);
  const teamLeadPermissions = () => {
    if (role === "team_lead") {
      // let subPermission = teamModule?.map((item) => ({ ...item, checked: false, value: "write" })) || []
      // let subPermission = Object.keys(moduleData)?.map((item) => ({ item, checked: false, value: "write" })) || []
      let subPermission = Object?.keys(singleModule)?.map((item) => ({
        item,
        checked: false,
        value: "write",
      })) || [];
      setTaskChecked(subPermission);
    }
  };

  // sending data when click on final save button

  let filteredArray = [];
  for (let i = 0; i < collegeId?.length; i++) {
    for (let j = 0; j < checkedBoxes.split(",").length; j++) {
      if (moduleClgid[checkedBoxes.split(",")[j]]?.indexOf(isCheck[i]) == -1) {
        filteredArray.push({
          "college_id": collegeId[i].toString(),
          "module": checkedBoxes.split(",")[j]
        })
      }
    }
  }

  console.log("idididididid", assignClgName, assignClgId)

  const tlCollegeModule = () => {
    let moduleNamesArray = checkedBoxes.split(',');
    let filteredArray = [];
    for (let j = 0; j < moduleNamesArray.length; j++) {
      filteredArray.push({
        "college_id": teamCollegeId?.toString(),
        "module": moduleNamesArray[j].trim()
      });
    }
    return filteredArray;
  }
  let tlSendCollegeModule = tlCollegeModule()

  let cmModuleName = taskChecked?.map(item => item?.permission_name)
  function findCommonElements(arr1, str2) {
    const arr2 = str2?.split(',');
    const commonElements = arr1?.filter(item => arr2?.includes(item));
    return commonElements;
  }
  let sameModuleName = findCommonElements(cmModuleName, collegeMoudleInitial);
  const shouldCheckCheckbox = (permissionName) => {
    return sameModuleName.includes(permissionName);
  };

  let moduleArray = checkedBoxes?.split(',');
  let singleClgCM = moduleArray?.map(modules => ({
    college_id: assignClgId,
    module: modules
  }));

  console.log("checkbox", checkedBoxes)
  console.log("checkbox", assignClgId)
  return (
    <div className="wrapper-assignpage">
      {role === "content_manager" ? (
        <Modal
          className="assign-modal wrapper-assignpage"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Body>
            <div>

              <div className="row">
                <div className="col-md-8 p-0">
                  <Modal.Header className="text-center d-block ">
                    <Modal.Title id="contained-modal-title-vcenter" className="f-14 p-0 mb-3">
                      Your Selected {props.isCheck.length || 1} Colleges
                      <div className="d-flex check-wrap">
                        <p className="m-0 p-0 blue-p">{props.isCheck.join(", ") || assignClgName}</p>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <div className="d-flex flex-wrap check-wrap row mt-3">
                    {taskChecked?.map((list, ind) => (
                      <div className=" d-flex col-md-4 mb-3">
                        <input
                          className=" mx-2"
                          type="checkbox"
                          id={`${list.permission_name}${ind}`}
                          checked={shouldCheckCheckbox(list?.permission_name) ? shouldCheckCheckbox(list?.permission_name) : list?.checked}
                          onChange={(e) =>
                            handleCheckboxChange(e, list.permission_id)
                          }
                        />
                        <label htmlFor={`${list.permission_name}${ind}`}>
                          {list.permission_name || "NA"}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-4 p-0 check-div">
                  <div className="clgModule-data ">
                    {isButtonVisible && (
                      <div className="d-flex gap-3 justify-content-between mb-3 wrapper-assignpage px-2">
                        <h6 className="check-heading">
                          Modules that already assigned to below colleges
                        </h6>
                        <button
                          className="check-btn"
                          id="saveButton"
                          onClick={async () => {
                            let singleSave =
                              document.getElementById("saveButton");
                            let payload = {
                              module: checkedBoxes,
                              college_id: collegeId.join(",") || assignClgId,
                            };
                            let response = await multipleClgModule(payload);
                            setModuleClgid(response?.data?.result)
                            setClgNameKeys(Object.keys(response?.data?.result));
                            setClgNameValues(
                              Object.values(response?.data?.result)
                            );
                            setIsButtonVisible(false);
                            if (response?.data?.statusCode === 200) {
                              singleSave.disabled = false;
                            }
                          }}
                        >
                          Check for Module Assigned or Not
                        </button>
                      </div>
                    )}
                    {clgNameKeys ? (
                      <div>
                        {clgNameKeys?.map((item, ind) => (
                          <div>
                            <h6 className="module-heading">{item || "NA"} </h6>
                            <p className="clgName p-3 m-0">
                              {clgNameValues[ind] || "NA"}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      console.log("Kumar")
                    )}

                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center ">
            <button
              type="button"
              className=" cancel-btn add-btn"
              onClick={props.onHide}
            >
              Discard{" "}
            </button>{console.log("clg name", assignClgName)}
            <button
              className="save-btn add-btn"
              onClick={() => {
                if (editable) {
                  navigate(`/edit-assign-task/${taskId}`, {
                    state: {
                      checkedBoxes: checkedBoxes,
                      isCheck: isCheck,
                      collegeId: collegeId,
                      taskChecked: taskChecked,
                      filteredArray: filteredArray,
                      assignClgName: assignClgName,
                      assignClgId: assignClgId,
                      singleClgCM: singleClgCM
                    },
                  });
                } else {
                  navigate(`/add-assign-task`, {
                    state: {
                      checkedBoxes: checkedBoxes,
                      isCheck: isCheck,
                      collegeId: collegeId,
                      taskChecked: taskChecked,
                      filteredArray: filteredArray,
                      assignClgName: assignClgName,
                      assignClgId: assignClgId,
                      singleClgCM: singleClgCM
                    },
                  });
                }
                //   handleFelterModule()
              }
              }
            >
              Save
            </button>
            <br />
            {/* <sub>*Note : Click Save when all College Name show NA</sub> */}
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          className="assign-modal "
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="text-center d-block ">
            <Modal.Title id="contained-modal-title-vcenter" className="f-14">
              Your Selected "{teamCollegeName}".

              <p className="blue-p m-0 p-0">{props.isCheck}</p>

            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="wrapper-assignpage">

            <h4>Select Module</h4>
            <div className="d-flex row check-wrap">
              {taskChecked?.map((list, ind) => (
                <div className="col-md-3 d-flex mb-3">
                  <input
                    className=" mx-2"
                    type="checkbox"
                    id={`${list?.item}${ind}`}
                    checked={list?.item?.checked}
                    value="Employee_Info"
                    disabled={singleModule[list.item]}
                    onChange={(e) => handleCheckboxChange(e, list?.item)} // className={`tabbing-button ${tab === 1 ? "active" : ""}`}  "text-primary":"text-muted"
                  />
                  <label
                    htmlFor={`${list?.item}${ind}`}
                    className={`tabbing-button ${singleModule[list.item] ? "text-muted" : "color-dim"
                      }`}
                  >
                    {list?.item}
                  </label>{console.log("rrrrr", singleModule[list.item] ? "text-muted" : "color-dim")}
                </div>
              ))}
            </div>
            <sub className="font-weight-bold">
              * Checkbox is disabled when module is already assigned.
            </sub>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center mt-4">
            <button
              type="button"
              className=" cancel-btn add-btn"
              onClick={props.onHide}
            >
              Discard{" "}
            </button>
            <button
              className="save-btn add-btn"
              onClick={() => {
                if (editable) {
                  navigate(`/edit-assign-task/${taskId}`, {
                    state: {
                      checkedBoxes: checkedBoxes,
                      isCheck: isCheck,
                      teamCollegeName: teamCollegeName,
                      teamCollegeId: teamCollegeId,
                      taskChecked: taskChecked,
                    },
                  });
                } else {
                  navigate(`/add-assign-task`, {
                    state: {
                      checkedBoxes: checkedBoxes,
                      isCheck: isCheck,
                      teamCollegeName: teamCollegeName,
                      teamCollegeId: teamCollegeId,
                      taskChecked: taskChecked,
                      filteredArray: tlSendCollegeModule,
                    },
                  });
                }
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
export default AssignModel;
