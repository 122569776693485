import React from "react";

const Checkbox = ({ id, type, name, handleClick, isChecked , item={}}) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      onChange={(e)=>handleClick(name, e.target.checked, id)}
      checked={isChecked}

    />
  );
};

export default Checkbox;