import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useLocation, useNavigate } from "react-router-dom";
import { staticContentDetails } from "../../services/staticContent";
import StaticContentTandC from "./StaticContentTandC";
import StaticContentAboutUs from "./StaticContentAboutUs";
import StaticContentPolicy from "./StaticContentPolicy";
import StaticContentFAQ from "./StaticContentFAQ";
import { faqDropdown } from "../../constants/dropdown";
import useScrollTop from "../../hooks/useScrollTop";

const tabName = ["terms_conditions", "about_us", "privacy_policy", "faqs"];
const StaticContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  const [showAdd, setShowAdd] = useState(false);
  const [tab, setTab] = useState(location?.state?.tab ? location?.state?.tab : 0);
  const [formType, setFormType] = useState(location?.state?.faq_type ? location?.state?.faq_type : faqDropdown[0].value);
  useScrollTop();
console.log("formtype", formType)
  useEffect(() => {
    getStaticContent()
  }, [tab]);

  const getStaticContent = async () => {
    let response = await staticContentDetails({ type: tabName[tab] });
    if (response?.data?.statusCode === 200) {
      setContent(response?.data?.result);
      setShowAdd(false)
    }
  }
  // let mainFunc = getStaticContent();
  const staticContentTab = () => {
    switch (tab) {
      case 0:
        return "Terms Conditions";
      case 1:
        return "About Us";
      case 2:
        return "Privacy Policy";
      case 3:
        return "FAQs";
      default:
        return "NA";
    }
  };

  const staticContentApis = () => {
    switch (tab) {
      case 0:
        navigate(showAdd ? `/edit-static-tnc` : `/edit-static-tnc`);
        break;
      case 1:
        navigate(showAdd ? `/add-static-about-us` : `/add-static-about-us`);
        break;
      case 2:
        navigate(showAdd ? `/edit-static-policy` : `/edit-static-policy`, { state: { faq_type: formType } });
        break;
      case 3:
        navigate(showAdd ? `/add-static-faq` : `/add-static-faq`);
        break;
      default:
        return "NA";
    }
  };
  const locationTab = location?.state?.tab;
  return (
    <div>
      <div className="subheader-title">
        <h2 className="heading-36">Static Content</h2>
        <div className="subheader-right-btm">
          <button
            className="btn-purple"
            onClick={() => {
              staticContentApis();
            }}
          >{tab === 3 || tab === 1 ? "Add" : showAdd ? "Add" : "Edit"} {staticContentTab()}
            {/* {showAdd ? "Add" : "Edit"} {staticContentTab()} */}
          </button>
        </div>
      </div>
      <div className="verticle-tab">
        <Tab.Container id="left-tabs-example"
          defaultActiveKey={
            locationTab === 0
              ? "first"
              : locationTab === 1
                ? "second"
                : locationTab === 2
                  ? "third"
                  : locationTab === 3
                    ? "fourth"
                    : "first"}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first" className={`tabbing-button ${tab === 0 ? "active-static" : ""}`} onClick={() => setTab(0)}>Terms Conditions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className={`tabbing-button ${tab === 1 ? "active-static" : ""}`} onClick={() => setTab(1)}>About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third" className={`tabbing-button ${tab === 2 ? "active-static" : ""}`} onClick={() => setTab(2)}>Privacy Policy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth" className={`tabbing-button ${tab === 3 ? "active-static" : ""}`} onClick={() => setTab(3)}>FAQs</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {tab === 0 && (<Tab.Pane eventKey="first"><StaticContentTandC
                  content={content}
                /></Tab.Pane>)}
                {tab === 1 && (<Tab.Pane eventKey="second"><StaticContentAboutUs
                  showAdd={showAdd}
                  setShowAdd={setShowAdd}
                /> </Tab.Pane>)}
                {tab === 2 && (<Tab.Pane eventKey="third"><StaticContentPolicy
                  content={content}
                /> </Tab.Pane>)}
                {tab === 3 && (<Tab.Pane eventKey="fourth"> <StaticContentFAQ
                  showAdd={showAdd}
                  setShowAdd={setShowAdd}
                  formType={formType}
                  setFormType={setFormType}
                  mainFunc={getStaticContent()}
                /> </Tab.Pane>)}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default StaticContent;
