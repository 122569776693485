import {
  faFacebook,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SubadminFooter = () => {
  return (
    <div>
      <div className="footer subadmin-footer">
        <div className="row p-3 px-5">
          <div className="col-md-6 footer-txt1">
            <h3>Copyright {new Date().getFullYear()} - Sygnific Careers</h3>
          </div>
          <div className="col-md-6 footer-txt">
            <h3>
              <span className="pointer-footer">Contact us</span>
              <span className="pointer-footer">About us</span>
              <span className="pointer-footer">Connect to your TL</span>
              <span className="pointer-footer">Connect with HRMS</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubadminFooter;
