import { faEdit, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { faqDropdown } from "../../constants/dropdown";
import { editStaticContentfaqs, staticContentFaqList } from "../../services/staticContent";
import Loader from "../../HOC/Loader";
import toast from "react-hot-toast";
import useScrollTop from "../../hooks/useScrollTop";

const StaticContentFAQ = ({ showAdd, setShowAdd, updateRouteAndType, formType, setFormType }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [staticFaq, setStaticFaq] = useState([]);
    useScrollTop();

    useEffect(() => {
        getStaticFaqList();
    }, []);

    let payload = {
        faq_type: formType,
    };

    const getStaticFaqList = async () => {
        let response = await staticContentFaqList(payload)
        if (response?.data?.statusCode === 200) {
            setStaticFaq(response?.data?.result || []);
            setShowAdd(true);
        } else {
            setShowAdd(false);
            setStaticFaq([]);
        }

    }

    const handleTypeChange = (e) => {
        payload.faq_type = e.target.value;
        setFormType(e.target.value);
        getStaticFaqList();
    };

    const handleEdit = async (id, type) => {
        navigate(`/edit-static-faq/${id}`, { state: { faq_type: formType } });
    };

    const handleDelete = async (id) => {
        let payload = { faq_id: JSON.stringify(id), action: "delete" };
        let response = await editStaticContentfaqs(payload);
        if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.statusMessage || "Success");
            getStaticFaqList();
        } else {
            toast.error(response?.data?.statusMessage || "Something Went Wrong");
        }
    };

    return (
        <div>
            <div className="d-flex gap-3  mb-3">
                <h3 className=""><FontAwesomeIcon className="play-icon" icon={faPlay} />Select Module :</h3>
                <select
                    className="form-control form-select faq-select"
                    value={formType}
                    onChange={handleTypeChange}
                >
                    {faqDropdown.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="campus-life faq_wrapper">
                {staticFaq?.length === 0 && <h1 className="no-data">No Data Found</h1>}
                <Accordion defaultActiveKey="0" flush>
                    {staticFaq?.map((item, index) => (
                        <Accordion.Item key={index} eventKey={index}>
                            <Accordion.Header>
                                <div>⦁ {item.question} </div>
                                <div className="faq-icon">
                                    <button
                                        className="edit-btn"
                                        onClick={() => handleEdit(item.faq_id)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>{" "}
                                    <button
                                        className="delete-btn"
                                        onClick={() => handleDelete(item.faq_id)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p className="html_string" dangerouslySetInnerHTML={{ __html: item.answer?.replaceAll("%22", `"`)?.replaceAll("%27", `'`) || "NA" }}></p>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    )
}

export default StaticContentFAQ;