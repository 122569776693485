import React, { useEffect } from "react";

import { Badge, Button, Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSearch,
  faBars,
  faBell,
  faFolder,
  faSquareCheck,
  faCommentSms,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { generateConfirm } from "../../helpers";

const SubadminHeader = (props) => {
  const user_img = process.env.PUBLIC_URL + "/assets/images/user-img.png";
  const toggleMenuHnadler = () => {
    props.onClickMenuBtn();
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header = document.querySelector(".main-header");
    const scrollTop = window.scrollY;
    scrollTop > 50
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
  }
  const name = localStorage.getItem("name") || "";
  const role = localStorage.getItem("role") || "";

  const roleFunction = ()=>{
    if(role === "content_manager"){
      return "Content Manager"
    } else if (role === "team_lead"){
      return "Team Leader"
    } else{
      return "Intern"
    }

  }
  return (
    <header className="main-header subadmin-header">
      <Navbar expand="lg" className="header-nav">
        <Container fluid className="justify-content-between">
          
          <div className="header-nav-right">
            <div className="header-nav-items">
              {/* <button>
              <FontAwesomeIcon icon={faComment}/>
      Profile <Badge bg="danger">9</Badge>
      <span className="visually-hidden">unread messages</span>
    </button> */}
              {/* <Button className="btn-icon subadmin-icon">
                <FontAwesomeIcon icon={faBell} />
              </Button> */}
            </div>
            <Dropdown className="user-profile">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={user_img} />
                <div className="user-deitail">
                  <p className="user-name">{name}</p>
                  <p className="user-position">{roleFunction()}</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/profile-detail">My Account</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Settings</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                 generateConfirm(
                  "Are you sure you want to Log out ?",
                  " ",
                  "log-out",
                  () => {
                    logout()
                    navigate("/")
                  }
                )
                }}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};
export default SubadminHeader
