import { useState } from "react";
import { HashRouter } from "react-router-dom";
import AppRouter from "./routers";
import { Toaster } from "react-hot-toast";
import "./styles/index.scss";
import MyContext from "./context/permissions";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-calendar/dist/Calendar.css";
import ImgCrop from "./components/common/ImgCrop";
function App() {
  let localPermission = localStorage.getItem("local-permissions");
  localPermission = localPermission ? JSON.parse(localPermission) : [];
  const [permissions, setPermissions] = useState(localPermission || []);
  return (
    <div className="App">
      <HashRouter>
        <Toaster position="top-center" reverseOrder="false" />
        <MyContext.Provider value={{ permissions, setPermissions }}>
          <AppRouter />
        </MyContext.Provider>
      </HashRouter>
    </div>
  );
}

export default App;
