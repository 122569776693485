import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react'
import { set, useFieldArray, useForm } from 'react-hook-form';
import { Button, Form } from "react-bootstrap";
import * as yup from 'yup';
import api from "../../helpers/interceptor";
import { v4 as uuidv4 } from "uuid";
import ImageUpload from '../../components/common/ImageUpload';
import { faAngleRight, faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../../components/common/Checkbox';
import toast from 'react-hot-toast';

const QuestionForm = () => {

  const [isSubmitted, setIsSubmitted] = useState(false)

  const courseSchema = yup.object().shape({
    class: yup.string().required(),
    sub_category_id : yup.number().required(), 
    passage_id : yup.string(),
    question: yup.string().required("Please enter the question"),
    questionFiles: yup.array(yup.string()).required(),
    options: yup.array(yup.object().shape({
      option: yup.string().required(),
      type: yup.string("File" | "String").required()
    }))
  });


  const {
    register,
    handleSubmit,
    reset,
    getValues,

    setValue,
    control,
    formState: {isValid, errors },
  } = useForm({
    defaultValues: {
      class: "",
      passage_id: "",
      question: "",
    sub_category_id : null,
      questionFiles: [],
      options: [],
      answer : null
    },
    resolver: yupResolver(courseSchema),
  });

  const [answer, setAnswer] = useState(null)


  const onSubmitHandler = handleSubmit(async (value) => {
    setIsSubmitted(true)
    if(!isValid) {
      setIsSubmitted(false)
      return 
    }
    await api.post("/addAptitudeQuestion", {...value,   answer : {
      value : value.options[answer].option,
      type : value.options[answer].type
    }}).then(response => {
      if(response) {
        toast.success("question added Successfully");
        setIsSubmitted(false)
        reset()
        setAnswer(null)
        setEmpptyArray([uuidv4()])
        setOptionEmptyArray([uuidv4()])
      }
    })
    setIsSubmitted(false)
  })

  const [EmptyArray, setEmpptyArray] = useState([uuidv4()])
  const [optionEmptyArray, setOptionEmptyArray] = useState([uuidv4()])


  const handleRemove = async (index) => {
    let currentImageList = getValues().questionFiles
    const url = currentImageList[index]
    if (url)
      await api.post("/deleteS3BucketUrl", { oldS3Url: url })
    let currentEmptyArry = EmptyArray
    currentEmptyArry.splice(index, 1)
    setEmpptyArray([...currentEmptyArry])
    currentImageList.slice(index, 1)
  }

  const handleRemoveOption = async (index) => {
    let currentOptionList = getValues().options
    if(currentOptionList[index]?.type === "file" && currentOptionList[index]?.option){
      const response = await api.post("/deleteS3BucketUrl", { oldS3Url: currentOptionList[index].option })
    }
    let currentEmptyArry = optionEmptyArray
    currentEmptyArry.splice(index, 1)
    setOptionEmptyArray([...currentEmptyArry])
    currentOptionList.slice(index, 1)
  }


  return (
    <div>
      <form onSubmit={onSubmitHandler}>
        <Button disabled={isSubmitted} type='submit'> 
            Submit Form
        </Button>
        <div className="col-md-12">
          <h6 className="headingwithicon">
            Select Grade
          </h6>
          <Form.Select
            className="form-control"
            aria-label="Default select example"
            {...register("class")}
          >
            <option value="">Select Grade</option>
            <option value="9 & 10">9 & 10</option>
            <option value="11 & 12">11 & 12</option>
            <option value="graduate & professional">Graduate & professional</option>
          </Form.Select>
          {errors.class ? (
            <div className="input-field-error">
              {errors.class.message}
            </div>
          ) : null}
        </div>
        <div className="col-md-12">
          <h6 className="headingwithicon">
            Select Sub Category
          </h6>
          <Form.Select
            className="form-control"
            aria-label="Default select example"
            {...register("sub_category_id")}
          >
            
            
            
            <option value="">Select Question's sub category</option>
            
            {
              [
                {sub_category_name:"Spatial Reasoning", sub_category_id:34},
                {sub_category_name:"Verbal Reasoning", sub_category_id:35},
                {sub_category_name:"Mechanical Reasoning", sub_category_id:36},
                {sub_category_name:"Critical Thinking", sub_category_id:37},
                {sub_category_name:"Abstract Reasoning", sub_category_id:38},
                {sub_category_name:"Quantitative Reasoning", sub_category_id:39},
                {sub_category_name:"Attention to Detail", sub_category_id:40},
                {sub_category_name:"Perceptual Speed", sub_category_id:41},
              ].map(item =>  <option value={item.sub_category_id}>{item?.sub_category_name}</option>)
            }
          </Form.Select>
          {errors.class ? (
            <div className="input-field-error">
              {errors.class.message}
            </div>
          ) : null}
        </div>
        <div className='col-md-12'>
          <h6>
            Pessage id
          </h6>
          <input {...register(`passage_id`)} className='form-control'
            placeholder='Enter here'></input>
          {errors?.passage_id ? (
            <div className="input-field-error">

              {
                errors?.passage_id["message"]
              }
            </div>
          ) : null}
        </div>
        <div className='col-md-12'>
          <h6>
            Question
          </h6>
          <input {...register(`question`)} className='form-control'
            placeholder='Enter here'></input>
          {errors?.question ? (
            <div className="input-field-error">

              {
                errors?.question["message"]
              }
            </div>
          ) : null}
        </div>
        <div className="col-md-12 ">
          <h6 className='d-flex w-100 align-items-center '>

            Upload Question Image
          </h6>
          <div className='d-flex gap-x-2 align-items-center'>

            {EmptyArray.map((id, index) => {
              return <div key={id} className='ps-3 position-relative' >
                <FontAwesomeIcon className='ms-2 position-absolute ' style={{background : "#f2f2f2", padding : "10px", zIndex: "10", right: "0", top: "0" }} onClick={() =>
                  handleRemove(index)
                } icon={faClose} />

                <ImageUpload
                  imgId={`questionFiles-${id}`}
                  inputId={`questionFiles-${id}-id`}
                  // src={indWebinarDetail?.background_image}
                  alt="College Image"
                  type=''
                  setValue={(key, value) => {
                    let currentImageList = getValues().questionFiles
                    currentImageList[index] = value
                    setValue("questionFiles", currentImageList)
                  }}
                  name={`questionFiles-${id}`}
                  register={register}
                />
              </div>
            })}
            <FontAwesomeIcon className='ms-2' style={{background : "#f2f2f2", padding : "10px"}} onClick={() => { setEmpptyArray([...EmptyArray, uuidv4()]) }} icon={faPlus} />
            {errors.questionFiles ? (
              <div className="input-field-error">
                {errors.questionFiles.message}
              </div>
            ) : null}
          </div>

        </div>
        <div className="col-md-12 mt-5">
          <h6 className='d-flex w-100 align-items-center '>

            Add options
          </h6>
        {console.log(optionEmptyArray)}
        {
          optionEmptyArray.map((items, index) => {
            return <div className='mt-3' key={items}>
                <AptiOptions answer={answer} setAnswer={setAnswer} handleRemoveOption={handleRemoveOption} id={items} errors={errors} index={index} register={register} setValue={setValue} getValues={getValues}  />
            </div>
          })
        }
               <Button className='mt-1' onClick={() => { setOptionEmptyArray([...optionEmptyArray, uuidv4()]) }}>
                  Add option
                </Button>
        </div>
      </form>

    </div>
  )
}

const AptiOptions = ({ id,index,errors,answer, handleRemoveOption,setAnswer, register, setValue, getValues }) => {
  const [optionType, setOptionType] = useState("")

  return (<div>
    <div className='d-flex justify-content-between align-items-center'>

    <Form.Select
      className="form-control w-50"
      value={optionType}
      onChange={(e) => setOptionType(e.target.value)}
      aria-label="Default select example"
    >
      <option value="">Select Option Type</option>
      <option value="file">File</option>
      <option value="text">Text</option>

    </Form.Select>
    <div style={{background : "#f2f2f2"}} className='d-flex align-items-center justify-centent-center'>

    <Checkbox isChecked={answer === index} handleClick={()=> setAnswer(index)} id={"check-foranswer"+id} type="checkbox"  />
     <label onClick={()=> setAnswer(index)} htmlFor={'check-foranswer'+id} className="m-0 ms-1 text-black"> Mark this Option as answer </label>
    </div>
    <FontAwesomeIcon  className='ms-2 ' style={{ zIndex: "10" ,padding : "10px" , right: "0", top: "0", background : "#f2f2f2" }} onClick={() =>
        handleRemoveOption(index)
      } icon={faClose} />
    </div>
    {optionType && (
      optionType === "file" ? 
      <div key={id} className='ps-3 col-md position-relative' >
      <ImageUpload
        imgId={`optionType-${id}`}
        inputId={`optionType-${id}-image`}
        // src={indWebinarDetail?.background_image}
        alt="College Image"
        type=''
        setValue={(key, value) => {
          let currentOptionList = getValues()?.options
          currentOptionList[index] = { type: optionType, option: value }
          setValue("options", currentOptionList)
        }}
        name={`optionType-${id}`}
        register={register}
      /> </div>: <div className='col-md-12 mt-2'>
        <input onChange={(e) => {
          let currentOptionList = getValues()?.options
          currentOptionList[index] = { type: optionType, option: e.target.value }
          setValue("options", currentOptionList)
        }} className='form-control'
          placeholder='Enter here'></input>
      </div>)
    }
  </div>)
}

export default QuestionForm