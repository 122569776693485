import React from "react";
import { faSearch, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

$('.btn-pagination').on('click', function(){
    $('.btn-pagination').removeClass('active');
    $(this).addClass('active')
})

const Report = () => {
    return(
        <div className="wrapper-assignpage">
<div className="subheader wrapassign-subheader">
                    <div className="subheader-title">
                        <h2 className="heading-36">
                            Reports
                        </h2>
                    </div>
                    <div className="subheader-right-top ">
                        <div className="subheader-search">
                            <input placeholder="search here" type="search" id="formFile" className="form-control" />
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        </div>
                        <button className="add-btn btn-prefrence">Preferences</button>
                        <button className="add-btn btn-filter">Filters</button>
                    </div>
                </div>
                <div>
                <div className="table-main assigndata-table fixTableHead">
                                <table className="table nowrap table-bordered table-default-dark ">
                                    <thead><tr className="table-heading">
                                        <th className="td_ellipse">Em ID</th>
                                        <th className="td_ellipse">Employee Name</th>
                                        <th className="td_ellipse">Position</th>
                                        <th className="td_ellipse">Short Description</th>
                                        <th className="td_ellipse">Joining Date</th>
                                        <th className="td_ellipse">Email</th>
                                        <th className="td_ellipse">Contact Number</th>
                                        <th className="td_ellipse">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="td_ellipse">SKT12</td>
                                            <td className="td_ellipse">Johnathan Doe</td>
                                            <td className="td_ellipse">TL</td>
                                            <td className="td_ellipse">National Institute of Technology</td>
                                            <td className="td_ellipse">24/12 2022</td>
                                            <td className="td_ellipse">rohan@gmail.com</td>
                                            <td className="td_ellipse">8800245057</td>
                                            <td className="action_btn justify-content-center wrap-action-btn">
                                                <button type="button" className="eye-btn  btn-primary">
                                                    <FontAwesomeIcon className="chart-icon" icon={faChartLine} />
                                                </button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">SKT12</td>
                                            <td className="td_ellipse">Johnathan Doe</td>
                                            <td className="td_ellipse">Intern</td>
                                            <td className="td_ellipse">National Institute of Technology</td>
                                            <td className="td_ellipse">24/12 2022</td>
                                            <td className="td_ellipse">rohan@gmail.com</td>
                                            <td className="td_ellipse">8800245057</td>
                                            <td className="action_btn justify-content-center wrap-action-btn">
                                                <button type="button" className="eye-btn btn-primary">
                                                    <FontAwesomeIcon className="chart-icon" icon={faChartLine} />
                                                </button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">SKT12</td>
                                            <td className="td_ellipse">Johnathan Doe</td>
                                            <td className="td_ellipse">TL</td>
                                            <td className="td_ellipse">National Institute of Technology</td>
                                            <td className="td_ellipse">24/12 2022</td>
                                            <td className="td_ellipse">rohan@gmail.com</td>
                                            <td className="td_ellipse">8800245057</td>
                                            <td className="action_btn justify-content-center wrap-action-btn">
                                                <button type="button" className="eye-btn btn-primary">
                                                    <FontAwesomeIcon className="chart-icon" icon={faChartLine} />
                                                </button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">SKT12</td>
                                            <td className="td_ellipse">Johnathan Doe</td>
                                            <td className="td_ellipse">Intern</td>
                                            <td className="td_ellipse">National Institute of Technology</td>
                                            <td className="td_ellipse">24/12 2022</td>
                                            <td className="td_ellipse">rohan@gmail.com</td>
                                            <td className="td_ellipse">8800245057</td>
                                            <td className="action_btn justify-content-center wrap-action-btn">
                                                <button type="button" className="eye-btn btn-primary">
                                                    <FontAwesomeIcon className="chart-icon" icon={faChartLine} />
                                                </button></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">SKT12</td>
                                            <td className="td_ellipse">Johnathan Doe</td>
                                            <td className="td_ellipse">TL</td>
                                            <td className="td_ellipse">National Institute of Technology</td>
                                            <td className="td_ellipse">24/12 2022</td>
                                            <td className="td_ellipse">rohan@gmail.com</td>
                                            <td className="td_ellipse">8800245057</td>
                                            <td className="action_btn justify-content-center wrap-action-btn">
                                                <button type="button" className="eye-btn btn btn-primary">
                                                    <FontAwesomeIcon className="chart-icon" icon={faChartLine} />
                                                </button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <button className=" btn-pagination btn-prev">Previous</button>
                                <button className="btn-pagination btn-next active">Next</button>
                            </div>
                        </div>
        </div>
    )
}
export default Report