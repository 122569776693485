import React, { useEffect, useState } from 'react'
import { editStaticContentAbout, staticContentAboutUsList } from '../../services/staticContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { photo_blue } from '../../constants/images';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateConfirm } from '../../helpers';
import toast from 'react-hot-toast';
import useScrollTop from '../../hooks/useScrollTop';

const StaticContentAboutUs = ({ showAdd, setShowAdd }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aboutList, setAboutList] = useState({});
  useScrollTop();
  useEffect(() => {
    getStaticAboutList();
  }, []);

  const getStaticAboutList = async () => {
    let payload = {

    }
    let response = await staticContentAboutUsList(payload)
    if (response?.data?.statusCode === 200) {
      setAboutList(response?.data?.result || [])
      setShowAdd(true)
    } else {
      setShowAdd(false)
      setAboutList({})
    }

  }

  const handleEdit = (detail_id, detail_type) => {
    navigate(`/edit-static-about-us/${detail_id}/${detail_type}`, { state: { state: detail_type } })
  }

  const handleDelete = async (values, actions, status) => {
    let payload = {
        id: JSON.stringify(values),
        action: actions,
        type: status
    }
    let response = await editStaticContentAbout(payload);
    if (response?.data?.statusCode === 200) {
        toast.dismiss();
        toast.success(response?.data?.statusMessage || "Success");
        getStaticAboutList();
    }
    else {
        toast.dismiss();
        toast.error(response?.data?.statusMessage || "Success");
    }
}
  return (
    <div>
      <div>
        <h1 className="mb-3"> <FontAwesomeIcon className="play-icon" icon={faPlay} />About Us</h1>
        <div className="row">
        {aboutList?.about_us?.length === 0 && <h1 className="no-data">No Data Found</h1>}
          {aboutList?.about_us?.map((item, index) => (
            <div className="col-md-12">
              <div key={index} className='testimonial_card p-3 mb-4'>
                <div className='time_date d-flex align-items-baseline justify-content-between'>
                  <Button type="button" className=" rating-header edit-btn me-1">
                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item.id, "about_us")} />
                  </Button>
                  <Button type="button" className="delete-btn ml-1">
                    <FontAwesomeIcon icon={faTrash}
                      onClick={() => {
                        generateConfirm(
                          "Are you sure you want to delete this About item ?",
                          "Press Yes if you want to delete !",
                          "cur-delete",
                          () => {
                            handleDelete(item.id, "delete", "about_us")
                          }
                        )
                      }}
                    />
                  </Button>
                </div>
                <h1>Heading</h1>
                <p className="m-0 mb-3">{item.heading || "NA"}</p>
                <div className="white-bg">
                  <div>
                    <h1>Sub Heading</h1>
                    <span className="mt-3 html_string" dangerouslySetInnerHTML={{ __html: item?.sub_heading?.replaceAll("%22", `"`)?.replaceAll("%27", `'`) || "NA" }}></span>
                  </div>
                  <h1>Image</h1>
                  <img className="rounded-3 mb-3" style={{ width: "275px", borderRadius: "10px" }} src={item.media || photo_blue} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="campus-image">
        <h1 className="mb-3"> <FontAwesomeIcon className="play-icon" icon={faPlay} />Members</h1>
        <div className="row">
        {aboutList?.members?.length === 0 && <h1 className="no-data">No Data Found</h1>}
          {aboutList?.members?.map((item, index) => (
            <div className="col-md-12">
              <div className='testimonial_card p-3  mb-4' key={index}>
              <div className='time_date d-flex align-items-baseline justify-content-between'>
                  <Button type="button" className="edit-btn me-1">
                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item.id, "members")} />
                  </Button>
                  <Button type="button" className="delete-btn ml-1">
                    <FontAwesomeIcon icon={faTrash}
                      onClick={() => {
                        generateConfirm(
                          "Are you sure you want to delete this Member ?",
                          "Press Yes if you want to delete !",
                          "cur-delete",
                          () => {
                            handleDelete(item.id, "delete", "members")
                          }
                        )
                      }}
                    />
                  </Button>
                </div>
                <h1>Member Name</h1>
                <p className="m-0 mb-3">{item.member_name || "NA"}</p>
                <div className="white-bg">
                  <div>
                    <h1>About Member</h1>
                    <span className="mt-3 html_string" dangerouslySetInnerHTML={{ __html: item?.about_member?.replaceAll("%22", `"`)?.replaceAll("%27", `'`) || "NA" }}></span>
                  </div>
                  <h1>Image</h1>
                  <img className="rounded-3 mb-3" style={{ width: "275px", borderRadius: "10px" }} src={item.member_image || photo_blue} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default StaticContentAboutUs;
