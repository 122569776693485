import React from 'react'
import useScrollTop from '../../hooks/useScrollTop';

const StaticContentPolicy = ({ content }) => {
  useScrollTop();
  return (
    <div>
      <div className="admission_para col-md-10">
        <p className="html_string" dangerouslySetInnerHTML={{ __html: content?.body?.replaceAll("%22", `"`)?.replaceAll("%27", `'`) || "NA" }}></p>
      </div>
    </div>
  )
}

export default StaticContentPolicy;