import { useRef } from "react";
import BundledEditor from "./BundledEditor";
import { getMediaURL } from "../../helpers";
// import "./App.css";

export default function CustomEditorHosted({
  initialValue = "",
  onChange = () => {},
}) {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  function isProperString(text) {
    // Check if the input is a non-empty string
    return typeof text === "string" && text.trim().length > 0;
  }
  return (
    <>
      <div className="col-md-9 editor mb-3">
        <BundledEditor
          onInit={(_evt, editor) => (editorRef.current = editor)}
          initialValue={initialValue
            .replaceAll("%22", `"`)
            .replaceAll("%27", `'`)}
          init={{
            branding: false,

            height: 300,
            menubar: false,
            toolbar_sticky: true,
            toolbar_sticky_offset: 100,
            plugins: [
              "advlist",
              "anchor",
              "autolink",
              "help",
              "image",
              "link",
              "fullscreen",
              "lists",
              "searchreplace",
              "table",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat |image ",
            // "undo redo | formatselect | h1 h2 h3 h4 h5 h6 | bold italic backcolor | \
            // alignleft aligncenter alignright alignjustify | \
            // bullist numlist outdent indent | removeformat | image | media",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            template_mdate_format: "%m/%d/%Y : %H:%M",
            templates: [
              {
                title: "Date modified example",
                description:
                  "Adds a timestamp indicating the last time the document modified.",
                content:
                  '<p>Last Modified: <time class="mdate">This will be replaced with the date modified.</time></p>',
              },
            ],
            automatic_uploads: true,
            browser_spellcheck: true,
            image_title: true,

            file_picker_types: "image media",
            file_picker_callback: function (cb, value, meta) {
              var input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*,video/*,pdf/*");
              input.onchange = function () {
                var file = this.files[0];
                var reader = new FileReader();
                reader.onload = async function () {
                  let response = await getMediaURL(file);
                  cb(response, { title: file.name });
                };
                reader.readAsDataURL(file);
              };
              input.click();
            },
          }}
          onChange={(e) => {
            console.log(
              isProperString(
                e.target
                  .getContent()
                  ?.replaceAll(`"`, "%22")
                  ?.replaceAll(`'`, "%27")
              )
            );
            onChange(e);
          }}
        />
      </div>
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}
