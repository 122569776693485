import React, { useEffect, useState } from "react";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import ReactPaginate from "react-paginate";
import {
  calendarApi,
  dashboardActiveTask,
  dashboardCards,
  productivityGraph,
  reviewsList,
  staticsGraph,
  timePieChart,
} from "../../services/subadmin";
import Calendar from "react-calendar";

import ActiveTaskCard from "./ActiveTaskCard";
import LottieAnimation from "../../HOC/LottieAnimation";
import moment from "moment";
const photo_blue = process.env.PUBLIC_URL + "/assets/images/user-profile.svg";
const tabCm = ["edit_requests", "tl_task", "intern_task"];
const tabTl = ["my_task", "intern_task"];
const SubAdminDashboard = () => {
  let location = useLocation();
  const role = localStorage.getItem("role") || "";
  const [tab, setTab] = useState(
    location?.state?.tab ? location?.state?.tab : 0
  );
  const [staticsData, setStaticsData] = useState([]);
  const [productiveData, setProductiveData] = useState([]);
  const [timeCompData, setTimeCompData] = useState([]);
  const [dashboardYear, setDashboardYear] = useState("2023");
  const [dashboardMonth, setDashboardMonth] = useState("month");
  const [isBarGraph, setIsBarGraph] = useState(true);
  const [selectedGraph, setSelectedGraph] = useState(
    "totalAllocatedVsAchieved"
  );
  const [reviewList, setReviewList] = useState([]);
  const [skip, setSkip] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [impDate, setImpDate] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [dashCard, setDashCard] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  console.log(
    "dashboard card",
    dashCard.length > 0 && Object?.keys(dashCard[0])?.length === 4
      ? "Prince"
      : "Kumar"
  );

  useEffect(() => {
    getStatics();
    getProductive();
    getTimeComplex();
    if (skip) {
      getReviewList();
    }
    getDate();
    getDashCard();
  }, []);
  console.log("task cm", tabCm[0]);
  useEffect(() => {
    getActiveTask();
  }, [tab]);

  const getDashCard = async () => {
    let response = await dashboardCards();
    if (response?.data?.statusCode === 200) {
      setDashCard(response?.data?.result || []);
    }
  };
  const getActiveTask = async () => {
    let payload = {
      type:
        role === "content_manager"
          ? tabCm[tab]
          : role === "team_lead"
          ? tabTl[tab]
          : "my_task",
    };
    let response = await dashboardActiveTask(payload);
    if (response?.data?.statusCode === 200) {
      setActiveData(response?.data?.result || []);
    }
  };

  let payload = {
    year: dashboardYear,
    type: dashboardMonth === "month" ? "month" : "week",
  };
  const getStatics = async () => {
    let response = await staticsGraph(payload);
    if (response?.data?.statusCode === 200) {
      setStaticsData(response?.data?.result || []);
    }
  };
  const getProductive = async () => {
    // let payload = {
    //     type: "month",
    //     year: "2023"
    // }
    let response = await productivityGraph(payload);
    if (response?.data?.statusCode === 200) {
      setProductiveData(response?.data?.result || []);
    }
  };
  const getTimeComplex = async () => {
    // let payload = {
    //     type: "month",
    //     year: "2022"
    // }
    let response = await timePieChart(payload);
    if (response?.data?.statusCode === 200) {
      setTimeCompData(response?.data?.result || []);
    }
  };
  const getReviewList = async () => {
    let response = await reviewsList({ page: skip, limit: 10 });
    if (response?.data?.statusCode === 200) {
      setReviewList(response?.data?.result || []);
      setPageCount(Math.ceil(response?.data?.total_count / payload.limit || 0));
      setTotalCount(response?.data?.total_count);
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setSkip(selectedPage + 1);
  };

  console.log("hiiii", timeCompData);
  const series = [
    {
      name: "Allocated Task",
      dashArray: [0, 8, 5],
      data: staticsData.map((item) => item?.allocated_tasks),
    },
    {
      name: "Achieved Task",
      dashArray: [0, 8, 5],
      data: staticsData.map((item) => item?.completed_tasks),
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [5, 7, 5],
      curve: "smooth",
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    markers: {
      size: 5,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      // categories: dashboardMonth === "month" ? staticsData.map(item => item?.month) : staticsData.map(item => item?.week),
      categories:
        dashboardMonth === "month"
          ? staticsData.map((item) => item?.month)
          : [
              "2",
              "4",
              "6",
              "8",
              "10",
              "12",
              "14",
              "16",
              "18",
              "20",
              "22",
              "24",
            ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  const optionsBar = {
    chart: {
      id: "bar-chart",
    },
    xaxis: {
      categories:
        dashboardMonth === "month"
          ? productiveData.map((item) => item?.month)
          : [
              "2",
              "4",
              "6",
              "8",
              "10",
              "12",
              "14",
              "16",
              "18",
              "20",
              "22",
              "24",
            ],
    },
    dataLabels: {
      enabled: false,
    },
  };

  const seriesBar = [
    {
      name: "Productivity",
      dashArray: [0, 8, 5],
      data: productiveData.map((item) => item?.productivity_ratio),
    },
    {
      name: "Efficiency",
      dashArray: [0, 8, 5],
      data: productiveData.map((item) => item?.efficiency_ratio),
    },
  ];

  // Pie chart

  const optionsTime = {
    chart: {
      id: "bar-chart",
    },
    xaxis: {
      categories:
        dashboardMonth === "month"
          ? timeCompData.map((item) => item?.month)
          : [
              "2",
              "4",
              "6",
              "8",
              "10",
              "12",
              "14",
              "16",
              "18",
              "20",
              "22",
              "24",
            ],
    },
    dataLabels: {
      enabled: false,
    },
  };

  const seriesTime = [
    {
      name: "Delay",
      dashArray: [0, 8, 5],
      // data: timeCompData.map(item => item?.delayed_tasks),
      data: ["2", "4", "6", "8", "20", "12", "19", "16", "18", "20", "22"],
    },
    {
      name: "Completed",
      dashArray: [0, 8, 5],
      // data: timeCompData.map(item => item?.timely_completed_tasks),
      data: ["2", "4", "6", "8", "0", "12", "9", "16", "18", "20", "22"],
    },
    {
      name: "In Progress",
      dashArray: [0, 8, 5],
      // data: timeCompData.map(item => item?.in_progress_tasks),
      data: ["2", "4", "6", "8", "0", "12", "9", "16", "18", "20", "22"],
    },
  ];
  const getYear = (type = "2023") => {
    payload.year = type;
    setDashboardYear(type);
    isBarGraph ? getStatics() : getProductive();
  };

  const handleRadioChange = (event) => {
    setSelectedGraph(event.target.value);
  };

  // calendar functions

  const getMonthName = (date) => {
    return date.toLocaleString("default", { month: "long" });
  };

  const getCalenderYear = (date) => {
    return date.getFullYear();
  };

  const handleMonthChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
    getDate(getCalenderYear(activeStartDate), getMonthName(activeStartDate));
  };
  const currentMonth = getMonthName(activeStartDate);
  const currentYear = getCalenderYear(activeStartDate);
  const getDate = async (years, months) => {
    delete payload.type;
    // let response = await calendarApi({ ...payload, month_name: months === undefined ? currentMonth : months })
    let response = await calendarApi({
      year: years === undefined ? currentYear : years,
      month_name: months === undefined ? currentMonth : months,
    });
    if (response?.data?.statusCode === 200) {
      setImpDate(response?.data?.result || []);
      setSelectedDates(
        response?.data?.result?.flatMap((item) => moment(item.deadline_date))
      );
    }
  };

  // const dateArray = [3, 10, 15, 19, 25];
  const dateArray = impDate?.map((item) => item?.date);

  // Function to check if a date should be highlighted for September
  const tileClassName = ({ date }) => {
    if (
      date.toLocaleString("en-US", { month: "long" }) === "September" &&
      dateArray.includes(date.getDate())
    ) {
      // if (date.toLocaleString('en-US', { month: 'long' }) === 'September' && dateArray.includes(date.getDate())) {
      return "highlighted-date";
    }
    return "";
  };
  // const tileClassName = ({ date }) => {
  //   const formattedDate = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short' }).format(date);

  //   if (impDate.includes(formattedDate)) {
  //     return "highlighted-date";
  //   }

  //   return "";
  // };

  return (
    <div className="wrapper-assignpage">
      <div className="dashboard">
        <div className="subheader wrapassign-subheader">
          <div className="subheader-title">
            <h2 className="heading-36 mb-3">Dashboard</h2>
          </div>
          {/* <div className="subheader-right-top ">
                        <button className="add-btn btn-cancel">Give Coins and Ratings</button>
                    </div> */}
        </div>
        <div className="row">
          <div className="col-md-8">
            {/* {dashCard?.map((item, index) => (<div key={index} className="main-dash mb-3"> */}
            {role === "content_manager" ? (
              <div>
                {dashCard?.map((item, index) => (
                  <div key={index} className="main-dash mb-3">
                    <div className="row ">
                      {console.log("card content manager subadmin")}
                      <div className="col-md-3 mb-3 ">
                        <div class="card card1">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash1 dash">
                              <img
                                src="assets/images/dash1.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h1 class="card-subtitle mb-0 mt-0">
                                {item?.total_published_tasks || "0"}
                              </h1>
                            </div>
                          </div>
                          <h6 class="card-title m-0">Total Published Tasks</h6>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div class="card card2">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash2 dash">
                              <img
                                src="assets/images/dash2.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.pending_edit_requests || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Pending Edit Requests</h1>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div class="card card3">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash dash3">
                              <img
                                src="assets/images/dash4.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.total_tl_tasks || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Total Team Lead Tasks</h1>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div class="card card4">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash4 dash">
                              <img
                                src="assets/images/dash3.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.total_interns_tasks || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Total Intern Tasks</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {dashCard?.map((item, index) => (
                  <div key={index} className="main-dash mb-3">
                    <div className="row ">
                      {console.log("card team lead subadmin")}
                      <div className="col-md-3 mb-3">
                        <div class="card card1">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash1 dash">
                              <img
                                src="assets/images/dash1.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h1 class="card-subtitle mb-0 mt-0">
                                {item?.active_tasks || "0"}
                              </h1>
                            </div>
                          </div>
                          <h6 class="card-title m-0">Active Tasks</h6>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div class="card card2">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash2 dash">
                              <img
                                src="assets/images/dash2.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.completed_tasks || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Completed Tasks</h1>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div class="card card3">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash dash3">
                              <img
                                src="assets/images/dash4.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.delayed_tasks || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Delayed Tasks</h1>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div class="card card4">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash4 dash">
                              <img
                                src="assets/images/dash3.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.pending_tasks || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Pending Tasks</h1>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-3 mb-3">
                        <div class="card card4">
                          <div class="card-body d-flex gap-3 align-items-center">
                            <div className="dash4 dash">
                              <img
                                src="assets/images/dash3.svg"
                                alt="profile"
                              />
                            </div>
                            <div>
                              <h6 class="card-subtitle m-0 text-muted">
                                {item?.total_tasks || "0"}
                              </h6>
                            </div>
                          </div>
                          <h1 class="card-title m-0">Total Tasks</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* </div>))} */}
            <div className="graph-div">
              <div className="assignrepport-subheader">
                <div className="d-flex align-items-center">
                  <label className="year-color">Year :</label>

                  <select
                    className="year"
                    value={dashboardYear}
                    onChange={(e) => getYear(e.target.value)}
                  >
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                  </select>
                </div>
                <div className="d-flex">
                  <button
                    className="add-btn me-3 preference filter2"
                    value={"week"}
                    onClick={(e) => {
                      // debugger
                      setDashboardMonth("week");
                    }}
                  >
                    Week
                  </button>
                  <button
                    className="add-btn filter1 preference"
                    value={"month"}
                    onClick={(e) => {
                      // debugger
                      setDashboardMonth("month");
                    }}
                  >
                    Month
                  </button>
                </div>
              </div>
              <div className="selected-data mb-3">
                <div className="select-content">
                  <label className="select-radio">
                    Total Allocated Vs Achieved Task
                    <input
                      id="radio-click-bar"
                      type="radio"
                      name="radio"
                      value="totalAllocatedVsAchieved"
                      checked={selectedGraph === "totalAllocatedVsAchieved"}
                      onChange={handleRadioChange}
                    />
                    <span
                      className="checkmark"
                      htmlFor="radio-click-bar"
                    ></span>
                  </label>
                </div>
                <div className="select-content">
                  <label className="select-radio">
                    {" "}
                    Productivity & Efficiency
                    <input
                      id="radio-click-line-productivity"
                      type="radio"
                      name="radio"
                      value="productivityAndEfficiency"
                      checked={selectedGraph === "productivityAndEfficiency"}
                      onChange={handleRadioChange}
                    />
                    <span
                      className="checkmark"
                      htmlFor="radio-click-line-productivity"
                    ></span>
                  </label>
                </div>
                <div className="select-content">
                  <label className="select-radio">
                    Timely completion
                    <input
                      id="radio-click-line-time-complexity"
                      type="radio"
                      name="radio"
                      value="timeComplexity"
                      checked={selectedGraph === "timeComplexity"}
                      onChange={handleRadioChange}
                    />
                    <span
                      className="checkmark"
                      htmlFor="radio-click-line-time-complexity"
                    ></span>
                  </label>
                </div>
              </div>
              <div>
                {selectedGraph === "totalAllocatedVsAchieved" && (
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                  />
                )}
                {selectedGraph === "productivityAndEfficiency" && (
                  <ReactApexChart
                    options={optionsBar}
                    series={seriesBar}
                    type="line"
                    height={350}
                  />
                )}
                {selectedGraph === "timeComplexity" && (
                  <ReactApexChart
                    options={optionsTime}
                    series={seriesTime}
                    type="bar"
                    height={350}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <Calendar
                className="card"
                value={selectedDates}
                // value={activeStartDate}
                onActiveStartDateChange={handleMonthChange}
                showNavigation={true}
                tileClassName={tileClassName}
              />
            </div>
            <div className="card-scroll check-div">
              {impDate.length === 0 && <LottieAnimation />}
              {impDate?.map((item, ind) => (
                <div className="row" key={ind}>
                  <div className="col-md-12">
                    <div className="card card-stamp-main mt-2">
                      <div className="d-flex justify-content-between">
                        <div className="card-title card-style">
                          <span className="text-white">
                            {item?.deadline_date &&
                              new Intl.DateTimeFormat("en-US", {
                                day: "2-digit",
                                month: "short",
                              }).format(new Date(item.deadline_date))}
                          </span>
                        </div>
                        <div>
                          <FontAwesomeIcon icon={faEllipsis} />
                        </div>
                      </div>
                      <h3 className="card-subtitle mb-1 text-muted">
                        {item?.priority || "NA"}
                      </h3>
                      <p className="card-text">{item?.task_title || "NA"}</p>
                      <div className="assign">
                        <div>
                          <img src="assets/images/image2.png" />
                        </div>
                        <div>
                          <p>Assigned By</p>
                          <h6 className="m-0 mb-2">
                            {item?.assigned_by || "NA"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="subheader-title mb-4">
          <h3 className="heading-36">Active Tasks</h3>
        </div>
        {role === "content_manager" ? (
          <div className="subadmin-nav-tab active-task">
            <div
              className={`tabbing-button ${tab === 0 ? "active" : ""}`}
              onClick={() => setTab(0)}
            >
              <button>My Tasks</button>
            </div>
            <div
              className={`tabbing-button ${tab === 1 ? "active" : ""}`}
              onClick={() => setTab(1)}
            >
              <button>Team Leader</button>
            </div>
            <div
              className={`tabbing-button ${tab === 2 ? "active" : ""}`}
              onClick={() => setTab(2)}
            >
              <button>Intern</button>
            </div>
          </div>
        ) : role === "team_lead" ? (
          <div className="subadmin-nav-tab active-task">
            <div
              className={`tabbing-button ${tab === 0 ? "active" : ""}`}
              onClick={() => setTab(0)}
            >
              <button>My Tasks</button>
            </div>
            <div
              className={`tabbing-button ${tab === 1 ? "active" : ""}`}
              onClick={() => setTab(1)}
            >
              <button>Intern</button>
            </div>
          </div>
        ) : (
          <div className="subadmin-nav-tab active-task">
            <div
              className={`tabbing-button ${tab === 0 ? "active" : ""}`}
              onClick={() => setTab(0)}
            >
              <button>My Tasks</button>
            </div>
          </div>
        )}
        {tab === 0 && (
          <ActiveTaskCard
            activeData={activeData}
            cmTab={tabCm[0]}
            role={role}
          />
        )}
        {tab === 1 && <ActiveTaskCard activeData={activeData} />}
        {tab === 2 && <ActiveTaskCard activeData={activeData} />}

        {console.log("tab in dashboard", tab)}

        <div className="nav-tab assignrepport-subheader">
          <h3 className="mt-5">Reviews</h3>
        </div>
        <div className=" table-main fixTableHead">
          <table className="table subadmin-table nowrap table-bordered table-default-dark subadmin-table">
            <thead>
              <tr className="subadmin-table-heading">
                <th className="td_ellipse text-center">S. No.</th>
                <th className="td_ellipse text-center">Name</th>
                <th className="td_ellipse text-center">Role</th>
                <th className="td_ellipse text-center">Initiative</th>
                <th className="td_ellipse text-center">Quality Of Content</th>
                <th className="td_ellipse text-center">Creativity</th>
                <th className="td_ellipse text-center">Research</th>
                <th className="td_ellipse text-center">Public</th>
              </tr>
            </thead>
            <tbody>
              {reviewList?.map((list, index) => (
                <tr className="table-shadow" key={list.id}>
                  <td className="td_ellipse">{index + 1 || "NA"}</td>
                  <td className="td_ellipse">{list.given_by_name || "NA"}</td>
                  <td className="td_ellipse">{list.given_by_role || "NA"}</td>
                  <td className="td_ellipse">{list.initiative || "NA"}</td>
                  <td className="td_ellipse">
                    {list.quality_of_content || "NA"}
                  </td>
                  <td className="td_ellipse">{list.creativity || "NA"}</td>
                  <td className="td_ellipse">{list.research || "NA"}</td>
                  <td className="td_ellipse">{list.public || "NA"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {totalCount > payload.limit && (
          <div>
            <ReactPaginate
              previousLabel={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              nextLabel={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination custom-pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubAdminDashboard;
