import React from "react";
import { Navigate, Route } from "react-router-dom";

const PublicRoute = (props) => {
  const user = localStorage.getItem("token");
  if(user){
    return <Navigate to='/dashboard' />
  }
  return props.children;
};

export default PublicRoute;
