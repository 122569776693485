import React from "react";
import {faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
$(".btn-pagination").on("click", function(){
    $(".btn-pagination").removeClass("active");
      $(".btn-pagination").addClass("active");
});

$(".read-more").on("click", function(){
    $("span").css(["white-space=normal", "max-width=160px"])
})
const RatingTask = () => {
    return(
        <div>
            <div className="table-main assigndata-table fixTableHead">
                                <table className="table nowrap table-bordered table-default-dark ">
                                    <thead><tr className="table-heading">
                                        <th className="">Task</th>
                                        <th className="">Ratings</th>
                                        <th className="">Out of 5</th>
                                        <th className="">Out of 10</th>
                                        <th className="">Comment</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="">1. Initiative </td>
                                            <td className="wrap-action-btn">
                                                
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                </td>

                                            <td className="">4.5 / 5</td>
                                            <td className="">7</td>
                                            <td className="td_ellipse"><span>lorem ipsum lorem ipsum lorem ipsum....read morerrhrhrehrhherhrehhrfhrchrjhe rhrehrehrrrref</span>
                                            <a href="javascript:void(0)" className="read-more">read more</a></td>
                                        </tr>
                                        <tr>
                                            <td className="">2. Over All Performance </td>
                                            <td className="wrap-action-btn">
                                                
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                </td>

                                            <td className="">4.5 / 5</td>
                                            <td className="">7</td>
                                            <td className="td_ellipse"><span>lorem ipsum lorem ipsum lorem ipsum....read morerrhrhrehrhherhrehhrfhrchrjhe rhrehrehrrrref</span>
                                            <a href="javascript:void(0)" className="read-more">read more</a></td>
                                        </tr>
                                        <tr>
                                            <td className="">3. Areas Of Improvement </td>
                                            <td className="wrap-action-btn">
                                                
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                </td>

                                            <td className="">4.5 / 5</td>
                                            <td className="">7</td>
                                            <td className="td_ellipse"><span>lorem ipsum lorem ipsum lorem ipsum....read morerrhrhrehrhherhrehhrfhrchrjhe rhrehrehrrrref</span>
                                            <a href="javascript:void(0)" className="read-more">read more</a></td>
                                        </tr>
                                        <tr>
                                            <td className="">4. Quality Of Content</td>
                                            <td className="wrap-action-btn">
                                                
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                </td>

                                            <td className="">4.5 / 5</td>
                                            <td className="">7</td>
                                            <td className="td_ellipse"><span>lorem ipsum lorem ipsum lorem ipsum....read morerrhrhrehrhherhrehhrfhrchrjhe rhrehrehrrrref</span>
                                            <a href="javascript:void(0)" className="read-more">read more</a></td>
                                        </tr>
                                        <tr>
                                            <td className="td_ellipse">5. Timely Submission</td>
                                            <td className="wrap-action-btn">
                                                
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                    <FontAwesomeIcon className="chart-icon" icon={faStar} />
                                                </td>

                                            <td className="td_ellipse">4.5 / 5</td>
                                            <td className="td_ellipse">7</td>
                                            <td className="td_ellipse"><span>lorem ipsum lorem ipsum lorem ipsum....read morerrhrhrehrhherhrehhrfhrchrjhe rhrehrehrrrref</span>
                                            <a href="javascript:void(0)" className="read-more">read more</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <button className=" btn-pagination btn-prev">Previous</button>
                                <button className="btn-pagination btn-next active">Next</button>
                            </div>
        </div>
    )
}
export default RatingTask