import React, { useEffect, useState } from "react";
import { faFacebook, faInstagram, faLinkedin, faYoutube, } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faHome, faPhone, faPhoneAlt, faUser, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileDetails } from "../../../services/subadmin";

const ProfileDetail = () => {
  const [porfileField, setProfileField] = useState({});

  useEffect(() => {
    getProfileDetail();
  }, []);

  const getProfileDetail = async () => {
    let response = await profileDetails()
    setProfileField(response?.data?.result || []);
  };

  console.log("prince", porfileField)

  return (
    <div className="wrapper-assignpage">
      <div className="d-flex justify-content-center">
        <div className="profile">Profile</div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div>
            <h2 className="name mt-3">{porfileField?.name || "NA"}</h2>
            <div className="tl">{porfileField?.role === "content_manager" ? "Content Manager" : porfileField?.role === "team_lead" ? "Team Leader" : porfileField?.role === "intern" ? "Intern" : "Admin" || "NA"}</div>
            <div className="location">
              {/* <span className="green me-4">BTS <span className="color">KIMS, Bangalore</span></span> */}
              <span className="color">{porfileField?.address || "NA"}</span>
            </div>
            <div className="social col-md-6">
              <div className="user-h mb-3">social Links</div>
              <div className="d-flex justify-content-between col-md-6">
                <FontAwesomeIcon icon={faInstagram} onClick={() => window.open(`${porfileField?.instagram_url}`, '_blank')} />
                <FontAwesomeIcon icon={faFacebook} onClick={() => window.open(`${porfileField?.facebook_url}`, '_blank')} />
                <FontAwesomeIcon icon={faLinkedin} onClick={() => window.open(`${porfileField?.linkedin_url}`, '_blank')} />
                <FontAwesomeIcon icon={faYoutube} onClick={() => window.open(`${porfileField?.youtube_url}`, '_blank')} />
              </div>
            </div>
            <div className="basic-detail col-md-6">
              <div className="user-h mb-2">Basic Details</div>
              <div className="basic-info">
                <div className="d-flex mb-2">
                  <div className="grey me-2">Employee Code: </div>
                  <div className="black">{porfileField?.emp_id || "NA"}</div>
                </div>
                <div className="d-flex mb-2">
                  <div className="grey me-2">D.O.B: </div>
                  <div className="black">{porfileField?.dob || "NA"}</div>
                </div>
                <div className="d-flex mb-2">
                  <div className="grey me-2">Sex: </div>
                  <div className="black">{porfileField?.sex || "NA"}</div>
                </div>
                <div className="d-flex mb-2">
                  <div className="grey me-2">Status: </div>
                  <div className="black">{porfileField?.employment_status || "NA"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <img className="img-fluid" src="../assets/images/image.png" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="social about">
            <div className="row">
              <div className="col-md-3">
                <img className="img-fluid" src="../assets/images/image2.png" />
              </div>
              <div className="col-md-9">
                <div className="user-h">About</div>
                <p>
                  {porfileField?.about || "NA"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="academic social">
        <div className="row">
          <div className="col-md-12">
            <div className="user-h my-4">Academic Details</div>
            <div className="d-flex mb-4">
              <div className="black me-4">College :</div>
              <div>
                <div className="grey">KIMS, Bangalore, 2014-2018</div>
                <div className="black">Bachelor of Dental Surgery</div>
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className="black me-4">School :</div>
              <div>
                <div className="grey">DPS, Bangalore, 2010-2014</div>
                <div className="black">High School Diploma</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="personal-detail social">
        <div className="user-h my-4">Professional Details</div>
        <div className="d-flex mb-4">
          <div className="black me-5">May 2018 - Aug 2020</div>
          <div>
            <div className="grey mb-3">ACE Surgical</div>
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>{" "}
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex mb-5">
          <div className="black me-5">Sept 2020 - April 2022</div>
          <div>
            <div className="grey mb-3">MegaGen</div>
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>{" "}
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex mb-5">
          <div className="black me-5">June 2022 - Present</div>
          <div>
            <div className="grey mb-3">Straumann</div>
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>{" "}
              <li>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy{" "}
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="top-skill social personal-detail">
        <div className="user-h mb-3">Top Skills</div>
        <ul>
          <li>{porfileField?.top_skills || "NA"}</li>
        </ul>
        <div className="mb-3"></div>
      </div>
      <div className="social ">
        <div className="user-h mb-4">Contact Details</div>
        <div className="mb-3 d-flex">
          <FontAwesomeIcon className="me-3 user-ic" icon={faPhone} /> +91 {porfileField?.mobile_no || "NA"}
        </div>
        <div className="mb-3 d-flex">
          <FontAwesomeIcon className="me-3 user-ic" icon={faEnvelope} /> {porfileField?.email_id || "NA"}
        </div>
        <div className="d-flex">
          <FontAwesomeIcon className="me-3 user-ic" icon={faHome} /> {porfileField?.address || "NA"}
        </div>
      </div>

     {porfileField?.role === "" ? "": <div>
        <div className="user-h my-4 ">
          Reporting Manager
        </div>
        <div className="mana-name d-flex user-h">
          <FontAwesomeIcon className="user-ic me-3" icon={faUser} />
          {porfileField?.reporting_manager || "NA"}
        </div>
      </div>}
    </div>
  );
};

export default ProfileDetail;
