import React from "react";
const Summary = () => {
    return(
        <div className="wrap-suggestion">
                <div className="wrap-suggestion-content">
                    <div className="content-para">
                        <p>But I must explain to you how all this mistaken idea of reprobating pleasure and extolling pain arose. To do so, I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who.</p>
                    </div>
                </div>
            </div>
    )
}
export default Summary