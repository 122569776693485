import React,{useState} from 'react'
import Lottie from "lottie-react";
import loader from "../constants/loader2.json";

const Loader = (WarpedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);

    const setLoadingStage = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <div className='loader-wrap'>
          <div className='loaderdv'>
          {/* <CircularProgress /> */}
          <Lottie className='sygnific-lottie' animationData={loader} loop={true} width={'10px'} height={'10px'} />
          </div>
        </div>}

        <WarpedComponent
          {...props}
          setLoading={setLoadingStage}
          isLoading={isLoading}
        />
      </>
    );
  }
    return HOC
}

export default Loader